import useDataSync from '@/composables/subscription/data-sync';
import useRepo from '@/nebula/repo';
import useSavedViewRepo from '@/composables/saved-views/saved-view-repo';
import useSubscription from '@/composables/subscription/subscription';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import {
  accessPolicy as accessPolicyConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  account as accountConfig,
  accountSettings as accountSettingsConfig,
  asanaQuery as asanaQueryConfig,
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  goalCycle as goalCycleConfig,
  goalSettings as goalSettingsConfig,
  goalSubscription as goalSubscriptionConfig,
  hubspotQuery as hubspotQueryConfig,
  jiraQuery as jiraQueryConfig,
  msPlannerQuery as msPlannerQueryConfig,
  msPowerBiQuery as msPowerBiQueryConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
  propertyValue as propertyValueConfig,
  resourceSettings as resourceSettingsConfig,
  salesforceQuery as salesforceQueryConfig,
  savedView as savedViewConfig,
  selectedView as selectedViewConfig,
  space as spaceConfig,
  spreadsheetCell as spreadsheetCellConfig,
  storageObject as storageObjectConfig,
  update as updateConfig,
  user as userConfig,
  userScope as userScopeConfig,
  userScopeTree as userScopeTreeConfig,
} from 'shared/api/query/configs.json';
import { computed, ref } from 'vue';
import { doLoginPublishedView } from '@/api';
import { isEmpty } from 'shared/lib/object/object';
import { routeName } from 'shared/constants.json';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function usePublishedViewLogin() {
  const route = useRoute();
  const store = useStore();
  const viewId = computed(() => parseInt(route.params.savedViewId, 10));

  const loading = ref(false);

  const { selectSingle: getView } = useSavedViewRepo();
  const selectedViewsRepo = useRepo(selectedViewConfig.model);

  const debounceDelay = 1000; // debounce delay for querying data in bulk from collaboration

  const loggedInSavedViewResponseHandler = (async () => {
    const view = getView(viewId.value);
    if (!isEmpty(view)) {
      document.title = `${view.title} | Mooncamp`;
    }
  });

  const isPublishedView = computed(() => [routeName.publishedView].includes(route.name));

  const repos = {};
  const subscribers = {};
  const configs = [
    { model: goalCycleConfig.model, fetch: true, subscribe: true },
    { model: propertyOptionConfig.model, fetch: true, subscribe: true },
    { model: propertyConfig.model, fetch: true, subscribe: true },
    { model: propertySettingsConfig.model, fetch: true, subscribe: true },
    { model: spaceConfig.model, fetch: true, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: userConfig.model, fetch: true, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: propertyValueConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: storageObjectConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: accessPolicyConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: accessPolicyScopeConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: userScopeTreeConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: userScopeConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: accountConfig.model, fetch: true, subscribe: true },
    { model: accountSettingsConfig.model, fetch: false, subscribe: true },
    { model: goalSettingsConfig.model, fetch: false, subscribe: true },
    { model: resourceSettingsConfig.model, fetch: false, subscribe: true },
    { model: updateConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: goalActivityConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: savedViewConfig.model, fetch: true, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: goalConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: goalSubscriptionConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: asanaQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: hubspotQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: jiraQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: salesforceQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: spreadsheetCellConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: msPlannerQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: msPowerBiQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
  ];

  configs.forEach((config) => {
    if (config.repo !== undefined) {
      repos[config.model] = config.repo();
    } else {
      repos[config.model] = useRepo(config.model);
    }
    if (config.subscriber !== undefined) {
      subscribers[config.model] = config.subscriber(repos[config.model], { delay: debounceDelay, ...config.syncOptions });
    } else {
      const subscriptionSvc = useSubscription();
      subscribers[config.model] = useDataSync(subscriptionSvc, repos[config.model], { delay: debounceDelay, ...config.syncOptions });
    }
  });

  const initialize = () => {
    const authPromise = getView(viewId.value, { commitToRemote: true }).then((view) => {
      if (view === undefined) {
        throw new Error('view not found');
      }

      selectedViewsRepo.updateSingle({
        uid: 0,
        view: { uid: view.uid },
        viewApplication: view.viewApplication,
      }, { commitToRemote: false });
    }).catch(() => null);

    const promises = [];
    promises.push(authPromise);
    configs.forEach((config) => {
      if (!config.fetch) {
        return;
      }
      promises.push(repos[config.model].getList().catch(() => {}));
    });
    loading.value = true;
    return Promise.all(promises).then(async (responses) => {
      if (responses[0] !== null) {
        store.commit('PUBLISHED_VIEW_DATA');
        await loggedInSavedViewResponseHandler();
        // subscriptions can only start from here
        configs.forEach((config) => {
          if (!config.subscribe) {
            return;
          }
          subscribers[config.model].subscribe();
        });
      }
      return responses[0];
    }).finally(() => {
      loading.value = false;
    });
  };

  const attemptLogin = () => {
    if (route.params.savedViewId !== undefined && route.query.s === undefined) {
      return Promise.reject();
    }
    const savedViewId = parseInt(route.params.savedViewId, 10);
    const passphrase = atob(route.query.s);
    return doLoginPublishedView({ savedViewId, passphrase }).then((response) => {
      if (response.status !== 200) {
        throw new Error('error during published login');
      }

      EventBus.$emit(EVENTS.SESSION.LOGIN_PUBLISHED_VIEW);
    });
  };
  return { loading, initialize, attemptLogin, isPublishedView };
}
