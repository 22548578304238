import { UID } from 'shared/api/query/constants';

import {
  aggregation as aggregationConfig,
  property as propertyConfig,
} from 'shared/api/query/configs.json';

export const aggregationChildren = [
  { attr: UID },
  { attr: aggregationConfig.edges.aggregator },
  { attr: aggregationConfig.edges.isProperty },
  { attr: aggregationConfig.edges.edge },
  { attr: aggregationConfig.edges.edgeType },
  { attr: aggregationConfig.edges.property, model: propertyConfig.model, children: [{ attr: UID }] },
];
