import { UID } from 'shared/api/query/constants';
import { aggregationChildren } from '@/api/query/nebula/aggregation';
import {
  aggregation as aggregationConfig,
  aggregationConfig as aggregationConfigConfig,
  chartGroupBy as chartGroupByConfig,
} from 'shared/api/query/configs.json';
import { chartGroupByChildren } from '@/api/query/nebula/chart-group-by';

export const aggregationConfigChildren = [
  { attr: UID },
  { attr: aggregationConfigConfig.edges.aggregation, model: aggregationConfig.model, children: aggregationChildren },
  { attr: aggregationConfigConfig.edges.stackOptions, model: chartGroupByConfig.model, children: chartGroupByChildren },
];
