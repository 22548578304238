<template>
  <m-focusable
    :hide-hover="hideHover"
    :hide-border="hideBorder"
    :full-width="fullWidth"
    :read-only="readOnly"
    :disabled="disabled"
    :small="small"
    :xs="xs"
    :class="classes"
    :show-placeholder="!focused && !hidePlaceholder && placeholder !== '' && (value === '' || value === null)"
    :placeholder="placeholder"
    :placeholder-icon="placeholderIcon"
    :has-error="hasError"
    :focused="focused"
    :m-style="mStyle"
    @click="handleClick"
  >
    <template
      v-if="!!$slots.prefix"
      #prefix
    >
      <slot
        name="prefix"
      />
    </template>
    <input
      ref="input"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled || readOnly"
      class="_input"
      :style="resolveStyles({ ...defaultStyle, ...mStyle })"
      :size="inputSize"
      @input="input"
      @change="change"
      @blur="blur"
      @focus="$emit('focus', $event)"
      @keydown.enter="handleKeyDownEnter"
    >
    <template
      #suffix
    >
      <slot
        v-if="!!$slots.suffix"
        name="suffix"
      />
    </template>
  </m-focusable>
</template>

<script>
import MFocusable from 'shared/components/base/MFocusable.vue';
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'MInput',
  props: {
    ...mStyleProps,
    value: {
      type: [String, Number],
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    inputSize: {
      type: Number,
      default: undefined,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  components: { MFocusable },
  emits: ['input', 'update:value', 'blur', 'change', 'click', 'focus', 'enter'],
  data() {
    return { val: '', focused: false };
  },
  computed: {
    defaultStyle() {
      if (this.xs) {
        return { fontStyle: { fontSize: 'small' } };
      }
      return {};
    },
    classes() {
      return [
        'm-input',
        this.fullWidth ? '-full-width' : '',
        this.hideBorder ? '-hide-border' : '',
        this.hideHover ? '-hide-hover' : '',
        this.disabled ? '-disabled' : '',
        this.xs ? '-xs' : '',
        this.wrap ? '-wrap' : '',
        this.placeholderIcon !== '' ? '-has-placeholder-icon' : '',
      ];
    },
  },
  methods: {
    handleKeyDownEnter() {
      this.$emit('enter');
    },
    resolveStyles,
    blur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    input(event) {
      this.$emit('input', event.target.value);
      this.$emit('update:value', event.target.value);
    },
    change(event) {
      this.$emit('change', event.target.value);
    },
    handleClick(event) {
      this.$emit('click', event);
      this.focus();
    },
    focus() {
      this.focused = true;
      this.$nextTick(() => {
        if (typeof this.$refs.input === 'undefined' || this.$refs.input === null) {
          return;
        }
        this.$refs.input.focus();
      });
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.focus();
    }
  },
};
</script>

<style lang="scss" scoped type="text/scss">
  .m-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-height: $input-height-default;
    padding: .3rem .8rem;
    cursor: inherit;
    border-radius: $input-field-border-radius;

    ._input {
      width: 100%;
      padding: 0;
      margin-left: .2rem;
      font-size: inherit;
      text-overflow: ellipsis;
      cursor: inherit;
      resize: none;
      background: none;
      border: none;
      outline: none;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }

      /* Firefox */
      &[type="number"] {
        appearance: textfield;
      }

      &::placeholder {
        color: $font-color-tertiary;
      }

      &:focus {
        outline: none;
      }
    }

    ._suffix {
      max-width: 15rem;
      white-space: nowrap;
    }

    &.-disabled {
      ._input {
        color: $font-color-disabled;
      }
    }

    &.-full-width {
      display: flex;
      width: 100%;
    }
  }

</style>
