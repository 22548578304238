<template>
  <div :class="classes">
    <input
      :ref="(el) => inputRef = el"
      :style="inputStyle"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      :name="name"
      :type="inputType"
      :class="['_input', hideHover ? '-hide-hover' : '']"
      :readonly="readOnly"
      @focus="handleFocus"
      @input="input"
      @change="change"
      @blur="blur"
    >
    <div
      v-if="!!$slots.suffix || allowClear"
      class="_suffix"
    >
      <slot name="suffix" />
      <m-btn
        v-if="allowClear && value !== ''"
        class="_btn"
        icon="close-circle"
        hide-border
        xs
        light
        fab
        @click="clear"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MTextField',
  props: {
    value: {
      type: String || Object,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    autoSelect: {
      type: Boolean,
      default: false,
    },
    hideFocus: {
      type: Boolean,
      default: false,
    },
    inheritStyling: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    formatter: {
      type: Function,
      default: (v) => v,
    },
    name: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: null,
    },
    inputStyle: {
      type: Object,
      default: () => {
      },
    },
  },
  emits: ['input', 'update:value', 'clear', 'blur', 'change', 'focus'],
  data() {
    return { inputRef: null };
  },
  computed: {
    classes() {
      return [
        'm-text-field',
        this.fullWidth ? '-full-width' : '',
        this.hideBorder ? '-hide-border' : '',
        this.readOnly ? '-read-only' : '',
        this.small ? '-small' : '',
        this.large ? '-large' : '',
        this.disabled ? '-disabled' : '',
        this.xl ? '-xl' : '',
        this.inheritStyling ? '-inherit-styling' : '',
        this.hasError ? '-has-error' : '',
        this.hasBackground ? '-has-background' : '',
      ];
    },
  },
  methods: {
    emitInput(event) {
      if (event.type === 'click') {
        this.$emit('clear');
      }
    },
    blur(event) {
      this.$emit('blur', event);
    },
    focus() {
      if (this.inputRef === null) {
        return;
      }
      this.$nextTick(() => {
        this.inputRef.focus();
      });
    },
    select() {
      if (this.inputRef === null) {
        return;
      }
      this.$nextTick(() => {
        this.inputRef.select();
      });
    },
    clear() {
      this.$emit('input', '');
      this.$emit('update:value', '');
      this.$emit('change', '');
      this.$emit('clear');
    },
    handleFocus() {
      this.$emit('focus');
    },
    change(event) {
      let value = event.target.value;
      if (this.maxLength > 0) {
        value = value.slice(0, this.maxLength);
      }
      value = this.formatter(value);
      this.$emit('update:value', value);
      this.$emit('change', value);
    },
    input(event) {
      let value = event.target.value;
      if (this.maxLength > 0) {
        value = value.slice(0, this.maxLength);
      }
      value = this.formatter(value);
      this.$emit('update:value', value);
      this.$emit('input', value);
    },
  },
  watch: {
    inputRef: {
      handler(val) {
        if (val === null) {
          return;
        }
        // We need to have the next tick so focus and select work within a dropdown or modal
        // which also call focus to close by pressing ESC
        this.$nextTick(() => {
          if (this.autoFocus) {
            this.focus();
          }
          if (this.autoSelect) {
            this.select();
          }
        });
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.value !== null && this.maxLength > this.value.length) {
      this.$emit('input', this.value.slice(0, this.maxLength));
      this.$emit('update:value', this.value.slice(0, this.maxLength));
      this.$emit('change', this.value.slice(0, this.maxLength));
    }
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-text-field {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: inherit;
    line-height: 1.5;
    cursor: text;
    border-radius: $input-field-border-radius;

    ._suffix {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: .6rem;
    }

    ._input {
      position: relative;
      width: 100%;
      height: $input-height-default;
      padding: .4rem 1.1rem;
      border: 1px solid $input-border-color;
      border-radius: $input-field-border-radius;

      &::placeholder {
        color: $font-color-tertiary;
      }

      &:focus {
        border-color: $primary-color;
        outline: none;
        box-shadow: 0 0 0 2px rgb(59 177 207 / 20%);
      }
    }

    &.-small {
      ._input {
        height: $input-height-sm;
      }
    }

    &.-large {
      ._input {
        height: $input-height-lg;
      }
    }

    &.-has-background {
      ._input {
        background-color: map_get($grey, lighten-7);
      }
    }

    &.-disabled {
      ._input {
        color: map_get($grey, 'lighten-1');
        cursor: default;
        background-color: map_get($grey, 'lighten-4');
        opacity: 1;
      }
    }

    &.-hide-border {
      ._input {
        cursor: pointer;
        resize: none;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    &.-has-error {
      ._input {
        border-color: $error-color;
      }
    }

    &.-xl {
      ._input {
        height: 5rem;
        font-size: $font-size-6;
      }
    }

    &.-read-only {
      ._input {
        color: $font-color-primary;
        cursor: default;
        background-color: white;
        background-color: map_get($grey, 'lighten-4');
      }
    }

    &.-inherit-styling {
      ._input {
        height: inherit;
        padding: inherit;
        margin: inherit;
        background-color: inherit;
        border: inherit;
        box-shadow: inherit;
      }

      &:focus {
        border: inherit;
        outline: inherit;
        box-shadow: inherit;
      }
    }

    &:not(.-hide-hover) {
      &:hover {
        &.-hide-border {
          ._input {
            background-color: $hover-color;
          }
        }

        &.-disabled {
          ._input {
            cursor: not-allowed;
            background-color: map_get($grey, 'lighten-4');
            border-color: $input-border-color;
          }
        }

        &.-read-only {
          ._input {
            background-color: map_get($grey, 'lighten-4');
          }
        }

        &.-inherit-styling {
          ._input {
            border-color: inherit;
          }
        }
      }
    }
  }
</style>
