<template>
  <dashboard-card
    class="goal-type-card"
    :title="title"
    :loading="loading"
  >
    <div class="_content">
      <table class="_all_goals">
        <thead>
          <tr
            class="_total_goals _item"
            @click="$emit('click-goal-total')"
          >
            <th
              class="_text"
            >
              {{ $t('goalInsightsPerformance.goalsTotal') }}
            </th>
            <th class="_amount">
              <span class="_total">{{ goalsTotalCount }}</span> <span
                v-if="goalsTotalCountDiff !== undefined"
                class="_diff"
              >({{ asDiff(goalsTotalCountDiff) }})</span>
            </th>
          </tr>
          <tr>
            <th
              class="_spacer _border_bottom"
              colspan="2"
            />
          </tr>
          <tr>
            <th
              class="_spacer"
              colspan="2"
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in goalTypeCounts"
            :key="item.uid"
            class="_item"
            @click="$emit('click-goal-type', item)"
          >
            <td class="_text">
              {{ textByLang(item.label, userLang) }}:
            </td>
            <td class="_amount">
              {{ item.count }} <span
                v-if="item.diff !== undefined"
                class="_diff"
              >({{ asDiff(item.diff) }})</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </dashboard-card>
</template>

<script>
import DashboardCard from '@/components/dashboard/DashboardCard.vue';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalTypeCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    goalTypeCounts: {
      type: Array,
      required: true,
    },
    goalsTotalCount: {
      type: Number,
      required: true,
    },
    goalsTotalCountDiff: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    const { userLang } = useLoggedInUser();
    return { userLang };
  },
  components: { DashboardCard },
  emits: ['click-goal-type', 'click-goal-total'],
  data() {
    return { textByLang };
  },
  methods: {
    asDiff(value) {
      if (value === 0) {
        return `±${value}`;
      }
      return value > 0 ? `+${value}` : `${value}`;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goal-type-card {
    ._content {
      display: flex;
      flex-direction: column;

      ._all_goals {
        border-spacing: 0;

        ._diff {
          color: $font-color-tertiary;
        }

        ._spacer {
          height: .5em;
        }

        ._border_bottom {
          border-bottom: 1px solid map_get($grey, 'lighten-3');
        }

        ._text {
          width: 50%;
        }

        ._item {
          ._text,
          ._amount {
            padding: .2em .4em;
            color: map_get($grey, 'darken-4');
            text-align: left;
          }

          ._amount {
            padding-right: 9rem;
            text-align: right;

            @media (max-width: $screen-size-xl) {
              padding-right: 0;
            }
          }

          &:hover {
            cursor: pointer;

            ._text {
              background-color: $hover-color;
              border-top-left-radius: $btn-border-radius;
              border-bottom-left-radius: $btn-border-radius;
            }

            ._amount {
              background-color: $hover-color;
              border-top-right-radius: $btn-border-radius;
              border-bottom-right-radius: $btn-border-radius;
            }
          }
        }

        ._total_goals._item {
          ._text {
            font-weight: normal;
            color: black;
          }

          ._amount {
            font-weight: $font-weight-semibold;
            color: black;
          }

          ._diff {
            font-weight: normal;
          }
        }
      }
    }
  }
</style>
