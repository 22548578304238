<template>
  <teleport
    v-if="showSidePeek"
    to="#overlay"
  >
    <transition
      name="slide"
    >
      <div
        v-if="showSidePeekContent"
        class="_side-peek"
        :style="sidePeakStyle"
        @mousedown.stop
        @mouseup.stop
      >
        <div
          :class="['_drag-handle', dragging? '-dragging': '']"
          @mousedown="handleDragStart"
        />
        <goal-page
          id="page"
          ref="goalPageSidePeek"
          :goal-id="goalId"
          :route-after-delete="routeWithoutGoalId"
          open-in-side-peek
          :width="width"
          :open-children-in-modal="openChildrenInModal"
          :goal-modifiers="modifiers"
          :scroll-container="bodyClass"
          :context="context"
          @close="closeGoalModal"
          @open="goToGoalDetail"
        />
      </div>
    </transition>
  </teleport>
  <m-dialog
    :value="showGoalModal"
    :max-width="$modalSizes.xl"
    hide-footer
    no-padding
    keep-height
    hide-header
    top="7rem"
    :body-class="bodyClass"
    @close="closeGoalModal"
  >
    <goal-page
      ref="goalPage"
      :goal-id="goalId"
      :route-after-delete="routeWithoutGoalId"
      open-in-modal
      :open-children-in-modal="openChildrenInModal"
      :goal-modifiers="modifiers"
      :scroll-container="bodyClass"
      :context="context"
      @close="closeGoalModal"
      @open="goToGoalDetail"
    />
  </m-dialog>
  <product-tour
    v-if="showTour"
    :steps="productTourSteps"
    @close="productTourEnd"
    @finish="productTourEnd"
  />
</template>

<script>

import ProductTour from '@/components/onboarding/ProductTour.vue';
import useDebounce from '@/composables/debounce';
import useGoalDetailsCtx from '@/composables/goal/goal-details-context';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import usePlanningDetailsCtx from '@/composables/planning/planning-details-context';
import usePublishedViewDetailsCtx from '@/composables/published-view/published-view-details-context';
import {
  CASCADE_CONTEXT_PLANNING,
  CASCADE_CONTEXT_PUBLISHED_VIEW,
} from '@/lib/constants';
import { computed, defineAsyncComponent } from 'vue';
import { getQueryParamAsInt } from '@/lib/route';
import { goalViewMode as viewMode } from 'shared/constants.json';

const MIN_WIDTH = 560;
const MAX_WIDTH = 0.66; // 66% of the window width

export default {
  name: 'GoalDetailsDialog',
  setup() {
    const { loggedInUser } = useLoggedInUser();
    const { personalAppSettings, updateSingle: updatePersonalAppSettings } = usePersonalAppSettings(loggedInUser);
    const peekModeSvc = useOpenPeekMode();
    const { inCtx: inPlanningDetailsCtx, goalModifiers: planningDetailsCtxModifiers } = usePlanningDetailsCtx();
    const { inCtx: inGoalDetailsCtx, goalModifiers: goalDetailsCtxModifiers } = useGoalDetailsCtx();
    const { inCtx: inPublishedViewDetailsCtx } = usePublishedViewDetailsCtx();
    const { debounce, flush } = useDebounce();
    return {
      peekModeSvc,
      inPlanningDetailsCtx,
      planningDetailsCtxModifiers,
      inGoalDetailsCtx,
      goalDetailsCtxModifiers,
      inPublishedViewDetailsCtx,
      personalAppSettings,
      updatePersonalAppSettings,
      viewMode,
      sidePeekWidth: computed(() => personalAppSettings.value?.goalSidePeekWidth),
      debounce,
      flush,
      goalViewMode: computed(() => personalAppSettings.value?.goalViewMode),
    };
  },
  components: { ProductTour, GoalPage: defineAsyncComponent(() => import('@/components/goal/GoalPage.vue')) },
  data() {
    return {
      bodyClass: '_goal-details-dialog-body',
      sidePeekStartX: 0,
      draggingWidth: 0,
      windowWidth: window.innerWidth,
      dragging: false,
      showTour: false,
      showSidePeekContent: false,
    };
  },
  computed: {

    productTourSteps() {
      return [
        {
          highlightedElement: 'view-mode-btn',
          heading: this.$t('peekView.productTour.heading'),
          content: this.$t('peekView.productTour.body'),
          placement: 'bottomLeft',
        },
      ];
    },
    sidePeek() {
      // Default to side peek to show side peek to existing users without a setting saved.
      return this.goalViewMode !== viewMode.centerPeek;
    },
    width() {
      if (this.dragging) {
        return this.constrainWidth(this.draggingWidth);
      }
      return this.constrainWidth(this.sidePeekWidth);
    },
    sidePeakStyle() {
      return { width: `${this.width}px` };
    },
    openChildrenInModal() {
      return this.inPublishedViewDetailsCtx;
    },
    showGoalModal() {
      if (this.sidePeek) {
        return false;
      }
      return this.$route.query.goalId !== undefined;
    },
    showSidePeek() {
      if (!this.sidePeek) {
        return false;
      }
      return this.$route.query.goalId !== undefined;
    },
    goalId() {
      return getQueryParamAsInt(this.$route, 'goalId');
    },
    routeWithoutGoalId() {
      const query = { ...this.$route.query };
      delete query.goalId;
      return { ...this.$route, query };
    },
    context() {
      if (this.inPlanningDetailsCtx) {
        return CASCADE_CONTEXT_PLANNING;
      }
      if (this.inPublishedViewDetailsCtx) {
        return CASCADE_CONTEXT_PUBLISHED_VIEW;
      }
      return '';
    },
    modifiers() {
      if (this.inPlanningDetailsCtx) {
        return this.planningDetailsCtxModifiers;
      }
      if (this.inGoalDetailsCtx) {
        return this.goalDetailsCtxModifiers;
      }
      return [];
    },
    goalPageRef() {
      if (this.showSidePeek) {
        return this.$refs.goalPageSidePeek;
      }
      if (this.showGoalModal) {
        return this.$refs.goalPage;
      }
      return null;
    },
  },
  methods: {
    constrainWidth(width) {
      if (width < MIN_WIDTH) {
        return MIN_WIDTH;
      }
      if (width >= this.windowWidth * MAX_WIDTH) {
        return Math.round(this.windowWidth * MAX_WIDTH);
      }
      return width;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    closeGoalModal() {
      if (this.goalPageRef !== null && (this.showGoalModal || this.showSidePeek)) {
        this.goalPageRef.checkToDeleteEmpty(() => {
          this.showSidePeekContent = false;
          // We have to remove the portal content after the transition ends
          setTimeout(() => {
            this.$router.push(this.routeWithoutGoalId);
          }, 250);
        });
      }
    },
    goToGoalDetail(goal, modal = false) {
      this.peekModeSvc.openGoal({ goalId: goal.uid, modal });
    },
    handleDragStart(event) {
      event.stopPropagation();
      event.preventDefault();

      document.body.classList.add('no-interaction');

      window.addEventListener('mousemove', this.handleDrag);
      window.addEventListener('mouseup', this.handleDragEnd);
      this.draggingWidth = this.sidePeekWidth;
      this.dragging = true;
      this.sidePeekStartX = event.clientX;
    },
    handleDragEnd() {
      window.removeEventListener('mousemove', this.handleDrag);
      window.removeEventListener('mouseup', this.handleDragEnd);
      document.body.classList.remove('no-interaction');

      this.dragging = false;

      this.flush();
      this.updatePersonalAppSettings({
        uid: this.personalAppSettings.uid,
        goalSidePeekWidth: this.draggingWidth,
      });
    },
    productTourEnd() {
      this.updatePersonalAppSettings({
        uid: this.personalAppSettings.uid,
        goalViewMode: this.viewMode.sidePeek,
      });
      this.showTour = false;
    },
    handleDrag(event) {
      event.preventDefault();
      event.stopPropagation();

      if (!this.dragging) {
        return;
      }

      const update = () => {
        this.draggingWidth = this.constrainWidth(this.sidePeekWidth) - event.clientX + this.sidePeekStartX;
      };

      this.debounce(update, 5);
    },
    handleOutsideClick(event) {
      const parent = event.target.parentElement;
      const grandparent = parent ? parent.parentElement : null;

      // This covers clicks in page body, dialogs and the main navigation
      const classList = ['scroll-container', '__dialog-content-wrapper', 'main-navigation'];
      if (classList.some((className) => parent?.classList.contains(className) || grandparent?.classList.contains(className))) {
        this.closeGoalModal();
      }
    },
    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        event.stopPropagation();
        this.closeGoalModal();
      }
    },
  },
  watch: {
    showSidePeek: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.showSidePeekContent = true;
          });
        }
        if (newValue && this.goalViewMode === null) {
          setTimeout(() => {
            this.showTour = true;
          }, 300);
          return;
        }
        this.showTour = false;
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('resize', this.updateWindowWidth);
    window.addEventListener('mouseup', this.handleOutsideClick);
    window.addEventListener('keydown', this.handleEscapeKey);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
    window.removeEventListener('mouseup', this.handleOutsideClick);
    window.removeEventListener('keydown', this.handleEscapeKey);
  },
};
</script>

<style scoped lang="scss">
  ._side-peek {
    display: flex;
    position: fixed;
    z-index: 1050;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
    border-left: 1px solid $border-color;
    box-shadow: -3px 0 15px $hover-color;

    ._drag-handle {
      width: 0.4rem;
      height: 100%;
      cursor: col-resize;
      background-color: transparent;
      flex-shrink: 0;
    }
  }

</style>
