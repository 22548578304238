<template>
  <published-view />
</template>

<script setup>
import PublishedView from '@/views/PublishedView.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import usePersonalAppSettings from '@/composables/logged-in-user/personal-app-settings';
import useSavedViewRepo from '@/composables/saved-views/saved-view-repo';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { computed } from 'vue';
import { currentGoalCycle } from '@/lib/goal/cycles';
import { useRoute } from 'vue-router';

const route = useRoute();

const { selectSingle: getView } = useSavedViewRepo();

const viewId = computed(() => parseInt(route.params.savedViewId, 10));
const publicView = computed(() => getView(viewId.value));

const { loggedInUserAccount } = useLoggedInUserAccount();
const { goalCycles } = useGoalCycle();
const goalSettingsSvc = useGoalSettings();
const { personalAppSettings, updateSingle: updatePersonalAppSettings } = usePersonalAppSettings();

goalSettingsSvc.setDefaultValueFeatureName();
updatePersonalAppSettings({
  uid: personalAppSettings.value.uid,
  selectedGoalCycles: currentGoalCycle(goalCycles.value),
}, { commitToRemote: false });
EventBus.$emit(EVENTS.VIEW.PUBLISHED_VIEW_VISITED, { account: loggedInUserAccount.value, view: publicView.value });

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
</style>
