<template>
  <div class="property-editor">
    <div class="_label">
      {{ textByLang(prop.label, userLang) }}
    </div>
    <div class="_item">
      <goal-cycle-selector
        v-if="prop.edgeName === goalConfig.edges.goalCycle"
        ref="goalCycleSelector"
        v-model:value="selectedCycles"
        :items="goalCycles"
        full-width
        show-search
        auto-focus
        keep-open-on-click
        multiple
        placement="onTopLeft"
        :placeholder="$t('general.empty')"
      />
      <goal-picker-v2
        v-else-if="prop.edgeName === goalConfig.edges.parents"
        ref="goalPicker"
        v-model:value="selectedParentIds"
        full-width
        :excluded-goal-ids="[...goals.map(g => g.uid)]"
        :disabled-condition="disableCondition"
        :placeholder="$t('general.empty')"
        :multiple="accountSettings.usesMultiGoalAlignment"
        :default-view="defaultView"
        :view-application="viewApplication"
        :ok-text="() => $t('general.select')"
        show-input-field
        show-footer
        show-create-view
        show-views-selector
        :initial-selected-cycles="selectedCycles"
        placement="onTopCenter"
        :base-filter="goalBaseFilter"
        :max-tag-text-length="50"
        auto-focus
        show-description
      />
      <property-form-item
        v-else
        :property="prop"
        full-width
        auto-focus
        :placeholder="$t('general.empty')"
        :property-values="[value]"
        restrict-foreign-entity-selection
        @change="updateValue"
      />
    </div>
    <div class="_actions">
      <m-btn
        class="_btn"
        hide-border
        small
        @click="$emit('close')"
      >
        {{ $t('general.cancel') }}
      </m-btn>
      <m-btn
        color="primary"
        class="_btn"
        small
        :loading="loading"
        @click="save"
      >
        {{ $t('general.save') }}
      </m-btn>
    </div>
  </div>
</template>

<script>
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import GoalPickerV2 from '@/components/goal/GoalPickerV2.vue';
import PropertyFormItem from '@/components/PropertyFormItem.vue';
import intersection from 'lodash-es/intersection';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useParentSelectorRules from '@/composables/goal/validator/parent-selector-rules';
import useValidator from '@/composables/goal/validator/validator';
import { AND } from 'shared/api/query/constants';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { initValue } from '@/lib/property';
import { logCatch } from '@/lib/logger/logger';
import { stripEntity, stripGoal, stripPropertyValue } from '@/lib/bulk-actions';
import { textByLang } from 'shared/lib/language';
import { useStore } from 'vuex';
import { viewApplication, viewType } from 'shared/constants.json';

export default {
  name: 'PropertyEditor',
  props: {
    goals: {
      type: Array,
      required: true,
    },
    prop: {
      type: Object,
      required: true,
    },
    successMessage: {
      type: String,
      default: '',
    },
    goalBaseFilter: {
      type: Object,
      default: () => ({}),
    },
    ignorePrefilled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'edited'],
  setup() {
    const store = useStore();
    const { properties: goalProperties } = useGoalProperty();
    const goalsSvc = useGoals();
    const { goalCycles } = useGoalCycle();
    const { userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { accountSettings } = useAccountSettings();

    const { goalTypeProperty } = useGoalTypeProperty();
    const { rules } = useParentSelectorRules(goalTypeProperty.value.options);
    const { validate } = useValidator({
      rules,
      usesOKRRules: true,
    });

    return {
      store,
      validate,
      goalCycles,
      goalProperties,
      account: loggedInUserAccount,
      accountSettings,
      loading: goalsSvc.mutateLoading,
      mutateMultiple: goalsSvc.mutateMultiple,
      userLang,
      goalsSvc,
    };
  },
  components: {
    GoalCycleSelector,
    GoalPickerV2,
    PropertyFormItem,
  },
  data() {
    return {
      goalConfig,
      textByLang,
      value: null,
      selectedCycles: [],
      selectedParentIds: [],
      viewApplication: viewApplication.goalParentPicker,
    };
  },
  computed: {
    defaultView() {
      return {
        title: this.$t('list.cascade'),
        viewType: viewType.cascade,
        viewApplication: this.viewApplication,
        params: {
          filter: { account: { uid: this.account.uid }, children: [], op: AND },
          props: [],
          order: [],
          wrapCells: true,
        },
      };
    },
    isValid() {
      switch (this.prop.edgeName) {
        case goalConfig.edges.goalCycle:
        case goalConfig.edges.parents:
          return true;
        default:
          return this.value !== null;
      }
    },
  },
  methods: {
    disableCondition(goal) {
      // TODO: In case the goals have different goal types, we cannot validate the goals correctly.
      // A possible solution could be to display a hint to the user.
      return Object.keys(this.validate({ toValidate: goal, selectRulesFrom: this.goals[0] })).length > 0;
    },
    updateValue(value) {
      this.value = value;
    },
    save() {
      if (!this.isValid) {
        return;
      }

      if (this.prop.edgeName === goalConfig.edges.parents) {
        this.goalsSvc.changeParents({ goals: this.goals, parents: this.selectedParentIds.map((uid) => ({ uid })) }).then(() => {
          this.$showSnackbar({ color: 'success', message: this.successMessage });
          this.$emit('close');
          this.$emit('edited');
        }).catch(() => {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        });
        return;
      }

      const goalsToSave = this.prepare();
      this.mutateMultiple(goalsToSave).then(() => {
        if (this.successMessage !== '') {
          this.$showSnackbar({ color: 'success', message: this.successMessage });
        }
        this.$emit('close');
        this.$emit('edited');
      }).catch(logCatch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    prepare() {
      switch (this.prop.edgeName) {
        case goalConfig.edges.goalCycle:
          return this.goals.map((g) => {
            if (g.uid === undefined) {
              // This caters for caller: duplicate from cycle
              return stripGoal({ ...g, goalCycle: this.selectedCycles });
            }
            return { uid: g.uid, goalCycle: this.selectedCycles.map(stripEntity) };
          });
        default:
          return this.goals.map((g) => ({
            uid: g.uid,
            properties: this.transformProperties({ properties: g.properties, value: this.value }).map(stripPropertyValue),
          }));
      }
    },
    transformProperties({ properties, value }) {
      delete value.uid;
      return properties.map((pv) => {
        if (pv.property.uid !== value.property.uid) {
          return pv;
        }

        return {
          ...pv,
          ...value,
        };
      });
    },
    initGoalCycles() {
      this.selectedCycles = intersection(...this.goals.map((g) => g.goalCycle.map((c) => c.uid))).reduce((res, cur) => {
        const c = this.goalCycles.find((c) => cur === c.uid);
        if (typeof c !== 'undefined') {
          res.push(c);
        }
        return res;
      }, []);
    },
    init() {
      switch (this.prop.edgeName) {
        case goalConfig.edges.goalCycle:
          this.initGoalCycles();
          break;
        case goalConfig.edges.parents:
          this.initGoalCycles();
          this.selectedParentIds = intersection(...this.goals.map((g) => g.parents.map((c) => c.uid)));
          break;
        default: {
          this.value = initValue({ propertyOwners: this.goals, property: this.prop });
          break;
        }
      }
    },
  },
  created() {
    if (!this.ignorePrefilled) {
      this.init();
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .property-editor {
    min-width: 25rem;

    ._label {
      margin-bottom: .2rem;
      font-size: $font-size-4;
      color: $font-color-secondary;
    }

    ._item {
      margin-bottom: 1rem;
    }

    ._actions {
      display: flex;
      justify-content: flex-end;

      ._btn {
        margin-left: .4rem;
      }
    }
  }
</style>
