<template>
  <m-dropdown
    v-model:value="visible"
    class="goals-sorter"
    :title="$t('listSorter.title')"
    :block="inCardMenu"
    placement="bottomCenter"
  >
    <component
      :is="inCardMenu ? 'm-card-item' : 'm-btn'"
      ref="button"
      class="_btn"
      small
      hide-border
      :icon="inCardMenu ? 'order' : ''"
      :color="color"
      :light="color === '' && !inCardMenu"
      @click="visible = true"
    >
      {{ $t('goalsSorter.sort') }}
    </component>
    <template #overlay>
      <m-card
        ref="card"
        class="_overlay"
        small-padding
      >
        <saved-view-info
          v-if="showSavedViewInfo && savedView !== null"
          :view="savedView"
          :label="$t('savedViewInfo.sortLabel')"
          class="_info"
        />
        <m-tooltip
          placement="left"
          :disabled="!disabled"
          :mouse-enter-delay=".5"
        >
          <div>
            <sorter-builder
              :value="value"
              :sort-options="sortOptions"
              :disabled="disabled"
              @input="update"
            />
          </div>
          <template #title>
            {{ $t('savedViewInfo.disabledInfo') }}
          </template>
        </m-tooltip>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import SavedViewInfo from '@/components/list/SavedViewInfo.vue';
import SorterBuilder from '@/components/SorterBuilder.vue';

export default {
  name: 'ListSorter',
  props: {
    inCardMenu: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
    sortOptions: {
      type: Array,
      required: true,
    },
    showSavedViewInfo: {
      type: Boolean,
      default: false,
    },
    savedView: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  components: { SavedViewInfo, SorterBuilder },
  data() {
    return { visible: false };
  },
  computed: {
    color() {
      if (this.value.length > 0) {
        return this.inCardMenu ? this.$colors.blue.base : 'primary';
      }

      return '';
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value);
      this.$emit('update:value', value);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._overlay {
    min-width: 35rem;
    max-width: 64rem;

    ._info {
      margin-bottom: 1.2rem;
    }
  }
</style>
