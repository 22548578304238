<template>
  <div :class="['m-url-field', value === '' ? '-empty' : '', fullWidth ? '-full-width' : '', small ? '-small' : '']">
    <m-textarea
      ref="input"
      :value="value"
      :placeholder="placeholder"
      :hide-placeholder="hidePlaceholder"
      :clearable="clearable"
      :disabled="disabled"
      :read-only="readOnly"
      :full-width="fullWidth"
      :hide-hover="hideHover"
      :auto-focus="autoFocus"
      :hide-border="hideBorder"
      :small="small"
      :m-style="mStyle"
      :placeholder-icon="placeholderIcon"
      auto-size
      class="_input"
      @input="change"
      @change="change"
      @blur="$emit('blur')"
    />
    <div
      class="_actions"
    >
      <m-tooltip>
        <m-btn
          v-if="value !== '' && url.indexOf('file://') !== 0"
          class="_btn"
          :href="url"
          icon="link"
          hide-border
          fab
          target="_blank"
          super-light
          xs
        />
        <template #title>
          {{ $t('mUrlField.openLink') }}
        </template>
      </m-tooltip>
      <m-tooltip>
        <m-btn
          v-if="value !== ''"
          class="_btn"
          icon="copy"
          hide-border
          fab
          target="_blank"
          super-light
          xs
          @click="copyToClipboard"
        />
        <template #title>
          {{ $t('mUrlField.copyLink') }}
        </template>
      </m-tooltip>
    </div>
  </div>
</template>

<script>
import MTextarea from 'shared/components/base/MTextarea.vue';
import { mStyleProps } from 'shared/lib/m-style-props';

export default {
  name: 'MUrlField',
  props: {
    ...mStyleProps,
    value: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value', 'change', 'blur'],
  components: { MTextarea },
  computed: {
    url() {
      if (this.value.indexOf('http://') === 0 || this.value.indexOf('https://') === 0 || this.value.indexOf('file://') === 0) {
        return this.value;
      }

      return `http://${this.value}`;
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.value);
      this.$showSnackbar({ color: 'success', message: this.$t('success.copied') });
    },
    change(val) {
      this.$emit('change', val);
      this.$emit('input', val);
      this.$emit('update:value', val);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .m-url-field {
    position: relative;

    &.-full-width {
      width: 100%;
    }

    ._input {
      word-break: break-word;
      white-space: pre-wrap;
    }

    &:not(.-empty) {
      ._input {
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-color: map_get($grey, 'lighten-3');
      }
    }

    ._actions {
      position: absolute;
      top: .5rem;
      right: .6rem;
      z-index: 1;
      display: none;

      ._btn {
        @include box_shadow(0);

        margin-left: .4rem;
        background: white;
        border-radius: $btn-border-radius;
      }
    }

    &.-small {
      ._btn {
        top: .3rem;
      }
    }

    &:hover {
      ._actions {
        display: flex;
        align-items: center;
      }
    }
  }
</style>
