<template>
  <grid-page-loading-error
    v-if="error !== null && !loading"
    @retry="init"
  />
  <div
    v-else-if="gridPageTileChart === null || loading"
    class="_spinner"
  >
    <m-spinner />
  </div>
  <m-content
    v-else
    class="grid-page-chart"
    padding-x="10"
    padding-y="10"
  >
    <grid-page-chart
      :chart="gridPageTileChart"
    />
  </m-content>
</template>

<script setup>
import GridPageChart from '@/components/custom-grid/chart/GridPageChart.vue';
import GridPageLoadingError from '@/components/custom-grid/GridPageLoadingError.vue';
import useGridPage from '@/composables/grid-page/grid-page-repo';
import { computed, ref } from 'vue';
import { logCatch } from '@/lib/logger/logger';

// TODO: Calculate height of chart depending of tile height
const props = defineProps({ gridPageTile: { type: Object, required: true } });

const gridPageSvc = useGridPage();
const gridPageTileChart = computed(() => {
  const res = gridPageSvc.gridPageTileChart.value.find((e) => e.tile.uid === props.gridPageTile.uid);
  if (res === undefined) {
    return null;
  }

  return res;
});

const error = ref(null);
const loading = ref(false);
const init = () => {
  loading.value = true;
  error.value = null;
  gridPageSvc.queryGridPageTileChartByTile(props.gridPageTile)
    .catch(logCatch((e) => {
      error.value = e;
    }))
    .finally(() => {
      loading.value = false;
    });
};

init();
</script>

<style scoped lang="scss">
.grid-page-chart {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;

  ._chart {
    width: 100%;
    height: 100%;
  }
}
</style>
