<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-cy="minus-icon"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 13.5H2V10.5H22V13.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default { name: 'MinusSquare' };
</script>

<style
    scoped
    lang="
      scss"
      type="text/scss"
    >
</style>
