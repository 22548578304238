<template>
  <m-focusable
    ref="wrapper"
    :small="small"
    :show-placeholder="!focused && !hidePlaceholder && placeholder !== '' && value === ''"
    :full-width="fullWidth"
    :hide-border="hideBorder"
    :hide-hover="hideHover"
    :read-only="readOnly"
    :placeholder-icon="placeholderIcon"
    :placeholder="placeholder"
    :class="classes"
    :disabled="disabled"
    :focused="focused"
    :m-style="mStyle"
    no-padding
    wrap-placeholder
    :placeholder-style="placeholderStyle"
    @click="handleClick"
  >
    <textarea
      ref="textarea"
      :placeholder="placeholder"
      :rows="rows"
      :value="value"
      :disabled="disabled"
      :class="['_input']"
      :style="resolveStyles({ ...defaultStyle, ...mStyle })"
      @input="update"
      @blur="blur"
      @focus="$emit('focus', $event)"
    />
    <m-btn
      v-if="clearable"
      class="_clear-btn"
      icon="close-circle"
      fab
      light
      small
      hide-border
      @click="clear"
    />
  </m-focusable>
</template>

<script>
import { mStyleProps, resolveStyles } from 'shared/lib/m-style-props';

export default {
  name: 'MTextarea',
  props: {
    ...mStyleProps,
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inheritStyling: {
      type: Boolean,
      default: false,
    },
    autoSize: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
  },
  emits: ['input', 'update:value', 'change', 'blur', 'focus', 'click'],
  data() {
    return { height: 0, focused: false };
  },
  computed: {
    defaultStyle() {
      if (this.xs) {
        return { fontStyle: { fontSize: 'small' } };
      }
      return {};
    },
    placeholderStyle() {
      if (this.small) {
        return { minHeight: `${(this.rows * 18) / 10}rem` };
      }
      return { minHeight: `${(this.rows * 21) / 10}rem` };
    },
    classes() {
      return [
        'm-textarea',
        this.disabled ? '-disabled' : '',
        this.fullWidth ? '-full-width' : '',
        this.hideBorder ? '-hide-border' : '',
        this.hideHover ? '-hide-hover' : '',
        this.readOnly ? '-read-only' : '',
        this.inheritStyling ? '-inherit-styling' : '',
        this.autoSize ? '-auto-size' : '',
        this.disabled ? '-no-resize' : '',
        this.small ? '-small' : '',
        this.xs ? '-xs' : '',
      ];
    },
  },
  methods: {
    resolveStyles,
    handleClick(event) {
      this.$emit('click', event);
      this.focus();
    },
    resize() {
      if (!this.autoSize || typeof this.$refs.textarea === 'undefined') {
        return;
      }

      if (this.value === '') {
        this.$refs.textarea.style.height = 'unset';
      }

      this.$refs.textarea.style.height = 'auto';
      this.$nextTick(() => {
        this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
        this.$refs.wrapper.$el.style.height = `${this.$refs.textarea.scrollHeight}px`;
      });
    },
    update(event) {
      this.$emit('change', event.target.value);
      this.$emit('input', event.target.value);
      this.$emit('update:value', event.target.value);
      this.resize();
    },
    blur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    focus() {
      this.focused = true;
      this.$nextTick(() => {
        if (typeof this.$refs.textarea === 'undefined' || this.$refs.textarea === null) {
          return;
        }
        this.$refs.textarea.focus();
      });
    },
    clear() {
      this.$emit('change', '');
      this.$emit('input', '');
      this.$emit('update:value', '');
      this.resize();
    },
  },
  mounted() {
    this.resize();
    if (this.autoFocus) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.focus();
        });
      });
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-textarea {
    position: relative;

    ._input {
      width: 100%;
      height: 100%;
      padding: .3rem 1rem;
      cursor: inherit;
      resize: vertical;
      border: none;
      border-radius: $input-field-border-radius;
      outline: none;

      &:focus,
      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: $font-color-tertiary;
      }
    }

    &.-small {
      ._input {
        padding: .2rem .6rem;
      }
    }

    &.-xs {
      ._input {
        padding: .2rem .4rem;
      }
    }

    &.-auto-size {
      ._input {
        overflow: hidden;
        resize: none;
      }
    }

    &.-no-resize {
      ._input {
        resize: none;
      }
    }

    ._clear-btn {
      position: absolute;
      top: .4rem;
      right: .4rem;
      z-index: 1;
      display: none;
    }

    &:hover {
      &:not(.-disabled) {
        ._clear-btn {
          display: flex;
        }
      }
    }
  }
</style>
