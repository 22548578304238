<template>
  <m-item-wrapper>
    <div
      :class="[
        'favorite-nav-item',
        showBtn ? '-show' : '',
        active ? '-active' : '',
      ]"
      :data-id="item.uid"
    >
      <div
        v-if="draggingOverTop"
        class="_drag-over-top"
      />
      <m-tooltip
        placement="right"
        :disabled="!isTitleTruncated"
        :mouse-enter-delay="0.5"
      >
        <template #title>
          {{ item.title }}
        </template>
        <m-link
          :to="link"
          inherit-color
        >
          <div class="_inner">
            <div class="_left">
              <item-title
                v-model:is-title-truncated="isTitleTruncated"
                class="_text"
                :title="item.title"
                :icons="[{ value: icon, size: 14 }]"
              />
            </div>
            <div class="_right">
              <m-dropdown
                v-model:value="show"
                :title="$t('general.actions')"
              >
                <m-btn
                  icon="ellipsis"
                  light
                  fab
                  xs
                  hide-border
                  @click.stop.prevent="show = true"
                />
                <template #overlay>
                  <m-card
                    list
                    no-padding
                  >
                    <favorite-editor :entity="item" />
                  </m-card>
                </template>
              </m-dropdown>
            </div>
          </div>
        </m-link>
      </m-tooltip>
      <div
        v-if="draggingOverBottom"
        class="_drag-over-bottom"
      />
    </div>
  </m-item-wrapper>
</template>

<script>
import FavoriteEditor from '@/components/favorite/FavoriteEditor.vue';
import ItemTitle from '@/components/ItemTitle.vue';
import MItemWrapper from 'shared/components/base/MItemWrapper.vue';
import { buildIcon, buildIconFromEntity, isEmptyIcon } from 'shared/lib/icon';
import { routeName } from 'shared/constants.json';

export default {
  name: 'FavoriteNavItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemTitle,
    FavoriteEditor,
    MItemWrapper,
  },
  data() {
    return {
      isTitleTruncated: false,
      show: false,
    };
  },
  computed: {
    showBtn() {
      return this.show || this.$store.state.breakpoint.smAndDown;
    },
    icon() {
      const icon = buildIconFromEntity(this.item);
      if (!isEmptyIcon(icon)) {
        return icon;
      }

      return buildIcon(this.iconByRoute);
    },
    iconByRoute() {
      switch (this.item.routeName) {
        case routeName.goalsExplorer:
        case routeName.goalDetails:
        case routeName.planningDetails:
        case routeName.planningList:
          return 'CompassOutlined';
        case routeName.updateTemplates:
          return 'FileTextOutlined';
        case routeName.updatesExplorer:
        case routeName.goalUpdateFeed:
        case routeName.updateSchedules:
        case routeName.updateScheduleDetails:
          return 'History';
        case routeName.dashboards:
        case routeName.dashboardDetails:
        case routeName.goalInsights:
          return 'AppstoreOutlined';
        case routeName.spaceDetails:
          return 'Team';
        default:
          return '';
      }
    },
    link() {
      return {
        name: this.item.routeName,
        params: this.item.params,
        query: this.item.query,
      };
    },
    active() {
      return (
        this.$route.name === this.item.routeName
        && JSON.stringify(this.$route.params)
          === JSON.stringify(this.item.params)
        && JSON.stringify(this.$route.query) === JSON.stringify(this.item.query)
      );
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.favorite-nav-item {
  position: relative;
  border-radius: $btn-border-radius;

  ._drag-over-top {
    position: absolute;
    top: -2px;
    right: 0;
    left: 0;
    z-index: 88;
    width: 100%;
    height: 4px;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

  ._drag-over-bottom {
    position: absolute;
    right: 0;
    bottom: -2px;
    left: 0;
    z-index: 88;
    width: 100%;
    height: 4px;
    pointer-events: none;
    background: $highlighted-color-dark;
    opacity: 1;
  }

  ._inner {
    display: flex;
    align-items: center;
    height: 2.8rem;
    padding: 0 0.6rem 0 1rem;
    font-weight: $font-weight-medium;
    color: map_get($grey, "darken-2");

    ._left {
      min-width: 0;

      ._text {
        display: block;
      }
    }

    ._right {
      display: none;
      margin-right: 0.2rem;
      margin-left: auto;
    }
  }

  &.-show {
    ._inner {
      ._right {
        display: flex;
      }
    }
  }

  &.-active {
    background-color: $sidebar-hover-color;

    ._inner {
      ._left {
        ._text {
          font-weight: $font-weight-semibold;
          color: $font-color-primary;
        }
      }
    }
  }

  &:hover {
    background-color: $sidebar-hover-color;

    ._inner {
      ._right {
        display: flex;
      }
    }
  }
}
</style>
