<template>
  <div class="grid-page-chart-editor">
    <div class="_top">
      <div class="_left">
        <breadcrumbs :breadcrumbs="breadcrumbItems" />
        <div class="_divider">
          /
        </div>
        <m-dropdown
          :relocate-key="title"
        >
          <m-btn
            type="clickable"
            hide-border
            small
          >
            {{ maxLength(props.gridPageTile.title, 30) }}
          </m-btn>
          <template #overlay>
            <m-card
              padding-xxs
              :style="{ width: '20rem'}"
            >
              <m-focusable>
                <simple-editor
                  auto-focus
                  :initial-value="props.gridPageTile.title"
                  :read-only="false"
                  auto-select
                  :placeholder="$t('gridPageChartEditor.titlePlaceholder')"
                  @update:value="updateTitle"
                />
              </m-focusable>
            </m-card>
          </template>
        </m-dropdown>
        <m-dropdown
          v-model:value="descriptionEditMode"
          :style="{ marginLeft: '.6rem' }"
        >
          <m-btn
            type="clickable"
            small
            light
            :icon="props.gridPageTile.description === '' ? 'plus' : undefined"
            hide-border
          >
            <template v-if="props.gridPageTile.description !== ''">
              {{ maxLength(props.gridPageTile.description, 50) }}
            </template>
            <template v-else>
              {{ $t('gridPageChartEditor.addDescription') }}
            </template>
          </m-btn>
          <template #overlay>
            <m-card
              padding-xxs
              :style="{ width: '30rem'}"
            >
              <m-focusable>
                <simple-editor
                  :style="{ width: '100%' }"
                  :initial-value="props.gridPageTile.description"
                  :read-only="false"
                  auto-select
                  auto-focus
                  :placeholder="$t('gridPageChartEditor.descriptionPlaceholder')"
                  :enter-handler="updateDescription"
                  @update:value="updateDescription"
                  @enter="descriptionEditMode = false"
                />
              </m-focusable>
            </m-card>
          </template>
        </m-dropdown>
      </div>
      <div class="_right">
        <m-btn
          :style="{ marginRight: '.4rem' }"
          hide-border
          @click="emit('close')"
        >
          {{ $t('general.discardChanges') }}
        </m-btn>
        <m-btn
          color="primary"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-btn>
      </div>
    </div>
    <div class="_bottom">
      <div class="_left">
        <m-content
          class="grid-page-chart"
          padding-x="10"
          padding-y="10"
        >
          <grid-page-chart
            :chart="{ ...gridPageTileChart, chartType: chartType }"
          />
        </m-content>
      </div>
      <div class="_right">
        <m-section
          :title="$t('gridPageChartEditor.title')"
          heading-size="h5"
        >
          <m-form-item :label="$t('gridPageChartEditor.source')">
            <m-select
              v-model:value="source"
              :items="sources"
              full-width
            />
          </m-form-item>
          <m-form-item :label="$t('gridPageChartEditor.filter')">
            <m-dropdown
              block
              :relocate-key="filter"
            >
              <m-focusable
                type="clickable"
                full-width
              >
                <m-tag
                  icon="filter"
                  :title="$t('gridPageChartEditor.filter')"
                  color="none"
                />
                <m-tag
                  v-if="filter?.children.length > 0"
                  :title="filter?.children.length"
                  small
                  :style="{ marginLeft: '.6rem' }"
                />
              </m-focusable>
              <template #overlay>
                <m-card
                  padding-xs
                  :style="{ minWidth: '30rem' }"
                >
                  <scope-filter
                    v-model:value="filter"
                    can-remove-all
                    :props="filterProps"
                    :account="loggedInUserAccount"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </m-form-item>
          <m-form-item :label="$t('gridPageChartEditor.cycle')">
            <goal-cycle-selector
              v-model:value="selectedCycles"
              keep-open-on-click
              multiple
              full-width
              wrap
            />
          </m-form-item>
        </m-section>
        <m-section
          :title="$t('gridPageChartEditor.chart')"
          heading-size="h5"
        >
          <m-form-item class="_chart-type-selector">
            <m-tooltip
              v-for="type in chartTypes"
              :key="type"
              class="_chart"
            >
              <m-focusable
                type="clickable"
                :class="{ '_chart-btn': true, '-selected': chartType === type }"
                @click="chartType = type"
              >
                <div class="_icon-wrapper">
                  <m-icon
                    :type="`${type}-chart`"
                    size="20"
                  />
                </div>
              </m-focusable>
              <template #title>
                {{ $t(`gridPageChartEditor.${type}`) }}
              </template>
            </m-tooltip>
          </m-form-item>
        </m-section>
        <template v-if="['bar', 'line'].includes(chartType)">
          <m-section
            :title="$t('gridPageChartEditor.xAxis')"
            heading-size="h5"
          >
            <m-form-item :label="$t('gridPageChartEditor.whatToShow')">
              <m-select
                v-model:value="xAxisGroupBy"
                :items="xAxisOptions"
                full-width
                show-search
                match-trigger-width
              />
            </m-form-item>
          </m-section>
          <m-section
            :title="$t('gridPageChartEditor.yAxis')"
            heading-size="h5"
          >
            <m-form-item :label="$t('gridPageChartEditor.whatToShow')">
              <m-select
                v-model:value="yAxisGroupBy"
                :items="yAxisOptions"
                full-width
              />
            </m-form-item>
            <m-form-item :label="$t('gridPageChartEditor.groupBy')">
              <m-select
                v-model:value="yAxisGroupBy"
                :items="groupByOptions"
                full-width
              />
            </m-form-item>
          </m-section>
        </template>
        <template v-if="chartType === 'pie'">
          <m-section
            :title="$t('gridPageChartEditor.pie')"
            heading-size="h5"
          >
            <m-form-item :label="$t('gridPageChartEditor.whatToShow')">
              <m-select
                v-model:value="pieWhatToShow"
                :items="pieWhatToShowOptions"
                full-width
              />
            </m-form-item>
            <m-form-item :label="$t('gridPageChartEditor.representation')">
              <m-select
                v-model:value="pieRepresentation"
                :items="pieRepresentationOptions"
                full-width
              />
            </m-form-item>
          </m-section>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import GoalCycleSelector from '@/components/goal/cycle/GoalCycleSelector.vue';
import GridPageChart from '@/components/custom-grid/chart/GridPageChart.vue';
import ScopeFilter from '@/components/filter/ScopeFilter.vue';
import SimpleEditor from '@/components/SimpleEditor.vue';
import useDebounce from '@/composables/debounce';
import useGoalDefaultProps from '@/composables/saved-views/goal-default-props';
import useGridPage from '@/composables/grid-page/grid-page';
import useGridPageTileChart from '@/composables/grid-page/chart/grid-page-tile-chart';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import { CREATED_AT, MODIFIED_AT } from 'shared/api/query/constants';
import { chartGroupByOption, routeName } from 'shared/constants.json';
import { computed, ref } from 'vue';
import { goalActivity as goalActivityConfig, goal as goalConfig, update as updateConfig } from 'shared/api/query/configs.json';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  gridPageTile: {
    type: Object,
    required: true,
  },
  gridPage: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const breadcrumbItems = ref([
  { title: t('views.dashboards'), to: { name: routeName.dashboards } },
  { title: props.gridPage.title, onClick: () => { emit('close'); } },
]);

const debounceSvc = useDebounce();
const gridPageService = useGridPage();

function updateTitle(val) {
  debounceSvc.debounce(() => gridPageService.updateGridPageTile({ uid: props.gridPageTile.uid, title: val }), 500);
}

const descriptionEditMode = ref(false);
function updateDescription(val) {
  debounceSvc.debounce(() => gridPageService.updateGridPageTile({ uid: props.gridPageTile.uid, description: val }), 500);
}

const chartService = useGridPageTileChart();
const gridPageSvc = useGridPage();
const gridPageTileChart = computed(() => {
  const res = gridPageSvc.gridPageTileChart.value.find((e) => e.tile.uid === props.gridPageTile.uid);
  if (res === undefined) {
    return null;
  }

  return res;
});

const save = () => {
  chartService.updateSingle({
    uid: gridPageTileChart.value.uid,
    source: source.value,
    chartType: chartType.value,
    filter: filter.value,
    goalCycles: selectedCycles.value,
    groupBy: groupBy.value,
    aggregationConfig: aggregationConfig.value,
  });
};

// TODO: add grouping of dates
const groupBy = computed(() => {
  const p = defaultGoalProps.value.find((e) => e.key === xAxisGroupBy.value);
  if (p === undefined) {
    return { uid: gridPageTileChart.value.groupBy.uid, groupBy: chartGroupByOption.none };
  }

  if (p.isDirect) {
    return { uid: gridPageTileChart.value.groupBy.uid, groupBy: chartGroupByOption.unique, isProperty: false, edge: p.property.edgeName, edgeType: p.property.type };
  }
  return { uid: gridPageTileChart.value.groupBy.uid, groupBy: chartGroupByOption.unique, isProperty: true, property: { uid: p.property.uid } };
});

// TODO: add aggregation config
const aggregationConfig = computed(() => gridPageTileChart.value.aggregationConfig);

const maxLength = (str, max) => {
  if (str.length === 0) {
    return t('general.untitled');
  }
  if (str.length > max) {
    return `${str.slice(0, max)}...`;
  }
  return str;
};

const source = ref(goalConfig.model);

const { loggedInUserAccount } = useLoggedInUserAccount();

const filter = ref(null);

const { defaultProps: defaultGoalProps } = useGoalDefaultProps();

const selectedCycles = ref([]);

// TODO: Add chart type for metric
const chartTypes = ['bar', 'line', 'pie'];
const chartType = ref('bar');

const xAxisGroupBy = ref(CREATED_AT);
const xAxisOptions = computed(() => [
  { value: CREATED_AT, text: t('edges.createdAt') },
  { value: MODIFIED_AT, text: t('edges.modifiedAt') },
  ...defaultGoalProps.value.map((e) => ({ value: e.key, text: e.label })),
]);
const count = 'count';
const yAxisGroupBy = ref(count);
const yAxisOptions = [{ value: count, text: t('gridPageChartEditor.count') }];

const none = 'none';
const groupByOptions = [{ value: none, text: t('gridPageChartEditor.none') }];

const filterProps = computed(() => {
  switch (source.value) {
    case goalConfig.model:
      return defaultGoalProps.value;
    case updateConfig.model:
      return [];
    case goalActivityConfig.model:
      return [];
    default:
      return [];
  }
});

const sources = [
  {
    text: t('gridPageChartEditor.goal'),
    icon: 'compass',
    value: goalConfig.model,
  },
  {
    text: t('gridPageChartEditor.checkin'),
    icon: 'history',
    value: updateConfig.model,
  },
  {
    text: t('gridPageChartEditor.goalActivity'),
    icon: 'retweet',
    value: goalActivityConfig.model,
  },
];
const initXAxis = () => {
  if (gridPageTileChart.value.groupBy === null) {
    xAxisGroupBy.value = null;
    return;
  }

  if (gridPageTileChart.value.groupBy.isProperty) {
    xAxisGroupBy.value = gridPageTileChart.value.groupBy.property.uid;
    return;
  }

  xAxisGroupBy.value = gridPageTileChart.value.groupBy.edge;
};

const initYAxis = () => {
  // TODO: add aggregation config init
};

if (gridPageTileChart.value !== null) {
  source.value = gridPageTileChart.value.source;
  chartType.value = gridPageTileChart.value.chartType;
  filter.value = gridPageTileChart.value.filter;
  selectedCycles.value = gridPageTileChart.value.goalCycles;
  initXAxis();
  initYAxis();
}
</script>

<style scoped lang="scss">
.grid-page-chart-editor {
  display: grid;
  grid-template-rows: 6rem 1fr;
  height: 100vh;

  ._top {
    flex: 0 0 4rem;
    border-bottom: 1px solid $input-border-color;
    display: flex;
    align-items: center;
    padding: 0 2rem;

    ._left {
      display: flex;

      ._divider {
        margin: 0 .6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    ._right {
      margin-left: auto;
      display: flex;
    }
  }

  ._bottom {
    display: flex;

    ._left {
      flex: 1 0 auto;
    }

    ._right {
      flex: 0 0 30rem;
      padding: 2rem;
      border-left: 1px solid $input-border-color;
    }
  }

  ._chart-type-selector {
    display: flex;
    gap: 1rem;

    ._chart {
      display: flex;
    }

    ._chart-btn {
      border: none;
      width: 4.2rem;
      height: 4.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 0 1px $input-border-color;

      ._icon-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.-selected {
        box-shadow: 0 0 0 2px $primary-color;
        background-color: $highlighted-color-light;
      }
    }
  }
}
</style>
