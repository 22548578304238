import useRepo from '@/nebula/repo';
import { computed } from 'vue';
import { guid } from 'shared/lib/uuid';
import { personalAppSettings as personalAppSettingsConfig } from 'shared/api/query/configs.json';

globalThis.__personalAppSettings = { STATIC_UID: guid() };
export default function usePersonalAppSettings(loggedInUser) {
  const repo = useRepo(personalAppSettingsConfig.model);

  const uid = globalThis.__personalAppSettings.STATIC_UID;
  const ps = computed(() => {
    const ps = repo.selectSingle(uid);
    if (ps === undefined) {
      return { uid };
    }
    return ps;
  });

  const personalAppSettings = computed(() => {
    if (loggedInUser?.value?.personalAppSettings === undefined) {
      return ps.value;
    }

    return repo.selectSingle(loggedInUser.value.personalAppSettings.uid);
  });

  return {
    personalAppSettings,
    updateSingle: repo.updateSingle,
    updateLoading: repo.updateLoading,
  };
}
