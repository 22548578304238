import { UID } from 'shared/api/query/constants';
import { aggregationConfigChildren } from '@/api/query/nebula/aggregation-config';
import {
  aggregationConfig as aggregationConfigConfig,
  chartGroupBy as chartGroupByConfig,
  goalCycle as goalCycleConfig,
  gridPageTileChart as gridPageTileChartConfig,
  gridPageTile as gridPageTileConfig,
} from 'shared/api/query/configs.json';
import { chartGroupByChildren } from '@/api/query/nebula/chart-group-by';

export const gridPageTileChartChildren = [
  { attr: UID },
  { attr: gridPageTileChartConfig.edges.source },
  { attr: gridPageTileChartConfig.edges.filter, default: null },

  { attr: gridPageTileChartConfig.edges.goalCycles, model: goalCycleConfig.model, children: [{ attr: UID }] },
  { attr: gridPageTileChartConfig.edges.chartType },
  { attr: gridPageTileChartConfig.edges.tile, model: gridPageTileConfig.model, children: [{ attr: UID }] },

  { attr: gridPageTileChartConfig.edges.groupBy, model: chartGroupByConfig.model, children: chartGroupByChildren },
  { attr: gridPageTileChartConfig.edges.aggregationConfig, model: aggregationConfigConfig.model, children: aggregationConfigChildren },
];
