<template>
  <grid-page-loading-error
    v-if="error !== null && !loading"
    @retry="init"
  />
  <div
    v-else-if="gridPageTileIframe === null || loading"
    class="_spinner"
  >
    <m-spinner />
  </div>
  <m-content
    v-else
    :padding-top="0"
    :padding-x="9"
    :padding-bottom="9"
    class="grid-page-tile-iframe"
  >
    <m-dropdown
      v-model:value="showSettings"
      :title="$t('general.actions')"
      block
      :relocate-key="relocateKey"
      @hide="hide"
    >
      <div :class="['_trigger', gridPageTileIframe.url === '' ? '-empty' : '']">
        <template
          v-if="gridPageTileIframe.url === ''"
        >
          <div class="_icon">
            <m-icon type="embed" />
          </div>
          <div class="_text">
            {{ $t('gridPageTileIframe.empty') }}
          </div>
        </template>
      </div>
      <template #overlay>
        <m-card
          small-padding
          class="_card"
        >
          <m-form-item>
            <m-input
              v-model:value="url"
              :placeholder="$t('gridPageTileIframe.placeholder')"
              auto-focus
              :has-error="!validUrl"
              full-width
            />
            <div class="_hint">
              {{ $t('gridPageTileIframe.hint') }}
            </div>
          </m-form-item>
          <m-btn
            block
            color="primary"
            :disabled="!validUrl"
            @click="saveUrl"
          >
            {{ $t('gridPageTileIframe.embedLink') }}
          </m-btn>
        </m-card>
      </template>
    </m-dropdown>
    <div
      v-if="gridPageTileIframe.url !== ''"
      ref="container"
      class="_iframe-wrapper"
    >
      <iframe
        ref="iframe"
        :width="dimensions.width"
        :height="dimensions.height"
        :src="gridPageTileIframe.url"
        frameborder="0"
        style="border:0"
        allowfullscreen
        sandbox="allow-presentation allow-storage-access-by-user-activation allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      />
    </div>
  </m-content>
</template>

<script setup>
import GridPageLoadingError from '@/components/custom-grid/GridPageLoadingError.vue';
import useGridPage from '@/composables/grid-page/grid-page';
import useInlineEditing from '@/composables/inline-editing';
import { computed, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';
import { logCatch } from '@/lib/logger/logger';

const props = defineProps({
  gridPageTile: { type: Object, required: true },
  readOnly: { type: Boolean, default: false },
  showReplaceMenu: { type: Boolean, default: false },
});

const url = ref('');

const relocateKey = ref(0);
const showSettings = ref(false);

const validUrl = computed(() => {
  if (url.value === '') {
    return true;
  }
  const regex = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,})/;
  return regex.test(url.value);
});

const showReplaceMenu = toRef(props, 'showReplaceMenu');

watch(url, (val) => {
  if (!val.startsWith('<iframe')) {
    return;
  }

  const tempElement = document.createElement('div');
  tempElement.innerHTML = val;

  const iframe = tempElement.querySelector('iframe');

  if (iframe !== null) {
    url.value = iframe.getAttribute('src');
  }
});

const emit = defineEmits(['close-replace-menu']);

const inlineEditingSvc = useInlineEditing();
const autoOpen = inlineEditingSvc.isInlineEditing(props.gridPageTile.uid);
watch(autoOpen, (val) => {
  if (val) {
    showSettings.value = true;
    setTimeout(() => {
      relocateKey.value += 1;
    }, 0);
  }
}, { immediate: true });

watch(showReplaceMenu, (val) => {
  if (val) {
    showSettings.value = true;
  }
});

const hide = () => {
  inlineEditingSvc.reset();
  emit('close-replace-menu');
};

const saveUrl = () => {
  gridPageSvc.updateGridPageTileIframe({ uid: gridPageTileIframe.value.uid, url: url.value }).then(() => {
    showSettings.value = false;
  });
};

const gridPageSvc = useGridPage();

const gridPageTileIframe = computed(() => {
  const res = gridPageSvc.gridPageTileIframe.value.find((e) => e.tile.uid === props.gridPageTile.uid);
  if (res === undefined) {
    return null;
  }

  return res;
});

const dimensions = ref({});
const container = ref(null);

const setDimensions = () => {
  if (container.value === null) {
    return;
  }
  dimensions.value = container.value.getBoundingClientRect();
};

const resizeObserver = new ResizeObserver(setDimensions);

onMounted(() => {
  setDimensions();
});

watch(container, () => {
  if (container.value === null) {
    return;
  }
  resizeObserver.disconnect();
  resizeObserver.observe(container.value);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
  inlineEditingSvc.reset();
});

const error = ref(null);
const loading = ref(false);
const init = () => {
  loading.value = true;
  error.value = null;
  gridPageSvc.queryGridPageTileIframeByTile(props.gridPageTile)
    .then(() => {
      url.value = gridPageTileIframe.value.url;
    })
    .catch(logCatch((e) => {
      error.value = e;
    }))
    .finally(() => {
      loading.value = false;
    });
};

init();

</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
._spinner {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-page-tile-iframe {
  overflow: hidden;
  width: 100%;
  height: 100%;

  ._iframe-wrapper {
    width: 100%;
    height: 100%;
  }

  ._trigger {
    width: 100%;

    &.-empty {
      border-radius: $btn-border-radius;
      background: map_get($grey, 'lighten-4');
      padding: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;

      &:hover {
        background: map_get($grey, 'lighten-3');
      }

      ._icon {
        margin-right: .6rem;
      }
    }
  }
}

._card {
  min-width: 30rem;

  ._hint {
    margin-top: .6rem;
    color: $font-color-secondary;
    font-size: $font-size-2;
  }
}
</style>
