<template>
  <div :class="['m-btn-group', `-${direction}`]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MBtnGroup',
  props: {
    direction: {
      type: String,
      default: 'horizontal',
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .m-btn-group {
    display: flex;
    background-color: white;
    border-radius: $btn-border-radius;

    .m-btn {
      border-radius: 0;
    }

    &.-vertical {
      flex-direction: column;

      >* {
        .m-btn, &.m-btn {
          border-top-width: .4px;
          border-bottom-width: .4px;
        }

        &:first-child {
          .m-btn, &.m-btn {
            border-top-width: 1px;
            border-top-left-radius: $btn-border-radius;
            border-top-right-radius: $btn-border-radius;
          }
        }

        &:last-child {
          .m-btn, &.m-btn {
            border-bottom-width: 1px;
            border-bottom-right-radius: $btn-border-radius;
            border-bottom-left-radius: $btn-border-radius;
          }
        }
      }
    }

    /* stylelint-disable no-descending-specificity */
    &.-horizontal {
      >* {
        .m-btn, &.m-btn {
          border-right-width: .4px;
          border-left-width: .4px;
        }

        &:first-child {
          .m-btn, &.m-btn {
            border-left-width: 1px;
            border-top-left-radius: $btn-border-radius;
            border-bottom-left-radius: $btn-border-radius;
          }
        }

        &:last-child {
          .m-btn, &.m-btn {
            border-right-width: 1px;
            border-top-right-radius: $btn-border-radius;
            border-bottom-right-radius: $btn-border-radius;
          }
        }
      }
    }
    /* stylelint-enable no-descending-specificity */
  }
</style>
