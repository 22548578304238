import useRepo from '@/nebula/repo';
import {
  gridPageTileChart as gridPageTileChartConfig,
} from 'shared/api/query/configs.json';

export default function useGridPageTileChart() {
  const repo = useRepo(gridPageTileChartConfig.model);

  return { ...repo };
}
