<template>
  <m-dropdown
    id="goal-cycle-selector"
    ref="dropdown"
    v-model:value="open"
    :class="['goal-cycle-selector', $store.state.breakpoint.smAndDown ? '-mobile' : '', small ? '-small' : '']"
    :title="$t('goalCycleSelector.title')"
    :placement="placement"
    match-trigger-width
    :block="fullWidth"
    :disabled="readOnly || disabled"
  >
    <m-focusable
      class="_input"
      :placeholder="placeholder"
      :show-placeholder="value.length === 0 && !hidePlaceholder"
      :full-width="fullWidth"
      :hide-border="hideBorder"
      :placeholder-icon="placeholderIcon"
      :hide-hover="hideHover"
      :read-only="readOnly"
      :disabled="disabled"
      :small="small"
      :m-style="mStyle"
      has-tags
      type="clickable"
    >
      <m-tag-list
        :wrap="wrap"
        :small="small"
      >
        <m-tag
          v-for="item in value"
          :key="item.uid"
          class="_tag"
          :title="item.title"
          :m-style="mStyle"
          :color="item.color"
          :small="!small"
          :xs="small"
        />
      </m-tag-list>
    </m-focusable>
    <template #overlay>
      <m-card
        no-padding
        class="_overlay"
        border-radius="small"
      >
        <m-focusable
          v-if="placement.includes('onTop')"
          :small="small"
          hide-hover
          has-tags
          hide-border
          full-width
          class="_input-overlay -on-top"
        >
          <m-tag-list
            wrap
            :small="small"
          >
            <m-tag
              v-for="item in value"
              :key="item.uid"
              class="_tag"
              :title="item.title"
              :color="item.color"
              small
              closeable
              @close="deselect(item)"
            />
            <div class="_search">
              <m-text-field
                ref="search"
                v-model:value="search"
                :placeholder="value.length === 0 ? $t('goalCycleSelector.selectPlaceholder') : null"
                inherit-styling
                auto-focus
                full-width
                @keydown="handleSearchKeyDown"
                @blur="focusDropdown"
              />
            </div>
          </m-tag-list>
        </m-focusable>
        <div
          v-else
          class="_search"
        >
          <m-content
            :padding-x="7"
            :padding-y="5"
          >
            <m-text-field
              ref="search"
              v-model:value="search"
              :placeholder="value.length === 0 ? $t('goalCycleSelector.selectPlaceholder') : null"
              auto-focus
              full-width
              has-background
              @keydown="handleSearchKeyDown"
              @blur="focusDropdown"
            />
          </m-content>
        </div>
        <goal-cycle-list
          :cycle-groups="timeGroupedGoalCycles"
          :selected="value"
          :selectable="!readOnly"
          :expand-all="true"
          :multiple="multiple"
          @select="select"
        />
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import GoalCycleList from '@/components/goal/cycle/GoalCycleList.vue';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import { DateTime } from 'luxon';
import { equalP } from 'shared/lib/array/array';
import { mStyleProps } from 'shared/lib/m-style-props';
import { shallowCopy } from 'shared/lib/copy';
import { sortDatetimeInterval } from 'shared/lib/sort';

export default {
  name: 'GoalCycleSelector',
  props: {
    ...mStyleProps,
    value: {
      type: Array,
      default: () => [],
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideHover: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    placeholderIcon: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    keepOpenOnClick: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottomCenter',
    },
  },
  setup() {
    const { goalCycles } = useGoalCycle();
    return { goalCycles };
  },
  emits: ['input', 'update:value', 'close'],
  components: { GoalCycleList },
  data() {
    return {
      open: false,
      search: '',
    };
  },
  computed: {
    filteredGoalCycles() {
      return this.goalCycles
        .filter((c) => this.search === '' || c.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
    },
    filteredAndSortedGoalCycles() {
      return shallowCopy(this.filteredGoalCycles)
        .sort(sortDatetimeInterval('start', 'end'));
    },
    timeGroupedGoalCycles() {
      return this.goalCyclesGroupByTime(this.filteredAndSortedGoalCycles);
    },
  },
  methods: {
    goalCyclesTreeReducer(acc, c) {
      const cycle = { ...c, editable: true, children: [] };
      if (acc.length === 0) {
        acc.push(cycle);
        return acc;
      }

      // Find out if an existing element is a child of c
      cycle.children = acc.filter((el) => (DateTime.fromISO(c.start).diff(DateTime.fromISO(el.start)) <= 0
          && DateTime.fromISO(c.end).diff(DateTime.fromISO(el.end)) >= 0));
      acc = acc.filter((el) => !(DateTime.fromISO(c.start).diff(DateTime.fromISO(el.start)) <= 0
          && DateTime.fromISO(c.end).diff(DateTime.fromISO(el.end)) >= 0));

      // Find out that c is a child of an existing element
      for (let i = 0; i < acc.length; i++) {
        if (DateTime.fromISO(acc[i].start).diff(DateTime.fromISO(c.start)) <= 0
            && DateTime.fromISO(acc[i].end).diff(DateTime.fromISO(c.end)) >= 0) {
          acc[i].children = this.goalCyclesTreeReducer(acc[i].children, cycle);
          return acc;
        }
      }

      acc.push(cycle);
      return acc;
    },
    goalCyclesGroupByTime(goalCycles) {
      const now = DateTime.local();

      const timeMap = goalCycles.reduce((acc, c) => {
        const start = DateTime.fromISO(c.start);
        const end = DateTime.fromISO(c.end);

        if (now.diff(start) < 0) {
          acc.upcoming.push(c);
          return acc;
        }

        if (now.diff(end) > 0) {
          acc.past.push(c);
          return acc;
        }

        acc.current.push(c);
        return acc;
      }, {
        current: [],
        past: [],
        upcoming: [],
      });
      return ['current', 'upcoming', 'past'].reduce((acc, timeKey) => {
        if (timeMap[timeKey].length > 0) {
          acc.push({
            title: this.$t(`goalCycleSelector.${timeKey}`),
            children: timeMap[timeKey].reduce(this.goalCyclesTreeReducer, []),
          });
        }
        return acc;
      }, []);
    },
    select(items) {
      this.search = '';
      this.$refs.search.focus();
      this.$emit('input', items);
      this.$emit('update:value', items);

      if (this.keepOpenOnClick) {
        return;
      }

      this.open = false;
    },
    deselect(item) {
      this.$refs.search.focus();
      const ids = this.value.map((i) => i.uid);
      if (ids.includes(item.uid)) {
        this.$emit('input', this.value.filter((i) => i.uid !== item.uid));
        this.$emit('update:value', this.value.filter((i) => i.uid !== item.uid));
      }
    },
    handleSearchKeyDown(event) {
      if (event.key === 'Backspace' && this.search === '' && this.value.length > 0) {
        this.deselect(this.value[this.value.length - 1]);
      }
    },
    focusDropdown() {
      if (typeof this.$refs.dropdown === 'undefined') {
        return;
      }

      this.$refs.dropdown.focus();
    },
    show() {
      this.open = true;
    },
  },
  watch: {
    open(val, oldVal) {
      if (oldVal === true && val === false) {
        this.$emit('close');
        this.$nextTick(() => {
          const v = shallowCopy(this.value).sort(sortDatetimeInterval('start', 'end'));
          if (!equalP({ a: v, b: this.value, order: true })) {
            this.$emit('input', v);
            this.$emit('update:value', v);
          }
        });
      }
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.show();
    }
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goal-cycle-selector {
    ._input {
      display: flex;
      cursor: pointer;

      ._tag {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  ._overlay {
    min-width: 20rem;

    ._input-overlay {
      background-color: map_get($grey, 'lighten-5');
      border-bottom: 1px solid $border-color;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      ._search {
        flex: 1 1 10rem;
        margin-left: .4rem;
      }

      &.-on-top {
        ._search {
          margin-bottom: .6rem;
        }
      }
    }
  }
</style>
