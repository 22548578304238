<template>
  <m-item-wrapper>
    <div class="selector-item">
      <div
        :class="classes"
        :style="contentStyle"
        @click="select(item)"
      >
        <m-tag
          :key="item.uid"
          :color="item.color"
          :title="item.title"
          :title-style="{ 'max-width': '15rem', 'font-weight': 'normal' }"
          :no-color="item.uid === UNASSIGNED"
          small
          class="_m-tag"
        />
        <div class="_checkmark">
          <m-icon
            v-if="isSelected"
            type="check"
          />
        </div>
      </div>
      <div class="_children">
        <selector-item
          v-for="c in item.children"
          :key="c.uid"
          :item="c"
          :level="level + 1"
          :selected="selected"
          :selectable="selectable"
          @select="$emit('select', $event)"
        />
      </div>
    </div>
  </m-item-wrapper>
</template>

<script>
import { UNASSIGNED } from '@/lib/constants';

export default {
  name: 'SelectorItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  emits: ['select'],
  data() {
    return {
      visible: false,
      UNASSIGNED,
    };
  },
  computed: {
    classes() {
      return [
        '_content',
        this.selectable ? '-selectable' : '',
      ];
    },
    isSelected() {
      return this.selected.map((c) => c.uid).filter((s) => s === this.item.uid).length > 0;
    },
    contentStyle() {
      return { paddingLeft: `${this.level * 1.5 + 3}rem` };
    },
  },
  methods: {
    select(item) {
      if (!this.selectable) {
        return;
      }
      this.$emit('select', item);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .selector-item {
    ._content {
      position: relative;
      display: flex;
      align-items: center;
      height: 3rem;
      padding: 0 1rem;
      cursor: pointer;
      border-radius: $btn-border-radius;

      ._m-tag {
        margin: .2rem .4rem;
      }

      ._checkmark {
        display: flex;
        justify-content: flex-end;
        width: 4rem;
        margin-left: auto;
      }

      &.-selectable:hover {
        background-color: $hover-color;
      }
    }
  }
</style>
