<template>
  <m-link
    :class="['card-list-item', hideFooter ? '-hide-footer' : '']"
    :to="to"
  >
    <div class="_body">
      <h5 class="_title">
        {{ icon }} {{ title }}
      </h5>
      <p
        v-if="subTitle !== ''"
        class="_sub-title"
      >
        {{ subTitle }}
      </p>
    </div>
    <template v-if="!hideFooter">
      <m-divider none />
      <div class="_footer">
        <m-tag
          v-if="tagTitle !== ''"
          :type="tagType"
          :title="tagTitle"
        />
        <m-spacer />
        <m-dropdown
          v-model:value="showMenu"
          placement="bottomRight"
          :title="$t('general.actions')"
        >
          <m-btn
            icon="ellipsis"
            hide-border
            small
            fab
            class="_link"
            @click="handleClick"
          />
          <template #overlay>
            <m-card
              list
              no-padding
            >
              <template
                v-for="(item, i) in menuItems"
                :key="item.name"
              >
                <m-divider
                  v-if="item.hasDivider"
                  :key="i"
                  xxs
                />
                <m-card-item
                  :style="item.style"
                  @click="handleItemClick(item)"
                >
                  {{ item.name }}
                </m-card-item>
              </template>
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </template>
  </m-link>
</template>

<script>

export default {
  name: 'CardListItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    to: {
      type: [Object, String],
      default: '',
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    tagTitle: {
      type: String,
      default: '',
    },
    tagType: {
      type: String,
      default: '',
    },
  },
  data() {
    return { val: true, showMenu: false };
  },
  methods: {
    handleClick(event) {
      event.stopPropagation();
      event.preventDefault();
      this.showMenu = true;
    },
    handleItemClick(item) {
      if (typeof item.to !== 'undefined') {
        this.$router.push(item.to);
        return;
      }
      if (typeof item.emit !== 'undefined' && item.emit !== null) {
        this.$emit(item.emit);
      }
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";
  @import "shared/assets/scss/padding";

  .card-list-item {
    background-color: white;
    border-radius: $btn-border-radius;

    ._body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 16rem;
      padding: $small-container-padding-y $small-container-padding-x;
      text-decoration: none;
      cursor: pointer;

      @include padding('small');

      ._title {
        hyphens: auto;
        text-align: center;
        word-break: break-word;
      }

      ._sub-title {
        color: $font-color-secondary;
        text-align: center;
        word-break: break-word;
      }
    }

    &.-hide-footer {
      ._body {
        height: 100%;
      }
    }

    ._footer {
      display: flex;
      padding: $small-container-padding-y $small-container-padding-x;

      ._link {
        color: $font-color-secondary;
        border-radius: $input-field-border-radius;

        &:hover {
          background-color: $hover-color;
        }
      }
    }

    @include box_shadow(1);

    &:hover {
      @include box_shadow(2);
    }
  }
</style>
