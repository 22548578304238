<template>
  <m-content
    class="update-template"
  >
    <editable-page-header
      :title="title"
      :icon-types="iconTypes"
      :icon="icon"
      :show-icon="!isEmptyIcon(icon) || emojiAdded"
      :placeholder="$t('list.noTitle')"
      show-icon-on-top
      class="_header"
      :disabled="!canEdit"
      @change-title="changeTitle"
      @change-icon="changeIcon"
    >
      <template #actions>
        <m-btn
          v-if="isEmptyIcon(icon) && !emojiAdded && canEdit"
          hide-border
          class="_item"
          icon="smile"
          light
          small
          @click="addEmoji"
        >
          {{ $t('page.addEmoji') }}
        </m-btn>
      </template>
    </editable-page-header>
    <div class="_properties">
      <property-values
        :properties="updateProperties"
        :value="propertyValues"
        :disabled="!canEdit"
        @input="updateProps"
      />
    </div>
    <m-divider />
    <div class="_template">
      <m-editor
        :initial-value="template"
        :placeholder="$t('updateTemplate.placeholder')"
        :allowed-content="allowedContent"
        :disabled="!canEdit"
        :default-font-size="$fontSizes[5]"
        :ctrl-enter-handlers="[onCmdOrModEnter]"
        :mod-enter-handlers="[onCmdOrModEnter]"
        @input="changeTemplate"
      />
    </div>
    <m-divider />
    <div class="_prefill-settings">
      <div class="_switch">
        <m-switch
          :value="prefillGoalActivities"
          :disabled="!canEdit"
          class="_toggle"
          @change="changePrefillGoalActivities"
        />
        {{ $t('updateTemplate.prefillGoalActivities') }}
        <m-tooltip>
          <m-icon
            type="question-circle"
            class="_info-circle"
          />
          <template #title>
            {{ $t('updateTemplate.prefillGoalActivitiesHint') }}
          </template>
        </m-tooltip>
      </div>
      <m-content
        v-if="prefillGoalActivities"
        padding
        class="_filter"
      >
        <div class="_row">
          <div class="_condition">
            {{ $t('updateTemplate.where') }}
          </div>
          <div class="_input">
            <m-focusable
              hide-hover
              :disabled="!canEdit"
            >
              {{ $t('updateTemplate.recipient') }}
            </m-focusable>
          </div>
          <div class="_input">
            <m-focusable
              hide-hover
              :disabled="!canEdit"
            >
              {{ $t('updateTemplate.is') }}
            </m-focusable>
          </div>
          <div class="_input">
            <m-select
              :disabled="!canEdit"
              :items="userPropertyItems"
              :value="userProperties"
              :read-only="userPropertyItems.length === 1"
              multiple
              return-object
              @update:value="changeUserProperties"
            />
          </div>
        </div>
        <div class="_row">
          <div class="_condition">
            {{ $t('updateTemplate.and') }}
          </div>
          <div class="_input">
            <m-focusable
              hide-hover
              :disabled="!canEdit"
            >
              {{ textByLang(goalTypeProperty.label, userLang) }}
            </m-focusable>
          </div>
          <div class="_input">
            <m-focusable
              hide-hover
              :disabled="!canEdit"
            >
              {{ $t('updateTemplate.is') }}
            </m-focusable>
          </div>
          <div class="_input">
            <m-select
              :items="goalTypesItems"
              :value="goalTypes"
              multiple
              return-object
              :disabled="!canEdit"
              tags
              @update:value="changeGoalTypes"
            />
          </div>
        </div>
      </m-content>
    </div>
  </m-content>
</template>

<script>
import EditablePageHeader from '@/components/page/EditablePageHeader.vue';
import MEditor from '@/components/editor/MEditor.vue';
import PropertyValues from '@/components/PropertyValues.vue';
import useAccess from '@/composables/access/access';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useProperties from '@/composables/property/property';
import { buildIconFromEntity, isEmptyIcon, replaceIconOnEntity } from 'shared/lib/icon';
import { computed } from 'vue';
import { editorNodeType, featureFlag, iconType, propertyType } from 'shared/constants.json';
import { getColoredIcon } from 'shared/lib/color-map';
import { keyResultOption } from '@/lib/goal/goal-type';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'UpdateTemplate',
  props: {
    updateTemplate: {
      type: Object,
      default: () => null,
    },
    onUpdate: {
      type: Function,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    const { updateProperties, goalProperties } = useProperties();
    const { goalTypeProperty } = useGoalTypeProperty();
    const { userLang } = useLoggedInUser();

    const userPropertyItems = computed(() => goalProperties.value
      .filter((p) => p.type === propertyType.user)
      .map(((p) => ({ icon: 'user', value: p.uid, text: textByLang(p.label, userLang.value) }))));

    const goalTypesItems = computed(() => goalTypeProperty.value.options
      .map(((o) => ({ icon: getColoredIcon(o), color: o.color, value: o.uid, text: textByLang(o.label, userLang.value) }))));

    return {
      accountHasFeature,
      allowedContent,
      updateProperties,
      userPropertyItems,
      textByLang,
      goalTypesItems,
      userLang,
      goalTypeProperty,
    };
  },
  components: { MEditor, EditablePageHeader, PropertyValues },
  emits: ['submit'],
  data() {
    return {
      template: this.updateTemplate.template,
      title: this.updateTemplate.title,
      propertyValues: this.updateTemplate.propertyValues,
      prefillGoalActivities: this.updateTemplate.prefillGoalActivities,
      emojiAdded: false,
      iconTypes: [iconType.emoji, iconType.icon, iconType.custom],
    };
  },
  computed: {
    icon() {
      return buildIconFromEntity(this.updateTemplate);
    },
    initialGoalTypes() {
      if (this.goalTypes.length === 0) {
        const krOption = keyResultOption([this.goalTypeProperty]);
        if (krOption === null) {
          return [this.goalTypesItems[0]];
        }
        return this.goalTypesItems.filter((i) => i.value === krOption.uid);
      }
      return this.goalTypes;
    },
    initialUserProperties() {
      if (this.userProperties.length === 0) {
        return [this.userPropertyItems[0]];
      }
      return this.userProperties;
    },
    goalTypes() {
      const ids = this.updateTemplate.goalTypes.map((t) => t.uid);
      return this.goalTypesItems.filter((i) => ids.includes(i.value));
    },
    userProperties() {
      const ids = this.updateTemplate.userProperties.map((t) => t.uid);
      return this.userPropertyItems.filter((i) => ids.includes(i.value));
    },
  },
  methods: {
    isEmptyIcon,
    addEmoji() {
      this.emojiAdded = true;
    },
    updateProps(value) {
      this.propertyValues = value;
      this.onUpdate({ propertyValues: this.propertyValues });
    },
    changeTitle(value) {
      this.title = value;
      this.onUpdate({ title: this.title }, true);
    },
    changeIcon(icon) {
      const entity = replaceIconOnEntity({ ...this.updateTemplate }, icon);
      if (isEmptyIcon(icon)) {
        this.emojiAdded = false;
      }
      this.onUpdate({ icon: entity.icon, image: entity.image });
    },
    changeTemplate(value) {
      this.template = value;
      this.onUpdate({ template: this.template }, true);
    },
    changePrefillGoalActivities(value) {
      this.prefillGoalActivities = value;
      if (value === true) {
        this.onUpdate({
          prefillGoalActivities: this.prefillGoalActivities,
          userProperties: this.initialUserProperties.map((u) => ({ uid: u.value })),
          goalTypes: this.initialGoalTypes.map((u) => ({ uid: u.value })),
        });
        return;
      }
      this.onUpdate({ prefillGoalActivities: this.prefillGoalActivities });
    },
    changeUserProperties(value) {
      this.onUpdate({ userProperties: value.map((v) => ({ uid: v.value })) });
    },
    changeGoalTypes(value) {
      this.onUpdate({ goalTypes: value.map((v) => ({ uid: v.value })) });
    },
    onCmdOrModEnter() {
      this.$emit('submit');
      return true;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .update-template {
    ._properties {
      margin-top: 2rem;
    }

    ._template {
      margin-top: 2rem;
      margin-left: .6rem;
    }

    ._prefill-settings {
      margin-bottom: 10rem;

      ._switch {
        display: flex;
        align-items: center;
        color: $font-color-secondary;

        ._toggle {
          width: auto;
          margin-right: 1rem;
        }
      }

      ._info-circle {
        margin-left: .6rem;
      }

      ._filter {
        margin-top: 2rem;
        border-radius: $btn-border-radius;
        border: 1px solid $input-border-color;

        ._row {
          display: flex;
          align-items: center;

          &:not(:last-of-type) {
            margin-bottom: 1rem;
          }

          ._condition {
            width: 5rem;
            text-align: right;
            font-weight: $font-weight-medium;
          }

          ._input {
            margin-left: .6rem;
          }
        }
      }
    }
  }
</style>
