import { UID } from 'shared/api/query/constants';
import {
  chartGroupBy as chartGroupByConfig,
  customBucket as customBucketConfig,
  property as propertyConfig,
} from 'shared/api/query/configs.json';
import { customBucketChildren } from '@/api/query/nebula/custom-bucket';

export const chartGroupByChildren = [
  { attr: UID },
  { attr: chartGroupByConfig.edges.groupBy },
  { attr: chartGroupByConfig.edges.isProperty },
  { attr: chartGroupByConfig.edges.edge },
  { attr: chartGroupByConfig.edges.edgeType },
  { attr: chartGroupByConfig.edges.property, model: propertyConfig.model, children: [{ attr: UID }] },
  { attr: chartGroupByConfig.edges.customBucket, model: customBucketConfig.model, children: customBucketChildren },
];
