import { DEFAULT_ATTRIBUTES, UID } from 'shared/api/query/constants';
import { gridPage as gridPageConfig, printable as printableConfig, storageObject as storageObjectConfig } from 'shared/api/query/configs.json';
import { storageObjectEdges } from '@/api/query/nebula/storage-object';

export const printableChildren = [
  ...DEFAULT_ATTRIBUTES,
  { attr: printableConfig.edges.width },
  { attr: printableConfig.edges.status },
  { attr: printableConfig.edges.localStorage },
  { attr: printableConfig.edges.page },
  {
    attr: printableConfig.edges.storageObject,
    model: storageObjectConfig.model,
    children: storageObjectEdges,
  },
  {
    attr: printableConfig.edges.gridPage,
    model: gridPageConfig.model,
    children: [{ attr: UID }],
  },
];
