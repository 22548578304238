<template>
  <m-dropdown
    class="props-filter"
    :title="$t('propsFilter.title')"
    :block="inCardMenu"
    @input="changeVisibility"
  >
    <component
      :is="inCardMenu ? 'm-card-item' : 'm-btn'"
      class="_btn"
      hide-border
      small
      :light="!inCardMenu"
      :icon="inCardMenu ? 'unordered-list' : ''"
    >
      {{ $t('propsFilter.button') }}
    </component>
    <template #overlay>
      <m-card
        class="_props-filter-overlay"
        list
        no-padding
      >
        <slot name="header" />
        <m-tooltip
          placement="left"
          :disabled="!disabled"
          :mouse-enter-delay=".5"
        >
          <div
            v-for="prop in notSortableProps"
            :key="prop.key"
            class="_prop-item"
          >
            <m-card-item
              :clickable="!propIsDisabled(prop)"
              :no-hover="propIsDisabled(prop)"
              :icon="iconByType({ type: prop.property.type, edgeName: prop.property.edgeName })"
              @click="toggleProp(prop)"
            >
              <m-switch
                :key="prop.key"
                :value="prop.show"
                :label="prop.label"
                :disabled="propIsDisabled(prop)"
                small
                class="_item"
              />
            </m-card-item>
          </div>
          <m-draggable
            draggable-item-class="m-card-item"
            ghost-item-class="m-card-item"
            dragover-item-class="m-card-item"
            scroll-container-class="_props-filter-overlay"
            can-drag-over-top
            can-drag-over-bottom
            :recreate-key="sortableProps.map((p) => p.key)"
            :disabled="disabled"
            @set-drag-item="setDragItem"
            @over-top="setOverTop"
            @over-bottom="setOverBottom"
            @drag-drop="handleDrop"
            @cancel="cancelDragging"
          >
            <div
              v-for="prop in sortableProps"
              :key="prop.key"
              class="_prop-item"
            >
              <m-dragzone
                v-if="draggingOverTop.includes(prop.key)"
                class="_dragzone-top"
              />
              <m-card-item
                :data-id="prop.key"
                :clickable="!propIsDisabled(prop)"
                :no-hover="propIsDisabled(prop)"
                :icon="iconByType({ type: prop.property.type, edgeName: prop.property.edgeName })"
                @click="toggleProp(prop)"
              >
                <template
                  v-if="!disabled"
                  #left
                >
                  <m-icon
                    class="_grab-icon"
                    type="drag"
                    :color="$colors.grey.base"
                  />
                </template>
                <m-switch
                  :key="prop.key"
                  :value="prop.show"
                  :label="prop.label"
                  :disabled="propIsDisabled(prop)"
                  small
                  class="_item"
                />
              </m-card-item>
              <m-dragzone
                v-if="draggingOverBottom.includes(prop.key)"
                class="_dragzone-bottom"
              />
            </div>
          </m-draggable>
          <template #title>
            {{ $t('savedViewInfo.disabledInfo') }}
          </template>
        </m-tooltip>
      </m-card>
    </template>
  </m-dropdown>
</template>

<script>
import MDragzone from 'shared/components/base/MDragzone.vue';
import useSort from '@/composables/draggable/sort';
import useViewParamsPropsOperationValidator from '@/composables/view-params/drag-validator';
import { computed } from 'vue';
import { copy } from 'shared/lib/copy';
import { equalP } from 'shared/lib/array/array';
import { iconByType } from '@/lib/property';

export default {
  name: 'PropsFilter',
  props: {
    value: {
      type: Array,
      required: true,
    },
    inCardMenu: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    propOrder: {
      type: Array,
      default: null,
    },
  },
  components: { MDragzone },
  emits: ['input', 'update:value', 'update:show', 'change-visibility', 'update:prop-order'],
  setup(props) {
    const visibleProps = computed(() => props.value.filter((p) => !p.hideInProps));
    const actionValidator = useViewParamsPropsOperationValidator(visibleProps);
    const getOperationBasedOnPosition = (dragItemIndex, anchorIndex, position) => {
      const anchorId = anchorIndex;
      let toSortIds = [];

      switch (position) {
        case 'top':
        case 'bottom':
          toSortIds = dragItemIndex;
          break;
        default:
          throw new Error('position unknown. Must be in top, right, left or bottom.');
      }

      const isSort = ['top', 'bottom'].includes(position);
      const sortAfter = position !== 'top';

      return {
        isRealign: false,
        isSort,
        anchorId,
        toSortIds,
        sortAfter,
      };
    };
    const validateDragAction = (keys, position) => {
      const filtered = keys.filter((k) => k !== dragItemId.value);
      if (filtered.length === 0) {
        return [];
      }

      const { isValid } = actionValidator.isValidDrag(getOperationBasedOnPosition([dragItemId.value], keys[0], position));
      if (!isValid) {
        return [];
      }
      return keys;
    };
    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      dropItem,
      draggingOverTop,
      draggingOverBottom,
      cancelDragging,
      dragItemId,
    } = useSort('key', validateDragAction);
    return {
      visibleProps,
      setDragItem,
      draggingOverTop,
      draggingOverBottom,
      setOverBottom,
      setOverTop,
      dropItem,
      cancelDragging,
      dragItemId,
    };
  },
  data() {
    return { iconByType };
  },
  computed: {
    sortableProps() {
      return this.visibleProps.filter((p) => p.property.noSortInProps !== true);
    },
    notSortableProps() {
      return this.visibleProps.filter((p) => p.property.noSortInProps === true);
    },
    disabledProps() {
      return this.visibleProps.filter((p) => p.property.disableInProps === true);
    },
  },
  methods: {
    handleDrop() {
      const oldPropOrder = this.value.map((v) => ({ key: v.key }));
      const newPropOrder = this.dropItem(oldPropOrder);
      if (equalP({ a: newPropOrder, b: oldPropOrder, key: 'key', order: true })) {
        return;
      }
      this.$emit('update:prop-order', newPropOrder);
    },
    toggleProp(prop) {
      if (this.propIsDisabled(prop)) {
        return;
      }
      const { key } = prop;
      const cp = copy(this.value);
      let index = -1;
      for (let i = 0; i < cp.length; i++) {
        if (cp[i].key === key) {
          index = i;
          break;
        }
      }
      const newVal = !cp[index].show;
      cp[index].show = newVal;
      this.$emit('input', cp, key);
      this.$emit('update:value', cp, key);
      this.$emit('update:show', { property: cp[index].property, value: newVal });
    },
    changeVisibility(val) {
      this.$emit('change-visibility', val);
    },
    propIsDisabled(prop) {
      return this.disabled || this.disabledProps.map((p) => p.key).includes(prop.key);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  ._props-filter-overlay {
    min-width: 30rem;

    ._prop-item {
      position: relative;

      ._dragzone-top {
        top: -2px;
      }

      ._dragzone-bottom {
        bottom: -2px;
      }
    }

    ._grab-icon {
      margin-right: 0.5rem;
      cursor: grab;
    }

    ._item {
      margin: .8rem 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
</style>
