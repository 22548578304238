<!--
  MItemWrapper is used to wrap card items and add consistent padding around them.
  This ensures that hover states and backgrounds are contained within the padding
  rather than stretching edge-to-edge. Used by components like MCardItem, NavItem,
  and CollapsableNavItems to create consistent spacing and hover behavior.
-->
<template>
  <div class="m-item-wrapper">
    <slot />
  </div>
</template>

<script>
export default { name: 'MItemWrapper' };
</script>

<style scoped lang="scss" type="text/scss">
  .m-item-wrapper {
    width: 100%;
    padding: .1rem .4rem;
  }
</style>
