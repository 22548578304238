import useCustomGridRepo from '@/composables/grid-page/grid-page-repo';
import useGridPageConfig from '@/composables/grid-page/grid-page-config';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import { gridPageType } from 'shared/constants.json';
import { insertAfter } from 'shared/lib/array/array';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { useI18n } from 'vue-i18n';

export default function useGridPageDuplicate() {
  const gridPageRepo = useCustomGridRepo();
  const loggedInUserService = useLoggedInUser();
  const gridPageConfig = useGridPageConfig();
  const i18n = useI18n();

  const removeUID = (node) => {
    const newRef = { ...node };
    delete (newRef.uid);
    return newRef;
  };

  const duplicateUserScope = (userScope) => {
    const res = { ...removeUID(userScope) };

    if (userScope.timeRange !== null && userScope.timeRange !== undefined) {
      res.timeRange = removeUID(userScope.timeRange);
    }

    if (userScope.numberRange !== null && userScope.numberRange !== undefined) {
      res.numberRange = removeUID(userScope.numberRange);
    }

    return res;
  };

  const duplicateUserScopeTree = (userScopeTree) => {
    const res = { ...removeUID(userScopeTree) };

    if (userScopeTree.scope !== undefined && userScopeTree.scope !== null) {
      res.scope = duplicateUserScope(userScopeTree.scope);
    }

    if (Array.isArray(userScopeTree.children)) {
      res.children = userScopeTree.children.map((ust) => duplicateUserScopeTree(ust));
    }

    return res;
  };

  const duplicateAccessPolicy = (accessPolicy) => ({
    ...removeUID(accessPolicy),
    creator: { uid: loggedInUserService.loggedInUser.value.uid },
    scopes: accessPolicy.scopes.map((userScopeTree) => duplicateUserScopeTree(userScopeTree)),
  });

  const duplicateStorageObject = (storageObject) => {
    if (isNullOrUndefined(storageObject)) {
      return null;
    }
    return removeUID(storageObject);
  };

  const duplicateGridPage = (gridPage) => {
    const res = {
      ...removeUID(gridPage),
      rid: gridPage.uid,
      title: copyTitle(gridPage.title),
      creator: { uid: loggedInUserService.loggedInUser.value.uid },
      accessPolicy: duplicateAccessPolicy(gridPage.accessPolicy),
      image: duplicateStorageObject(gridPage.image),
    };

    if (Array.isArray(gridPage.rows)) {
      res.rows = gridPage.rows.map((r) => ({
        ...removeUID(r),
        rid: r.uid,
        gridPage: { rid: gridPage.uid },
        tileOrder: r.tileOrder.filter(({ uid }) => r.tiles.findIndex((t) => t.uid === uid) !== -1).map(({ uid }) => ({ rid: uid })),
        tiles: r.tiles.map((t) => {
          const res = {
            ...removeUID(t),
            rid: t.uid,
            gridPageRow: { rid: r.uid },
          };

          if (t.textTile !== null) {
            res.textTile = { ...removeUID(t.textTile), tile: { rid: t.uid } };
          }

          if (t.iframeTile !== null) {
            res.iframeTile = { ...removeUID(t.iframeTile), tile: { rid: t.uid } };
          }

          if (t.singleGoalTile !== null) {
            res.singleGoalTile = { ...removeUID(t.singleGoalTile), tile: { rid: t.uid } };
          }

          if (t.chartTile !== null) {
            res.chartTile = { ...removeUID(t.chartTile), tile: { rid: t.uid } };
          }

          res.savedViews = t.savedViews.map((sv) => ({ ...removeUID(sv), gridPageTile: { rid: t.uid }, creator: { uid: loggedInUserService.loggedInUser.value.uid } }));
          if (t.selectedView !== null) {
            res.selectedView = { ...removeUID(t.selectedView), gridPageTile: { rid: t.uid }, savedView: { rid: t.selectedView.view.uid } };
          }

          return res;
        }),
      }));
      res.rowOrder = gridPage.rowOrder.filter(({ uid }) => gridPage.rows.findIndex((r) => r.uid === uid) !== -1).map(({ uid }) => ({ rid: uid }));
    }

    return res;
  };

  const duplicateGridPages = (gridPages) => gridPageRepo.queryGridPagesDeep(gridPages).then((gridPages) => {
    const res = gridPages.map((gridPage) => duplicateGridPage(gridPage));
    return gridPageRepo.createGridPagesDeep({ entities: res });
  });

  const copyTileTitle = (tile) => {
    if ([gridPageType.text, gridPageType.singleGoal].includes(tile.type)) {
      return tile.title;
    }

    return copyTitle(tile.title);
  };

  const copyTitle = (title) => {
    if (title === '') {
      return `${i18n.t('general.untitled')} - ${i18n.t('general.copy')}`;
    }

    return `${title} - ${i18n.t('general.copy')}`;
  };

  const duplicateGridPageTile = (tile) => gridPageRepo.queryGridPageTileDeep(tile).then((tile) => {
    const tileRid = 1;
    const rowRid = 2;

    const columns = gridPageConfig.maxColumns / (tile.gridPageRow.tileOrder.length + 1);

    const res = {
      ...removeUID(tile),
      rid: tileRid,
      title: copyTileTitle(tile),
      columns,
    };
    let otherTiles = [];

    res.gridPageRow = {
      uid: tile.gridPageRow.uid,
      tileOrder: insertAfter([...tile.gridPageRow.tileOrder], tile.gridPageRow.tileOrder.find(({ uid }) => uid === tile.uid), { rid: tileRid }),
    };

    if (tile.gridPageRow.tileOrder.length === gridPageConfig.maxTiles) {
      res.gridPageRow = {
        rid: rowRid,
        height: gridPageConfig.defaultHeight,
        tileOrder: [{ rid: tileRid }],
        gridPage: {
          uid: tile.gridPageRow.gridPage.uid,
          rowOrder: insertAfter([...tile.gridPageRow.gridPage.rowOrder], tile.gridPageRow.gridPage.rowOrder.find(({ uid }) => uid === tile.gridPageRow.uid), { rid: rowRid }),
        },
      };
      res.columns = gridPageConfig.maxColumns;
    }

    if (tile.gridPageRow.tileOrder.length < gridPageConfig.maxTiles) {
      otherTiles = tile.gridPageRow.tileOrder.map(({ uid }) => ({ uid, columns }));
    }

    if (tile.singleGoalTile !== null) {
      res.singleGoalTile = {
        ...removeUID(tile.singleGoalTile),
        tile: { rid: tileRid },
      };
    }

    if (tile.textTile !== null) {
      res.textTile = {
        ...removeUID(tile.textTile),
        tile: { rid: tileRid },
      };
    }

    if (tile.iframeTile !== null) {
      res.iframeTile = {
        ...removeUID(tile.iframeTile),
        tile: { rid: tileRid },
      };
    }

    if (tile.chartTile !== null) {
      res.chartTile = {
        ...removeUID(tile.chartTile),
        tile: { rid: tileRid },
      };
    }

    res.savedViews = tile.savedViews.map((savedView) => ({
      ...removeUID(savedView),
      rid: savedView.uid,
      gridPageTile: { rid: tileRid },
      creator: { uid: loggedInUserService.loggedInUser.value.uid },
    }));

    if (tile.selectedView !== null) {
      res.selectedView = {
        ...removeUID(tile.selectedView),
        gridPageTile: { rid: tileRid },
        view: { rid: tile.selectedView.view.uid },
      };
    }

    return gridPageRepo.createGridPageTileDeep({ tile: res, otherTiles });
  });

  return { duplicateGridPages, duplicateGridPageTile };
}
