<template>
  <m-content
    padding
    class="_content"
  >
    <m-form-item
      v-if="!hideDashboardSelector"
      :label="$t('reportEditor.dashboard')"
    >
      <m-select
        :value="selectedDashboard"
        full-width
        :items="dashboards"
        match-trigger-width
        @update:value="selectDashboard"
      />
    </m-form-item>
    <m-form-item>
      <r-rule-picker
        v-model:value="schedule"
        full-width
        match-trigger-width
        hide-error
      />
    </m-form-item>
    <m-form-item :label="$t('reportEditor.recipients')">
      <template v-if="!isPrivateDashboard">
        <div
          class="_recipients-input"
        >
          <m-input
            v-model:value="email"
            full-width
            class="_input"
            :has-error="inputError !== ''"
            @focus="emailFocused = true"
            @blur="emailFocused = false"
            @enter="addRecipient"
          />
          <m-btn
            :disabled="recipients.length > maxRecipients"
            color="primary"
            @click="addRecipient"
          >
            {{ $t("reportEditor.addRecipient") }}
          </m-btn>
        </div>
        <div class="_error-message">
          <template v-if="inputError !== ''">
            {{ inputError }}
          </template>
        </div>
      </template>
      <m-alert
        v-if="isPrivateDashboard"
        type="info"
        hide-icon
      >
        {{ $t('reportEditor.privateDashboardRecipients') }}
      </m-alert>
      <div class="_recipients">
        <m-tag
          v-for="recipient in recipients"
          :key="recipient"
          :title="recipient"
          :closeable="!isPrivateDashboard"
          @close="removeRecipient(recipient)"
        />
      </div>
    </m-form-item>
    <m-form-item :label="$t('reportEditor.subject')">
      <m-input
        v-model:value="subject"
        full-width
        :has-error="subject === '' && selectedDashboard !== null"
      />
      <div class="_error-message">
        <template v-if="subject === '' && selectedDashboard !== null">
          {{ $t('validation.required') }}
        </template>
      </div>
    </m-form-item>
    <m-form-item
      :label="$t('reportEditor.message')"
      class="_message"
    >
      <m-textarea
        v-model:value="message"
        full-width
        :rows="3"
      />
    </m-form-item>
  </m-content>
  <m-content
    class="_footer"
    padding
  >
    <m-btn
      color="secondary"
      class="_cancel"
      @click="cancel"
    >
      {{ $t("general.cancel") }}
    </m-btn>
    <m-btn
      color="primary"
      :loading="
        reportService.updateLoading.value || reportService.createLoading.value
      "
      :disabled="
        recipients.length === 0 ||
          selectedDashboard === null ||
          selectedDashboard === undefined ||
          subject === ''
      "
      @click="save"
    >
      <template v-if="entity.uid === 0">
        {{ $t("general.create") }}
      </template>
      <template v-else>
        {{ $t("general.save") }}
      </template>
    </m-btn>
  </m-content>
</template>

<script setup>
import RRulePicker from '@/components/RRulePicker.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useGridPage from '@/composables/grid-page/grid-page';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useReport from '@/composables/report/report';
import useSnackBar from '@/composables/snackbar';
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';
import { emailRegex } from 'shared/lib/email-regex';
import { isEqual } from 'lodash';
import { printablePage, productPlan } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const reportService = useReport();
const snackbar = useSnackBar();

const props = defineProps({
  entity: {
    type: Object,
    required: true,
  },
  hideDashboardSelector: {
    type: Boolean,
    default: false,
  },
});

const { loggedInUser } = useLoggedInUser();

const emit = defineEmits(['cancel', 'close', 'dirty']);

const emailFocused = ref(false);

const gridPageService = useGridPage();
const dashboards = computed(() => {
  const items = [
    {
      text: t(`reportEditor.${printablePage.progressDashboard}`),
      value: printablePage.progressDashboard,
    },
    {
      text: t(`reportEditor.${printablePage.healthDashboard}`),
      value: printablePage.healthDashboard,
    },
  ];
  const list = [...gridPageService.gridPages.value];
  list.sort((a, b) => DateTime.fromISO(b.modifiedAt).diff(DateTime.fromISO(a.modifiedAt)).toMillis());
  const gridPages = [
    ...list.map((gridPage) => ({
      text: gridPage.title,
      value: gridPage.uid,
    })),
  ];
  items.push(...gridPages);
  return items;
});

const selectedDashboard = ref(null);

const selectDashboard = (val) => {
  selectedDashboard.value = val;
  emit('dirty');
  if ([printablePage.healthDashboard, printablePage.progressDashboard].includes(val)) {
    subject.value = t(`reportEditor.${val}`);
    return;
  }
  const dashboard = gridPageService.selectSingleGridPage(val);
  if (dashboard === undefined) {
    return;
  }
  subject.value = dashboard.title;
};

const schedule = ref(props.entity.schedule);
const email = ref('');
const recipientsInput = ref([...props.entity.recipients]);
const recipients = computed(() => {
  if (isPrivateDashboard.value) {
    return [loggedInUser.value.email];
  }
  return recipientsInput.value;
});

const isValidEmail = computed(() => {
  const rg = new RegExp(emailRegex());
  return rg.test(email.value);
});

const { accountSettings } = useAccountSettings();

const alreadyAdded = computed(() => recipients.value.includes(email.value));
const isTrial = computed(() => accountSettings.value.planId === productPlan.trial);

const isPrivateDashboard = computed(() => {
  const dashboard = gridPageService.selectSingleGridPage(selectedDashboard.value);
  // ignore health and progress dashboards
  if (dashboard === undefined) {
    return false;
  }
  return !dashboard.isPublic;
});

const maxRecipients = computed(() => {
  if (isTrial.value) {
    return 1;
  }

  return 50;
});

const errorMessage = computed(() => {
  if (!isValidEmail.value) {
    return t('reportEditor.invalidEmail');
  }
  if (alreadyAdded.value) {
    return t('reportEditor.alreadyAdded');
  }
  if (recipients.value.length >= maxRecipients.value) {
    if (isTrial.value) {
      return t('reportEditor.tooManyRecipientsTrial');
    }
    return t('reportEditor.tooManyRecipients');
  }
  return '';
});

const submitPressed = ref(false);
const inputError = computed(() => {
  if (!submitPressed.value && (email.value === '' || emailFocused.value)) {
    return '';
  }
  return errorMessage.value;
});

const addRecipient = () => {
  submitPressed.value = true;
  if (!isValidEmail.value || alreadyAdded.value || recipients.value.length >= maxRecipients.value) {
    return;
  }
  recipientsInput.value = [...recipientsInput.value, email.value];
  email.value = '';
};

watch(email, () => {
  submitPressed.value = false;
});

const subject = ref('');
const message = ref('');

const removeRecipient = (email) => {
  recipientsInput.value = recipientsInput.value.filter((r) => r !== email);
};

const cancel = () => {
  emit('cancel');
};

watch(schedule, (newVal) => {
  if (props.entity.schedule !== newVal) {
    emit('dirty');
  }
});

watch(recipientsInput, (newVal) => {
  if (!isEqual(props.entity.recipients, newVal)) {
    emit('dirty');
  }
});

watch(message, (newVal) => {
  if (props.entity.message !== newVal) {
    emit('dirty');
  }
});

watch(subject, (newVal) => {
  if (props.entity.subject !== newVal) {
    emit('dirty');
  }
  subject.value = newVal.trim();
});

watch(props.entity, (newVal) => {
  if (isEqual(props.entity, newVal)) {
    return;
  }
  setValues();
});

const getDashboard = (printable) => {
  if (printable === undefined) {
    return null;
  }
  if ([printablePage.progressDashboard, printablePage.healthDashboard].includes(printable.page)) {
    return printable.page;
  }
  return printable?.gridPage?.uid;
};

const setValues = () => {
  selectedDashboard.value = getDashboard(props.entity.printable);
  schedule.value = props.entity.schedule;
  recipientsInput.value = [...props.entity.recipients];
  if (props.entity.subject !== undefined) {
    subject.value = props.entity.subject;
  }
  if (props.entity.message !== undefined) {
    message.value = props.entity.message;
  }
};

const printablePageData = computed(() => {
  if ([printablePage.progressDashboard, printablePage.healthDashboard].includes(selectedDashboard.value)) {
    return { page: selectedDashboard.value };
  }

  return {
    page: printablePage.dashboard,
    gridPage: { uid: selectedDashboard.value },
  };
});

const save = () => {
  if (props.entity.uid === 0) {
    return reportService
      .createSingle({
        activatedAt: DateTime.local().toISO(),
        schedule: schedule.value,
        recipients: recipients.value,
        subject: subject.value,
        message: message.value,
        printable: {
          creator: { uid: loggedInUser.value.uid },
          localStorage: JSON.stringify(localStorage),
          width: 1200,
          ...printablePageData.value,
        },
      })
      .then(() => {
        emit('close', false);
        snackbar.success(t('success.created'));
      })
      .catch(() => {
        snackbar.error(t('error.default'));
      });
  }
  return reportService
    .updateSingle({
      ...props.entity,
      schedule: schedule.value,
      recipients: recipients.value,
      subject: subject.value,
      message: message.value,
      printable: {
        ...props.entity.printable,
        ...printablePageData.value,
      },
    })
    .then(() => {
      emit('close', false);
      snackbar.success(t('success.updated'));
    })
    .catch(() => {
      snackbar.error(t('error.default'));
    });
};

setValues();
</script>

<style scoped lang="scss">
._content {
  ._recipients-input {
    display: flex;
    align-items: center;
  }

  ._input {
    margin-right: 1rem;
  }

  ._recipients {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
  }

  ._error-message {
    height: 1.6rem;
    color: $error-color;
  }

  ._message {
    margin-bottom: 0;
  }
}

._footer {
  display: flex;
  justify-content: flex-end;

  ._cancel {
    margin-right: 1rem;
  }
}
</style>
