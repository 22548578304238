<template>
  <div class="reports-table">
    <m-table
      :columns="columns"
      :data-source="reports"
      :order="{ modifiedAt: 'down' }"
      class="_schedules-table"
      row-class-name="schedules-row"
      row-key="uid"
      rows-clickable
      hide-vertical-border
      :custom-row="handleRowClick"
      show-new-button
      @new="create"
    >
      <template #subject="{ subject, row }">
        <div class="_name">
          <div class="_text">
            <m-tooltip :disabled="!isSubjectTruncated[row.uid]">
              <item-title
                v-model:is-title-truncated="isSubjectTruncated[row.uid]"
                :title="subject"
                :style="{ maxWidth: '20rem' }"
              />
              <template #title>
                {{ subject }}
              </template>
            </m-tooltip>
          </div>
        </div>
      </template>
      <template #printable="{ printable, row }">
        <div class="_name">
          <div class="_text">
            <m-tooltip :disabled="!isTitleTruncated[row.uid]">
              <item-title
                v-model:is-title-truncated="isTitleTruncated[row.uid]"
                :title="getTitle(printable)"
                :icons="[{ value: buildIconFromEntity(printable.gridPage) }]"
                :style="{ maxWidth: '15rem' }"
              />
              <template #title>
                {{ getTitle(printable) }}
              </template>
            </m-tooltip>
          </div>
        </div>
      </template>
      <template #recipients="{ recipients, row }">
        <div class="_recipients">
          <m-dropdown
            :title="$t('schedulesTable.recipientsList')"
            :value="showRecipientList === row.uid"
            :disabled="recipients.length === 0"
            @hide="showRecipientList = null"
          >
            <m-btn
              class="_trigger"
              hide-border
              small
              :disabled="recipients.length === 0"
              @click.stop="showRecipientList = row.uid"
            >
              {{ recipients.length }}
            </m-btn>
            <template #overlay>
              <m-card
                list
                no-padding
                class="_overlay"
              >
                <m-card-item
                  v-for="recipient in recipients"
                  :key="recipient"
                >
                  {{ recipient }}
                </m-card-item>
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
      <template #schedule="{ schedule, row }">
        <div class="_schedule">
          <m-icon
            v-if="isPaused(row)"
            class="_icon"
            type="pause-circle"
            :color="$colors.grey.lighten1"
          />
          <m-tooltip>
            {{ getRRuleText(schedule, false) }}
            <template #title>
              {{ getRRuleText(schedule, true) }}
            </template>
          </m-tooltip>
        </div>
      </template>
      <template #modifiedAt="{ modifiedAt }">
        <div class="_modified-at">
          {{ formatDate(modifiedAt) }}
        </div>
      </template>
      <template #createdBy="{ createdBy }">
        <div class="_created-by">
          <user-display
            :user="createdBy"
          />
        </div>
      </template>
      <template #actions="{ row }">
        <div class="_actions">
          <m-dropdown
            close-on-click
            :title="$t('general.actions')"
            @click.stop
          >
            <m-btn
              icon="ellipsis"
              fab
              small
              hide-border
            />
            <template #overlay>
              <m-card
                list
                no-padding
              >
                <m-card-item
                  icon="edit"
                  @click="handleEdit(row)"
                >
                  {{ $t('general.edit') }}
                </m-card-item>
                <m-card-item
                  v-if="isPaused(row)"
                  icon="play-circle"
                  @click="activate(row)"
                >
                  {{ $t('reportsTable.activate') }}
                </m-card-item>
                <m-card-item
                  v-if="!isPaused(row)"
                  icon="pause-circle"
                  @click="pause(row)"
                >
                  {{ $t('reportsTable.pause') }}
                </m-card-item>
                <m-card-item
                  icon="bell"
                  @click="toggleSubscribe(row)"
                >
                  <m-switch
                    small
                    :value="row.recipients.includes(loggedInUser.email)"
                    :label="$t('reportsTable.subscribed')"
                  />
                </m-card-item>
                <m-card-item
                  icon="delete"
                  @click="deleteItem(row)"
                >
                  {{ $t('general.delete') }}
                </m-card-item>
              </m-card>
            </template>
          </m-dropdown>
        </div>
      </template>
    </m-table>
    <m-dialog
      :value="showEditor"
      :max-width="$modalSizes.lg"
      hide-footer
      no-padding
      :title="$t('reportsTable.scheduleReport')"
      @close="closeDetailPage"
    >
      <report-editor
        :entity="toEdit"
        @dirty="isDirty = true"
        @cancel="closeDetailPage"
        @close="closeDetailPage"
      />
    </m-dialog>
  </div>
</template>

<script setup>
import ItemTitle from '@/components/ItemTitle.vue';
import ReportEditor from '@/components/report/ReportEditor.vue';
import UserDisplay from 'shared/components/UserDisplay.vue';
import useConfirmDialog from '@/composables/confirm-dialog';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useRRuleTranslations from '@/composables/rrule-translations/translations';
import useReport from '@/composables/report/report';
import useSnackBar from '@/composables/snackbar';
import { DateTime } from 'luxon';
import { RRule, rrulestr } from 'rrule';
import { buildIconFromEntity } from 'shared/lib/icon';
import { computed, ref } from 'vue';
import { frontendRRuleToBackend } from '@/lib/rrule';
import { printablePage } from 'shared/constants.json';
import { useI18n } from 'vue-i18n';

const isSubjectTruncated = ref({});
const isTitleTruncated = ref({});

const props = defineProps({
  search: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();
const snackbar = useSnackBar();

const {
  entityList,
  deleteSingle,
  activate,
  pause,
  toggleSubscribe,
} = useReport();
const { loggedInUser } = useLoggedInUser();

const reports = computed(() => entityList.value.filter((r) => {
  if (props.search === '') {
    return true;
  }

  return r.printable.gridPage?.title.toLowerCase().includes(props.search.toLowerCase());
}));

const toEdit = ref({});
const showRecipientList = ref(null);
const showEditor = ref(false);

const columns = computed(() => [
  {
    key: 'subject',
    dataIndex: 'subject',
    title: t('reportsTable.subject'),
    width: '30%',
    scopedSlots: { customRender: 'subject' },
  },
  {
    key: 'printable',
    dataIndex: 'printable',
    title: t('reportsTable.printable'),
    width: '30%',
    scopedSlots: { customRender: 'printable' },
  },
  {
    key: 'recipients',
    dataIndex: 'recipients',
    title: t('reportsTable.recipients'),
    width: '10%',
    scopedSlots: { customRender: 'recipients' },
  },
  {
    key: 'schedule',
    dataIndex: 'schedule',
    title: t('reportsTable.schedule'),
    width: '25%',
    scopedSlots: { customRender: 'schedule' },
  },
  {
    key: 'modifiedAt',
    dataIndex: 'modifiedAt',
    title: t('reportsTable.lastUpdatedAt'),
    width: '25%',
    scopedSlots: { customRender: 'modifiedAt' },
  },
  {
    key: 'createdBy',
    dataIndex: 'creator',
    title: t('reportsTable.createdBy'),
    width: '25%',
    scopedSlots: { customRender: 'createdBy' },
  },
  {
    key: 'actions',
    dataIndex: 'actions',
    title: '',
    scopedSlots: { customRender: 'actions' },
  },
]);

const confirmDialog = useConfirmDialog();

const deleteItem = (entity) => {
  const deleteMethod = () => deleteSingle(entity.uid)
    .then(() => {
      snackbar.success(t('success.deleted'));
    })
    .catch(() => {
      snackbar.error(t('error.default'));
    });

  confirmDialog.confirm({
    title: t('reportsTable.deletePrompt'),
    okText: t('general.yesDelete'),
    okType: 'danger',
    maskClosable: true,
    cancelText: t('general.cancel'),
    onOk() {
      deleteMethod();
    },
  });
};

const getTitle = (printable) => {
  if ([printablePage.progressDashboard, printablePage.healthDashboard].includes(printable.page)) {
    return t(`reportEditor.${printable.page}`);
  }

  return printable.gridPage?.title;
};

const create = () => {
  const now = DateTime.local();
  const dtstart = DateTime.local(now.year, now.month, now.day, 9, 0, 0, 0);

  const rRule = new RRule({
    dtstart: dtstart.toJSDate(),
    tzid: DateTime.local().zoneName,
    freq: RRule.WEEKLY,
    byweekday: [now.weekday - 1],
    interval: 1,
    byminute: 0,
    bysecond: 0,
    byhour: dtstart.hour,
  });
  const rRuleStr = frontendRRuleToBackend(rRule);
  toEdit.value = {
    uid: 0,
    recipients: [loggedInUser.value.email],
    schedule: rRuleStr,
    subject: '',
    message: '',
  };
  showEditor.value = true;
};

defineExpose({ createReport: create });

const isDirty = ref(false);

const closeDetailPage = (showConfirm = true) => {
  if (!isDirty.value || !showConfirm) {
    showEditor.value = false;
    isDirty.value = false;
    return;
  }
  confirmDialog.confirm({
    title: t('general.discardEditPrompt'),
    okText: t('general.yesDiscard'),
    cancelText: t('general.cancel'),
    maskClosable: true,
    onOk() {
      showEditor.value = false;
      isDirty.value = false;
    },
  });
};

const handleRowClick = (entity) => ({
  on: {
    click: () => {
      handleEdit(entity);
    },
  },
});

const handleEdit = (entity) => {
  toEdit.value = entity;
  showEditor.value = true;
};

const { rruleToText } = useRRuleTranslations();
const getRRuleText = (s, includeTime) => {
  const rule = rrulestr(s);
  return `${rruleToText(rule, { includeTime })}`;
};

const formatDate = (date) => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
function isPaused(row) {
  return row.activatedAt === null || (row.cancelledAt !== null && DateTime.fromISO(row.cancelledAt) > DateTime.local());
}
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
.reports-table {
  overflow: unset;
}

._name {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  ._text {
    display: flex;
    align-items: center;
    margin-right: 1.2rem;
  }
}

._schedule {
  display: flex;
  align-items: center;
  white-space: nowrap;

  ._icon {
    margin-right: 0.6rem;
  }
}
</style>
