<template>
  <div
    ref="container"
    :class="['goals-explorer-list', bordered ? '-bordered' : '']"
  >
    <full-screen-spinner
      v-if="firstLoadingSvc.loading.value && goalList.length === 0"
      :height="150"
    />
    <template v-else>
      <m-hover-menu
        v-if="showSideMenu && selectedGoalIds.length < 2"
        :item-class="itemClass"
        :mouse-move-area="mouseMoveArea"
        :recreate-key="goalList"
        :force-show="showHoverMenu || showCreateDropdown"
        @set-active-element="setActiveElement"
      >
        <m-card
          flat
          no-padding
          :style="{ marginLeft: '-5rem', marginTop: '.3rem', maxWidth: 'fit-content'}"
          :bordered="viewApplication.gridPageTileGoal === viewApp"
        >
          <div class="_hover-menu-inner">
            <m-tooltip v-if="canCreate && allowedSubItemGoalTypes.length > 0">
              <goal-create-dropdown
                :goal="activeGoal"
                @create-beneath="create"
                @hide="showCreateDropdown = false"
              >
                <m-btn
                  icon="plus"
                  hide-border
                  small
                  super-light
                  fab
                  icon-size="18"
                  :button-style="{ width: '2.2rem' }"
                  :loading="createLoading"
                  :disabled="createLoading"
                  @click="showCreateDropdown = true"
                />
              </goal-create-dropdown>
              <template #title>
                {{ $t('goalsCascadeTable.addTooltip') }}
              </template>
            </m-tooltip>
            <m-dropdown
              v-model:value="showHoverMenu"
              :title="$t('general.actions')"
              placement="leftCenter"
            >
              <m-tooltip>
                <span>
                  <m-btn
                    icon="more"
                    hide-border
                    small
                    fab
                    super-light
                    icon-size="16"
                    :button-style="{ width: '2rem' }"
                    @click="handleHoverMoreClick"
                  />
                </span>
                <template #title>
                  {{ $t('goalsCascadeTable.moreTooltip') }}
                </template>
              </m-tooltip>
              <template #overlay>
                <goal-context-menu
                  :goal="activeGoal"
                  component-type="div"
                  :create-loading="createLoading"
                  :can-create="canCreate"
                  @hide="hideHoverMenu"
                  @create-beneath="create"
                />
              </template>
            </m-dropdown>
          </div>
        </m-card>
      </m-hover-menu>
      <m-endless-scroll-list
        @visible="handleAutoPaginate"
      >
        <template
          v-for="item in goalList"
          :key="item.uid"
        >
          <goal-list-item
            :entity-id="item.uid"
            :props="viewProps"
            :class="['_item', itemClass]"
            :selected="selectedGoalIds.includes(item.uid)"
            :selected-secondary="selectedGoalIds.includes(item.uid) && item.accessRight === 'read'"
            :selected-goal-ids="selectedGoalIds"
            :create-loading="createLoading"
            :can-create="canCreate"
            :read-only="readOnly"
            :show-progress-diff="showProgressDiff"
            :progress-display-option="progressDisplayOption"
            :progress-course-diff="getCustomFuncCtxValue(item)"
            @selection-right-click="$emit('selection-right-click', $event, item)"
            @create="create"
          />
          <m-divider
            v-if="bordered || $store.state.breakpoint.smAndDown"
            :key="`divider_${item.uid}`"
            small
          />
        </template>
      </m-endless-scroll-list>
      <m-content
        v-if="goalList.length === 0 && !canCreate && !listLoading"
        :padding-x="6"
        :padding-y="6"
        class="_empty"
      >
        {{
          $t('goalsCascadeTable.emptyGoalList', { title: loggedInUserAccount.goalSettings.featureNamePlural })
        }}
      </m-content>
      <m-btn
        v-if="hasMore"
        hide-border
        light
        full-width
        block
        :style="{ justifyContent: 'flex-start' }"
        :loading="listLoading"
        icon="arrow-down"
        @click="loadMore"
      >
        {{ $t('general.loadMore') }}
      </m-btn>
      <m-btn
        v-if="canCreate"
        hide-border
        light
        full-width
        block
        :style="{ justifyContent: 'flex-start' }"
        :loading="createLoading"
        icon="plus"
        @click="create()"
      >
        {{ $t('general.new') }}
      </m-btn>
    </template>
  </div>
</template>

<script>
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import GoalContextMenu from '@/components/goal/GoalContextMenu.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import GoalListItem from '@/components/goal/GoalListItem.vue';
import useAccess from '@/composables/access/access';
import useCascadeViewParams from '@/composables/goal/cascade/view-params';
import useCustomFuncCtx from '@/composables/custom-func/custom-func-ctx';
import useDebounce from '@/composables/debounce';
import useFirstLoading from '@/composables/first-loading';
import useGoalFilter from '@/composables/goal/cascade/goal-filter';
import useGoalList from '@/composables/goal/list/goal-list';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useGoalsSorting from '@/composables/goal/sort';
import useLoader from '@/composables/goal/cascade/loader';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import useSnackBar from '@/composables/snackbar';
import useUsers from '@/composables/user/users';
import { AND } from 'shared/api/query/filter';
import { COUNT, RESULT } from 'shared/api/query/constants';
import { MAX_AUTO_LOAD_LIMIT, VIEWS_SERVICE } from '@/lib/constants';
import { applyBaseFilter } from '@/lib/filter/base-filter';
import { computed, inject, toRef } from 'vue';
import { createViewId } from '@/lib/saved-view/saved-view';
import { getAllowedSubItemGoalTypes, goalTypeOption } from '@/composables/goal/allowed-sub-items';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { goalList as goalListQuery } from '@/api/query/nebula/goal';
import { logCatch } from '@/lib/logger/logger';
import { showCompareTo } from '@/lib/compare-to';
import { upToContainerWithClass } from 'shared/lib/dom';
import { viewApplication } from 'shared/constants.json';

export default {
  name: 'GoalsExplorerList',
  props: {
    canCreate: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    selectedGoalIds: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showSideMenu: {
      type: Boolean,
      default: false,
    },
    goalCreator: {
      type: Object,
      default: () => ({ addChild: () => {} }),
    },
    searchTerm: {
      type: String,
      default: '',
    },
    selectedCycles: {
      type: Array,
      default: () => [],
    },
    baseFilter: {
      type: Object,
      default: () => ({}),
    },
    defaultFilter: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['select-goal-row', 'selection-right-click', 'data-loading', 'data-loaded'],
  components: { GoalCreateDropdown, GoalListItem, FullScreenSpinner, GoalContextMenu },
  setup(props) {
    const snackbar = useSnackBar();
    const { debounce } = useDebounce();
    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));
    const viewsService = inject(VIEWS_SERVICE);
    const firstLoadingSvc = useFirstLoading({ currentView: viewsService.currentView });
    const { loggedInUserAccount } = useLoggedInUserAccount();

    const progressDisplayOption = computed(() => viewsService.currentView.value.params.props.find((p) => p.key === goalConfig.edges.cachedCalculatedCurrent).progressDisplayOption);

    const viewParamsService = useCascadeViewParams(
      viewsService,
      props.defaultFilter,
      loggedInUserAccount,
      toRef(props, 'searchTerm'),
      toRef(props, 'selectedCycles'),
    );

    const { userHasRight } = useAccess();
    const { goalTypeProperty } = useGoalTypeProperty();

    const goalsSvc = useGoals();
    const customFuncCtxKey = computed(() => `${createViewId(viewsService.currentView.value)}_compareTo`);
    const customFuncCtx = useCustomFuncCtx(customFuncCtxKey);

    const goalFetcherWithFirstLoading = {
      getGoals: (
        filter = {
          searchTerm: '',
          selectedCycles: [],
          disabledGoalIds: [],
        },
        baseFilter = {},
        pagination = { page: 1, itemsPerPage: 20, countAlias: COUNT },
      ) => {
        firstLoadingSvc.startLoading();

        let progressCourseAlias;
        let customFuncHandler;
        if (viewParamsService.compareTo.value.length !== 0) {
          progressCourseAlias = customFuncCtx.alias.value;
          customFuncHandler = [customFuncCtx];
        }
        return goalsSvc.getGoals({
          queries: applyBaseFilter({
            queries: goalListQuery({
              pagination: {
                page: pagination.page,
                itemsPerPage: pagination.itemsPerPage,
                countAlias: COUNT,
              },
              progressCourse: viewParamsService.compareTo.value,
              progressCourseAlias,
              goalCycles: filter.selectedCycles,
              searchTerm: filter.searchTerm,
              filter: viewParamsService.gqlFilterObject.value.filterTree,
              varBlocks: viewParamsService.gqlFilterObject.value.varBlocks,
              order: viewParamsService.order.value,
              alias: RESULT,
            }),
            operator: AND,
            baseFilter,
          }),
          customFuncHandler,
        });
      },
    };

    const goalFilter = useGoalFilter(
      toRef(props, 'baseFilter'),
      viewParamsService,
    );

    const incRootItems = 20;
    const initRenderedItems = 20;
    const incRenderedItems = 20;
    const {
      fetchLoading,
      listLoading,
      totalRootItems,
      retrieveGoals,
      loadMore,
      renderedItems,
    } = useLoader(
      incRootItems,
      initRenderedItems,
      incRenderedItems,
      goalFetcherWithFirstLoading,
      viewParamsService,
      debounce,
      toRef(props, 'selectedCycles'),
      toRef(props, 'searchTerm'),
      toRef([]),
      toRef(props, 'baseFilter'),
    );

    const hasMore = computed(() => totalRootItems > renderedItems);

    const { properties: goalProperties } = useGoalProperty();
    const userSvc = useUsers();
    const { sort } = useGoalsSorting(goalProperties, userSvc);

    const { goalList } = useGoalList(
      viewsService,
      viewParamsService,
      goalFilter,
      sort,
      goalsSvc.entityList,
    );

    const viewApp = computed(() => viewsService.currentView.value.viewApplication);

    const mousemoveAreaClass = computed(() => {
      switch (viewApp.value) {
        case viewApplication.gridPageTileGoal:
          return 'grid-item';
        default:
          return 'scroll-container';
      }
    });

    return {
      firstLoadingSvc,
      snackbar,
      peekModeSvc,
      userHasRight,

      goalList,
      fetchLoading,
      listLoading,
      retrieveGoals,
      hasMore,
      loadMore,
      renderedItems,

      selectedView: viewsService.currentView,
      viewProps: viewParamsService.props,

      goalTypeProperty,
      loggedInUserAccount,

      mousemoveAreaClass,
      viewApp,
      viewApplication,
      customFuncCtx,
      progressDisplayOption,
    };
  },
  data() {
    return {
      showCreateDropdown: false,
      showHoverMenu: false,
      activeGoalId: -1,
    };
  },
  computed: {
    mouseMoveArea() {
      return upToContainerWithClass(this.$refs.container, this.mousemoveAreaClass);
    },
    createLoading() {
      return this.goalCreator.createLoading.value;
    },
    showProgressDiff() {
      return showCompareTo(this.selectedView.params.compare);
    },
    allowedSubItemGoalTypes() {
      if (this.activeGoal === null) {
        return [];
      }
      return getAllowedSubItemGoalTypes(goalTypeOption(this.activeGoal, this.goalTypeProperty), this.goalTypeProperty);
    },
    activeGoal() {
      return this.goalList.find((g) => g.uid === this.activeGoalId);
    },
  },
  methods: {
    getCustomFuncCtxValue(entity) {
      return this.customFuncCtx.state.value?.[entity.uid];
    },
    handleAutoPaginate() {
      if (this.renderedItems >= MAX_AUTO_LOAD_LIMIT) {
        return;
      }

      this.loadMore();
    },
    create({ parent, option } = { parent: { uid: 0 }, option: null }) {
      this.goalCreator.modifiers.setOption(option);
      this.goalCreator.addChild([parent.uid]).then((newGoal) => {
        this.goalCreator.modifiers.setOption(null);
        this.goalListItems.push(newGoal);
        this.peekModeSvc.openGoal({ goalId: newGoal.uid });
      }).catch(logCatch(() => {
        this.snackbar.error();
      })).finally(() => {
        this.showCreateDropdown = false;
        this.showHoverMenu = false;
      });
    },
    hideHoverMenu() {
      this.showHoverMenu = false;
      this.activeGoalId = -1;
    },
    handleHoverMoreClick() {
      this.showHoverMenu = true;
      this.$emit('select-goal-row', { goal: { uid: this.activeGoalId } });
    },
    setActiveElement(uid) {
      this.activeGoalId = uid;
    },
  },
  watch: {
    fetchLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.$emit('data-loaded');
      }
      if (oldVal === false && newVal === true) {
        this.$emit('data-loading');
      }
    },
    listLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.firstLoadingSvc.finishLoading();
      }
    },
  },
  created() {
    this.retrieveGoals();
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .goals-explorer-list {
    padding-bottom: .8rem;

    ._empty {
      margin: .8rem 0 .8rem .6rem;
    }

    ._item {
      margin: .4rem 0;
    }

    &.-bordered {
      ._item {
        margin: 0;
      }
    }

    ._hover-menu-inner {
      display: flex;
    }
  }
</style>
