<template>
  <component
    :is="component"
    class="reminder-item"
    :disabled="disabled"
    :light="disabled"
  >
    <m-dropdown
      v-model:value="showTitleEdit"
      :title="$t('general.edit')"
      class="_title"
      block
      :disabled="disabled"
    >
      <div
        class="_title"
        @click="showTitleEdit = true"
      >
        {{ title }}
      </div>
      <template #overlay>
        <m-card

          small-padding
        >
          <reminder-editor
            :reminder="reminder"
            @update="handleUpdate"
          />
        </m-card>
      </template>
    </m-dropdown>
    <template #right>
      <div
        v-if="!disabled"
        class="_actions"
      >
        <m-dropdown
          v-model:value="showActions"
          :title="$t('general.title')"
        >
          <m-btn
            icon="ellipsis"
            fab
            hide-border
            xs
            @click="showActions = true"
          />
          <template #overlay>
            <m-card

              list
              no-padding
            >
              <m-dropdown
                v-model:value="showEdit"
                :title="$t('general.edit')"
                block
              >
                <m-card-item
                  icon="edit"
                  @click="showEdit = true"
                >
                  {{ $t('general.edit') }}
                </m-card-item>
                <template #overlay>
                  <m-card

                    small-padding
                  >
                    <reminder-editor
                      :reminder="reminder"
                      @update="handleUpdate"
                    />
                  </m-card>
                </template>
              </m-dropdown>
              <m-card-item
                icon="delete"
                @click="deleteReminder"
              >
                {{ $t('general.delete') }}
              </m-card-item>
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </template>
  </component>
</template>

<script>
import ReminderEditor from '@/components/update-schedules/ReminderEditor.vue';
import { camelCase } from 'lodash-es';
import { durationUnit } from '@/constants.json';

export default {
  name: 'ReminderItem',
  props: {
    reminder: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    component: {
      type: String,
      default: 'm-card-item',
    },
  },
  emits: ['delete', 'update'],
  components: { ReminderEditor },
  data() {
    return {
      showEdit: false,
      showActions: false,
      showTitleEdit: false,
    };
  },
  computed: {
    title() {
      // in go durations are an int64 in nanoseconds (10 to the power of -9);
      const minutes = this.reminder.after / (10 ** 9) / 60;

      if (minutes % (24 * 60) === 0) {
        const unit = this.$t(`afterUnit.${camelCase(durationUnit.day)}`, minutes / (24 * 60));
        return `${this.$t('reminderEditor.after')} ${minutes / (24 * 60)} ${unit}`;
      }
      if (minutes % 60 === 0) {
        const unit = this.$t(`afterUnit.${camelCase(durationUnit.hour)}`, minutes / 60);
        return `${this.$t('reminderEditor.after')} ${minutes / 60} ${unit}`;
      }

      const unit = this.$t(`afterUnit.${camelCase(durationUnit.minute)}`, minutes);
      return `${this.$t('reminderEditor.after')} ${minutes} ${unit}`;
    },
  },
  methods: {
    deleteReminder() {
      this.$emit('delete', this.reminder);
      this.showActions = false;
    },
    handleUpdate(event) {
      this.$emit('update', event);
      this.showEdit = false;
      this.showActions = false;
      this.showTitleEdit = false;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
