<template>
  <m-content
    padding-xs
    :class="['trial-box', isUrgent? '-urgent' : '']"
  >
    <m-card
      :level="1"
      padding-xs
      class="_card"
    >
      <div class="_title">
        <div class="_left">
          <i18n-t
            v-if="daysRemaining < 0"
            keypath="trialBox.expired"
          />
          <span
            v-else-if="daysRemaining === 0"
            class="_highlighted"
          >
            {{ $t('trialBox.today') }}
          </span>
          <span
            v-else-if="daysRemaining === 1"
            class="_highlighted"
          >
            {{ $t('trialBox.tomorrow') }}
          </span>
          <i18n-t
            v-else
            keypath="trialBox.remaining"
          >
            <template #amount>
              <span class="_highlighted">
                {{ $t('trialBox.days', { amount: daysRemaining }) }}
              </span>
            </template>
          </i18n-t>
        </div>
        <div
          v-if="daysRemaining > 0"
          class="_right"
        >
          <m-btn
            xs
            fab
            icon="close"
            hide-border
            @click="$emit('hide')"
          />
        </div>
      </div>
      <m-btn
        v-if="daysRemaining > 0"
        class="_btn"
        block
        :to="plansLink"
      >
        {{ $t('trialBox.button') }}
      </m-btn>
      <m-btn
        v-else
        class="_btn"
        block
        @click="showDialog = true"
      >
        {{ $t('trialBox.button') }}
      </m-btn>
      <m-btn
        class="_btn"
        block
        :href="$t('trialBox.demoLink')"
        target="_blank"
      >
        {{ $t('trialBox.bookDemo') }}
      </m-btn>
      <m-dialog
        v-model:value="showDialog"
        hide-footer
        center
        max-width="40rem"
      >
        <upgrade-form
          :title="$t('upgradeForm.subscriptionExpired')"
          expired
          :description="$t('upgradeForm.subscriptionExpiredText')"
          @close="showDialog = false"
        />
      </m-dialog>
    </m-card>
  </m-content>
</template>

<script>
import UpgradeForm from '@/components/plans/UpgradeForm.vue';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import { DateTime } from 'luxon';
import { routeName } from 'shared/constants.json';

export default {
  name: 'TrialBox',
  components: { UpgradeForm },
  setup() {
    const { accountSettings } = useAccountSettings();
    return { accountSettings };
  },
  data() {
    return { showDialog: false };
  },
  emits: ['hide'],
  computed: {
    plansLink() {
      return { name: routeName.plans };
    },
    daysRemaining() {
      if (this.expiresAt === null) {
        return 14;
      }
      const millis = DateTime.fromISO(this.expiresAt).diff(DateTime.local()).milliseconds;
      const days = Math.floor(millis / (1000 * 3600 * 24));
      if (days < 0) {
        return -1;
      }
      return days;
    },
    expiresAt() {
      return this.accountSettings.expiresAt;
    },
    isUrgent() {
      return this.daysRemaining < 6;
    },
  },
};
</script>

<style lang="scss" type="text/scss">
  .trial-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ._card {
      background-color: $side-nav-background-color;
    }

    ._title {
      display: flex;
    }

    &.-urgent {
      ._highlighted {
        color: map_get($red, 'base');
      }
    }

    ._btn {
      width: 100%;
      margin-top: 1.2rem;
    }
  }

</style>
