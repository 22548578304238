<template>
  <div class="property-label">
    <div
      v-if="errors.length > 0"
      class="_error"
    >
      <m-tooltip placement="left">
        <div class="_error-icon">
          <m-icon
            type="exclamation-circle-filled"
            :color="$colors.red.base"
          />
        </div>
        <template
          #title
        >
          <validation-tooltip-content :errors="errors" />
        </template>
      </m-tooltip>
    </div>
    <div
      :class="['_left', hasMenu ? '-has-menu' : '']"
    >
      <m-tooltip
        placement="left"
        :mouse-enter-delay="1"
      >
        <m-dropdown
          v-model:value="showMenu"
          :title="$t('general.actions')"
          :disabled="!hasMenu"
        >
          <div
            class="_inner"
            @click="showMenu = true"
            @contextmenu.prevent="showMenu = true"
          >
            <div class="_icon">
              <m-icon :type="icon" />
            </div>
            <div class="_label">
              {{ label }}
            </div>
          </div>
          <template #overlay>
            <slot
              name="labelMenu"
            />
          </template>
        </m-dropdown>
        <template #title>
          {{ label }}
        </template>
      </m-tooltip>
    </div>
    <m-content
      :class="classesRight"
    >
      <slot />
    </m-content>
  </div>
</template>

<script>
import ValidationTooltipContent from '@/components/rules/ValidationTooltipContent.vue';
import { EventBus } from '@/lib/event-bus';

export default {
  name: 'PropertyLabel',
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    allowOverflow: {
      type: Boolean,
      default: false,
    },
    hasMenu: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: { ValidationTooltipContent },
  data() {
    return { showMenu: false };
  },
  computed: {
    classesRight() {
      return [
        '_right',
        this.allowOverflow ? '-allow-overflow' : '',
      ];
    },
  },
  methods: {
    hideMenu() {
      this.showMenu = false;
    },
  },
  created() {
    EventBus.$on('hide-customize-page', this.hideMenu);
  },
  beforeUnmount() {
    EventBus.$off('hide-customize-page', this.hideMenu);
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .property-label {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: .4rem 0;

    ._error {
      position: absolute;
      top: 1rem;
      left: -2rem;

      @media (max-width: $screen-size-sm) {
        left: -1.7rem;
      }

      ._error-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        cursor: help;
      }
    }

    ._left {
      margin-right: 1rem;

      ._inner {
        display: flex;
        align-items: center;
        width: 16rem;
        height: 3.2rem;
        padding: 0 .4rem;
        overflow: hidden;
        color: $font-color-secondary;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: $btn-border-radius;

        @media (max-width: $screen-size-sm) {
          width: 10rem;
        }
      }

      ._label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.-has-menu {
        ._inner {
          cursor: pointer;

          &:hover {
            background-color: $hover-color;
          }
        }
      }

      ._icon {
        margin-right: .8rem;
        color: $font-color-secondary;
      }
    }

    ._right {
      align-self: center;
      width: 100%;
      min-width: 20rem;
      overflow: hidden;
      text-overflow: ellipsis;

      &.-allow-overflow {
        overflow: visible;
      }
    }
  }
</style>
