<template>
  <sign-in-content
    class="login-form"
  >
    <div
      v-if="!saml"
      class="_sso"
    >
      <div class="_btn">
        <m-btn
          block
          @click="goToGoogle"
        >
          <m-icon
            size="16"
            type="google-color"
            class="_icon"
          />
          {{ $t('login.viaGoogle') }}
        </m-btn>
      </div>
      <div class="_btn">
        <m-btn
          block
          @click="goToMicrosoft"
        >
          <m-icon
            size="16"
            type="microsoft-color"
            class="_icon"
          />
          {{ $t('login.viaMicrosoft') }}
        </m-btn>
      </div>
    </div>
    <div class="_errors">
      <o-auth-error
        v-if="emailNotRegistered[oAuthProvider.google] !== ''"
        :email="emailNotRegistered[oAuthProvider.google]"
        :provider="oAuthProvider.google"
      />
      <o-auth-error
        v-if="emailNotRegistered[oAuthProvider.microsoft] !== ''"
        :email="emailNotRegistered[oAuthProvider.microsoft]"
        :provider="oAuthProvider.microsoft"
      />
    </div>
    <m-divider
      v-if="!saml"
      small
    />
    <password-login
      v-if="!saml"
      :loading="passwordLoading"
      @submit="submit"
      @ssologin="saml = true"
    />
    <saml-login
      v-if="saml"
      @close="disableSaml"
    />
    <div
      v-if="saml"
      class="_saml-back"
    >
      <m-link
        inherit-color
        @click="disableSaml"
      >
        {{ $t('login.back') }}
      </m-link>
    </div>
  </sign-in-content>
</template>

<script>
import OAuthError from '@/components/OAuthError.vue';
import PasswordLogin from '@/components/PasswordLogin.vue';
import SamlLogin from '@/components/SamlLogin.vue';
import SignInContent from '@/components/SignInContent.vue';
import { doLoginUser } from '@/api';
import { encodeURLValues } from '@/lib/url';
import { errorType } from '@/constants.json';
import { initOAuthLogin } from '@/lib/oauth-redirect';
import { mapActions, mapState } from 'vuex';
import { oAuthProvider } from 'shared/constants.json';

export default {
  name: 'LoginForm',
  components: { SamlLogin, SignInContent, OAuthError, PasswordLogin },
  data() {
    return {
      show: false,
      googleLoading: false,
      microsoftLoading: false,
      password: '',
      email: '',
      emailNotRegistered: oAuthProvider.all.reduce((res, cur) => ({ ...res, [cur]: '' }), {}),
      oAuthProvider,
      passwordLoading: false,
      samlError: '',
      saml: false,
    };
  },
  emits: ['session-established'],
  computed: { ...mapState({ loginURLs: (state) => state.loginURLs }) },
  methods: {
    ...mapActions([
      'oauthLogin',
    ]),
    disableSaml() {
      this.saml = false;
      this.$route.query.saml_login_status = undefined;
    },
    goToGoogle() {
      const params = {
        action: 'login',
        provider: oAuthProvider.google,
      };
      const url = `/#/oauth-redirect?${encodeURLValues(params)}`;

      this.googleLoading = true;
      initOAuthLogin(url).then(this.subscribeWindowEvents)
        .finally(() => {
          this.googleLoading = false;
        });
    },
    goToMicrosoft() {
      const params = {
        action: 'login',
        provider: oAuthProvider.microsoft,
      };
      const url = `/#/oauth-redirect?${encodeURLValues(params)}`;

      this.microsoftLoading = true;
      initOAuthLogin(url).then(this.subscribeWindowEvents)
        .finally(() => {
          this.microsoftLoading = false;
        });
    },
    submit({ email, password }) {
      this.passwordLoading = true;
      doLoginUser({
        email,
        password,
      })
        .then((response) => {
          this.passwordLoading = false;
          if (response.status === 400) {
            this.$showSnackbar({ color: 'error', message: this.$t('login.emailPasswordCombinationWrong') });
            return;
          }
          if (response.status === 403) {
            this.$showSnackbar({ color: 'error', message: this.$t('login.passwordLoginRestricted') });
            return;
          }
          if (response.status !== 200) {
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
            return;
          }

          this.$emit('session-established');
        });
    },
    subscribeWindowEvents(event) {
      const { provider, error, email } = event.data;
      if (error !== '') {
        switch (error) {
          case errorType.noSuchUser:
            this.emailNotRegistered[provider] = email;
            break;
          case errorType.googleLoginNotAllowed:
            this.$showSnackbar({ color: 'error', message: this.$t('loginForm.googleLoginNotAllowed') });
            break;
          case errorType.microsoftLoginNotAllowed:
            this.$showSnackbar({ color: 'error', message: this.$t('loginForm.microsoftLoginNotAllowed') });
            break;
          default:
            this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        }
        return Promise.resolve();
      }

      this.$emit('session-established');
      return Promise.resolve();
    },
  },
  mounted() {
    if (this.$route.query.email !== undefined) {
      this.email = this.$route.query.email;
    }

    if (this.$route.query.saml_login_status !== undefined) {
      this.saml = true;
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .login-form {
    width: 100%;

    ._sso {
      ._btn {
        margin-bottom: .4rem;
        background-color: white;
        border-radius: $btn-border-radius;
      }

      ._icon {
        margin-right: .8rem;
      }
    }

    ._errors {
      color: map_get($orange, 'base');

      > * {
        padding: 0 .8rem;
        margin-top: .8rem;
      }
    }

    ._saml-back {
      margin-top: 2rem;
      font-size: $font-size-3;
      color: $font-color-secondary;
      text-align: center;
      text-decoration: underline;
    }
  }
</style>
