<template>
  <div class="user-scope-tree-user-field">
    <div class="_top">
      {{ `${count} ${$t('userScopeTreeUserField.persons', count)}` }}
    </div>
    <div class="_content">
      <account-scope-filter
        :value="userScopeTree"
        :props="userProps"
        :account="loggedInUserAccount"
        :all-text="$t('formSend.sendToAll')"
        :segment-text="$t('formSend.sendToSegments')"
        show-static-user-selection
        class="_filter"
        @input="updateUserScopeTree"
      />
      <user-scope-tree-user-list
        :user-scope-tree="userScopeTree"
        is-filter-mode
        class="_list"
        @update-count="updateCount"
      />
    </div>
  </div>
</template>

<script>
import AccountScopeFilter from '@/components/filter/AccountScopeFilter.vue';
import UserScopeTreeUserList from '@/components/UserScopeTreeUserList.vue';
import useDebounce from '@/composables/debounce';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import { copy } from 'shared/lib/copy';
import { createProp } from '@/lib/props';
import { isEqual } from 'lodash-es';
import { user as userConfig } from 'shared/api/query/configs.json';

export default {
  name: 'UserScopeTreeUserField',
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'update:value'],
  components: {
    UserScopeTreeUserList,
    AccountScopeFilter,
  },
  setup() {
    const { debounce } = useDebounce();
    const { userProperties } = useProperties();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { userLang } = useLoggedInUser();
    return { debounce, userProperties, loggedInUserAccount, userLang };
  },
  data() {
    return {
      showModal: false,
      userScopeTree: null,
      loading: false,
      count: 0,
    };
  },
  computed: {
    userProps() {
      return this.userProperties.map((p) => createProp(p, false, true, true, this.userLang, userConfig.model));
    },
  },
  methods: {
    updateCount(val, oldVal) {
      if (val === oldVal) {
        return;
      }

      this.count = val;
      this.submit();
    },
    updateUserScopeTree(val, oldVal) {
      if (isEqual(val, oldVal)) {
        return;
      }

      this.userScopeTree = val;
      this.submit();
    },
    show() {
      if (this.disabled) {
        return;
      }

      this.showModal = true;
    },
    submit() {
      this.$emit('input', { userScopeTree: this.userScopeTree, count: this.count });
      this.$emit('update:value', { userScopeTree: this.userScopeTree, count: this.count });
    },
  },
  created() {
    this.userScopeTree = copy(this.value);
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  $value-padding: .4rem 1.1rem;

  .user-scope-tree-user-field {
    ._top {
      padding: $value-padding;
      background-color: map_get($grey, 'lighten-4');
      border-bottom: 1px solid $input-border-color;
      border-top-left-radius: $input-field-border-radius;
      border-top-right-radius: $input-field-border-radius;
    }

    ._content {
      padding: $value-padding;
      margin-top: 1rem;
    }

    ._list {
      margin-top: 2rem;
    }

    ._submit {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 1.4rem;
      margin-top: 1.4rem;
      border-top: 1px solid $input-border-color;

      ._btn {
        margin-left: .6rem;
      }
    }
  }
</style>
