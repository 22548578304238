<template>
  <div class="m-card-group">
    <m-item-wrapper
      @click="show = !show"
    >
      <div class="_heading">
        <div class="_icon">
          <m-icon
            :type="show ? 'chevron-down' : 'chevron-right'"
            :color="$colors.grey.base"
          />
        </div>
        <div class="_title">
          {{ title }}
        </div>
      </div>
    </m-item-wrapper>
    <m-transition-expand>
      <div
        v-if="show"
        class="_items"
      >
        <slot />
      </div>
    </m-transition-expand>
  </div>
</template>

<script>
import MIcon from 'shared/components/base/MIcon.vue';
import MItemWrapper from 'shared/components/base/MItemWrapper.vue';
import MTransitionExpand from 'shared/components/base/MTransitionExpand.vue';

export default {
  name: 'MCardGroup',
  props: {
    title: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  components: { MIcon, MItemWrapper, MTransitionExpand },
  data() {
    return { show: false };
  },
  watch: {
    expanded(val) {
      if (val === true) {
        this.show = true;
      }
    },
  },
  created() {
    this.show = this.expanded;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .m-card-group {
    ._heading {
      display: flex;
      align-items: center;
      height: 2.8rem;
      padding: 0 1rem;
      cursor: pointer;
      border-radius: $btn-border-radius;

      &:hover {
        background-color: $hover-color;
      }

      ._icon {
        margin-right: .4rem;
      }

      ._title {
        font-size: 1.1rem;
        font-weight: $font-weight-semibold;
        color: $font-color-secondary;
        text-transform: uppercase;
      }
    }
  }
</style>
