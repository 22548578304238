<template>
  <updates-explorer
    show-create-view
    show-views-selector
    show-onboarding
  />
</template>

<script>
import UpdatesExplorer from '@/components/updates/UpdatesExplorer.vue';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUpdateDefaultProps from '@/composables/saved-views/update-default-props';
import useViewServiceInit from '@/composables/saved-views/view-service-init';
import { CREATED_AT } from 'shared/api/query/constants';
import { VIEWS_SERVICE } from '@/lib/constants';
import { provide, ref, watch } from 'vue';
import { filter as spaceFilter } from '@/lib/filter/space/filter';
import { useI18n } from 'vue-i18n';
import { viewApplication, viewType } from 'shared/constants.json';

export default {
  name: 'SpaceUpdates',
  props: {
    property: {
      type: Object,
      required: true,
    },
    space: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const i18n = useI18n();

    const isFeedRef = ref(false);

    const defaultPropsSvc = useUpdateDefaultProps({ isFeed: isFeedRef });
    const viewServiceInitService = useViewServiceInit();

    const { loggedInUserAccount } = useLoggedInUserAccount();

    const initialFilter = spaceFilter({
      property: props.property,
      space: props.space,
      account: { uid: loggedInUserAccount.value.uid },
    });

    const viewApp = viewApplication.updateSpace;

    const defaultView = {
      title: i18n.t('list.feed'),
      viewType: viewType.feed,
      viewApplication: viewApp,
      space: props.space,
      params: {
        filter: initialFilter,
        order: [{ attr: CREATED_AT, desc: true }],
        props: [],
      },
    };

    let routeAwareConfig = viewServiceInitService.defaultConfig();
    routeAwareConfig = viewServiceInitService.withDefaultView(routeAwareConfig, defaultView);
    routeAwareConfig = viewServiceInitService.withViewApplication(routeAwareConfig, viewApp);
    routeAwareConfig = viewServiceInitService.withDefaultProps(routeAwareConfig, defaultPropsSvc.defaultProps);
    routeAwareConfig = viewServiceInitService.withSpace(routeAwareConfig, props.space);

    const viewSvc = viewServiceInitService.routeAwareViewService(routeAwareConfig);
    provide(VIEWS_SERVICE, viewSvc);

    watch(viewSvc.currentView, (currentView) => {
      if (currentView.viewType === viewType.feed) {
        isFeedRef.value = true;
        return;
      }
      isFeedRef.value = false;
    }, { immediate: true });

    return { viewApp };
  },
  components: { UpdatesExplorer },
};
</script>

<style scoped lang="scss" type="text/scss">

</style>
