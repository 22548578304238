<template>
  <chart
    :options="chartOptions"
    class="_chart"
  />
</template>

<script setup>
import { Chart } from 'highcharts-vue';
import { computed } from 'vue';

const props = defineProps({ chart: { type: Object, required: true } });

const chartOptions = computed(() => {
  const baseOptions = { chart: { animation: false } };

  switch (props.chart.chartType) {
    case 'bar':
      return {
        ...baseOptions,
        chart: { ...baseOptions.chart, type: 'bar' },
        series: [{ data: [1, 2, 3, 4, 5] }],
      };
    case 'line':
      return {
        ...baseOptions,
        chart: { ...baseOptions.chart, type: 'line' },
        series: [{ data: [1, 3, 2, 4, 5] }],
      };
    case 'pie':
      return {
        ...baseOptions,
        chart: { ...baseOptions.chart, type: 'pie' },
        series: [
          {
            data: [
              { name: 'A', y: 30 },
              { name: 'B', y: 70 },
            ],
          },
        ],
      };
    default:
      return baseOptions;
  }
});

</script>
