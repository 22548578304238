<template>
  <div :class="['goal-update-editor', focused ? '-focused' : '', loading ? '-loading' : '']">
    <comment-layout
      class="_editor"
      :creator="update === null ? loggedInUser : update.creator"
      :hide-title="update === null"
      :timestamp="timestamp"
      :generated="update === null ? false : update.generated"
      :show-actions="!readOnly && (showMenu || showReactionBtn)"
      show-meta
    >
      <template #post-title>
        <div
          v-if="hasCustomCreatedAt"
          class="_tooltip"
        >
          <m-tooltip class="_tooltip-inner">
            <span class="_icon-info">
              <m-icon
                type="clock-circle"
                :color="$colors.grey.lighten1"
                size="13"
              />
            </span>
            <template #title>
              {{ $t('goalUpdateEditor.createdAtExplanation', { date: formatDate(update.createdAt) }) }}
            </template>
          </m-tooltip>
        </div>

        <m-tag
          v-if="update !== null && update.goal === null"
          xxs
          color="light"
          :style="{ display: 'inline-flex', marginLeft: '.8rem', textTransform: 'uppercase', fontWeight: '500' }"
          :title="$t('updateFeedItem.viaCheckin')"
          :clickable="!readOnly"
          @click="viewCheckIn"
        />
      </template>
      <div :class="['_inner', disabled ? '-disabled' : '']">
        <m-editor
          v-if="allowComments"
          v-show="!(disabled && content === null && initialValue === null)"
          :key="key"
          ref="editor"
          v-model:value="content"
          class="_m-editor"
          :placeholder="$t('goalUpdateEditor.writeComment')"
          :allowed-content="allowedContent"
          :disabled="disabled"
          :initial-value="initialValue"
          :ctrl-enter-handlers="[onCmdOrModEnter]"
          :mod-enter-handlers="[onCmdOrModEnter]"
          @focus="focused = true"
        />
        <template
          v-if="allowGoalActivity"
        >
          <goal-activity-form
            v-for="(activity, index) in goalActivities"
            :key="`${index}_${activity.goal.uid}`"
            :activity="activity"
            prevent-search
            class="_activity"
            :disabled="disabled"
            :clearable="goalsClearable"
            :read-only="readOnly"
            @delete="deleteGoalActivity(activity, index)"
            @change="updateActivity($event, index)"
          />
        </template>
      </div>
      <div class="_submit">
        <div
          v-if="!disabled && (allowGoalActivity || (allowComments && focused))"
          class="_submit-btn"
        >
          <m-btn
            color="primary"
            small
            :loading="loading"
            @click="submit"
          >
            <template v-if="update === null">
              {{ $t('general.send') }}
            </template>
            <template v-else>
              {{ $t('general.save') }}
            </template>
          </m-btn>
        </div>
        <div
          v-else-if="update !== null && update.goal !== null"
          class="_bottom-actions"
        >
          <reaction-list
            :update="update"
            class="_reactions"
            :read-only="readOnly"
          />
          <comment-list
            ref="comments"
            :comments="comments"
            :update="update"
            class="_comments"
            collapse
            @write-comment="writeComment = true"
          />
        </div>
      </div>
      <template #actions>
        <div
          class="_actions"
        >
          <div class="_container">
            <m-card
              v-if="!readOnly && update !== null && update.goal !== null && disabled"
              :level="1"
              padding-xxxs
              border-radius="small"
              class="_btn-group"
            >
              <div class="_inner">
                <m-tooltip>
                  <div :style="{ height: '100%' }">
                    <add-reaction-btn
                      ref="reactionBtn"
                      :update="update"
                      fab
                      light
                      small
                      :button-style="reactionBtnStyle"
                      hide-border
                      @show="showReactionBtn = true"
                      @hide="showReactionBtn = false"
                    />
                  </div>
                  <template #title>
                    {{ $t('goalUpdateEditor.addReaction') }}
                  </template>
                </m-tooltip>
                <m-tooltip>
                  <span>
                    <m-btn
                      icon="message"
                      hide-border
                      light
                      small
                      :button-style="messageBtnStyle"
                      fab
                      @click="$refs.comments.add()"
                    />
                  </span>
                  <template #title>
                    {{ $t('goalUpdateEditor.replyInThread') }}
                  </template>
                </m-tooltip>
                <m-dropdown
                  v-if="disabled && menuItems.length > 0"
                  v-model:value="showMenu"
                  :title="$t('general.actions')"
                  placement="bottomRight"
                >
                  <m-tooltip>
                    <span>
                      <m-btn
                        icon="ellipsis"
                        :button-style="ellipsisButtonStyle"
                        hide-border
                        small
                        light
                        fab
                        class="_link"
                        @click="showMenu = true"
                      />
                    </span>
                    <template #title>
                      {{ $t('general.moreActions') }}
                    </template>
                  </m-tooltip>
                  <template #overlay>
                    <m-card
                      no-padding
                      list
                    >
                      <m-card-item
                        v-for="item in menuItems"
                        :key="item.name"
                        :icon="item.icon"
                        :style="item.style === undefined ? null : item.style"
                        @click="item.onClick"
                      >
                        {{ item.name }}
                      </m-card-item>
                    </m-card>
                  </template>
                </m-dropdown>
              </div>
            </m-card>
            <m-dialog
              :value="showModal"
              :max-width="$modalSizes.xl"
              hide-footer
              no-padding
              keep-height
              hide-header
              top="7rem"
              @close="handleClose"
            >
              <update-editor
                :update="update"
                disabled
                :modal="true"
                @saved="setUpdate"
                @deleted="deleteGlobalUpdate"
                @cancel="updateDirty = false"
                @is-dirty="updateDirty = true"
              />
            </m-dialog>
          </div>
        </div>
      </template>
    </comment-layout>
  </div>
</template>

<script>
import AddReactionBtn from '@/components/reaction/AddReactionBtn.vue';
import CommentLayout from '@/components/comment/CommentLayout.vue';
import CommentList from '@/components/comment/CommentList.vue';
import GoalActivityForm from '@/components/goal/GoalActivityForm.vue';
import MEditor from '@/components/editor/MEditor.vue';
import ReactionList from '@/components/reaction/ReactionList.vue';
import UpdateEditor from '@/components/updates/UpdateEditor.vue';
import isEqual from 'lodash-es/isEqual';
import useAccess from '@/composables/access/access';
import useComments from '@/composables/comments/comments';
import useGoalProperty from '@/composables/property/goal-property';
import useGoals from '@/composables/goal/goals';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useStatusProperty from '@/composables/goal/status-property';
import useUpdates from '@/composables/updates/updates';
import { DateTime } from 'luxon';
import { accessPolicyType,
  editorNodeType,
  featureFlag,
  goalProgressMeasurement } from 'shared/constants.json';
import { copy } from 'shared/lib/copy';
import { current } from '@/lib/goal/progress';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { ref } from 'vue';
import { trimEmptyLines } from '@/lib/editor/editor';
import { uniqBy } from 'lodash-es';

export default {
  name: 'GoalUpdateEditor',
  props: {
    goal: {
      type: Object,
      required: true,
    },
    allowComments: {
      type: Boolean,
      default: false,
    },
    allowGoalActivity: {
      type: Boolean,
      default: false,
    },
    goalChildren: {
      type: Array,
      required: true,
    },
    update: {
      type: Object,
      default: () => null,
    },
    lastUpdate: {
      type: Object,
      default: () => null,
    },
    initialValue: {
      type: Object,
      default: () => ({}),
    },
    initialDisabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    autoAddGoalActivity: {
      type: Boolean,
      default: false,
    },
    goalsClearable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['created', 'saved', 'deleted'],
  components: { AddReactionBtn, GoalActivityForm, MEditor, UpdateEditor, ReactionList, CommentList, CommentLayout },
  setup(props) {
    const { comments } = useComments();
    const updateComments = props.update === null ? ref([]) : comments({ updateId: props.update.uid });

    const updateSvc = useUpdates();

    const { selectSingle } = useGoals();

    const { statusProperty: statusProp } = useGoalProperty();
    const { loggedInUser } = useLoggedInUser();
    const { calculateInitialPropertyStatus } = useStatusProperty(statusProp, loggedInUser.language);

    const allowedContent = [
      editorNodeType.codeBlock,
      editorNodeType.blockquote,
      editorNodeType.bulletList,
      editorNodeType.orderedList,
      editorNodeType.mention,
      editorNodeType.goalMention,
    ];

    const { accountHasFeature } = useAccess();
    if (accountHasFeature([featureFlag.fileUpload])) {
      allowedContent.push(editorNodeType.image, editorNodeType.file);
    }

    return {
      selectSingle,
      comments: updateComments,

      createUpdate: updateSvc.createUpdate,
      updateUpdate: updateSvc.updateUpdate,
      deleteUpdate: updateSvc.deleteUpdate,
      fetchUpdatesForGoal: updateSvc.fetchUpdatesForGoal,
      createUpdateLoading: updateSvc.createUpdateLoading,
      updateUpdateLoading: updateSvc.updateUpdateLoading,
      userLang: loggedInUser.value.language,
      loggedInUser,
      calculateInitialPropertyStatus,
      allowedContent,
    };
  },
  data() {
    return {
      focused: false,
      content: null,
      disabled: true,
      key: 0,
      writeComment: false,
      activitiesToCreate: [],
      updateDirty: false,
      showModal: false,
      showMenu: false,
      showReactionBtn: false,
    };
  },
  computed: {
    loading() {
      return this.updateUpdateLoading || this.createUpdateLoading;
    },
    hasCustomCreatedAt() {
      if (this.update === null) {
        return false;
      }

      const d1 = DateTime.fromISO(this.update.createdAt);
      const d2 = DateTime.fromISO(this.update.customCreatedAt);

      return Math.abs(d1.diff(d2).as('day')) >= 1;
    },
    messageBtnStyle() {
      if (this.menuItems.length === 0) {
        return {
          borderBottomRightRadius: '4px',
          borderTopRightRadius: '4px',
        };
      }

      return {};
    },
    reactionBtnStyle() {
      return {
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
        borderRightWidth: '.3px',
      };
    },
    ellipsisButtonStyle() {
      if (this.update === null || this.update.goal === null) {
        return { 'border-radius': '4px' };
      }

      return { 'border-top-right-radius': '4px', 'border-bottom-right-radius': '4px', 'border-left-width': '.3px' };
    },
    menuItems() {
      if (this.readOnly) {
        return [];
      }

      if (this.update === null) {
        return [];
      }

      if (this.update.goal === null) {
        return [
          {
            name: this.$t('goalUpdateEditor.viewCheckIn'),
            onClick: this.viewCheckIn,
          },
        ];
      }

      if (this.loggedInUser === null) {
        return [];
      }

      if (this.update.generated === true && [accessPolicyType.write, accessPolicyType.full].includes(this.goal.accessRight)) {
        return [
          {
            icon: 'delete',
            name: this.$t('general.delete'),
            onClick: this.onDeleteUpdate,
          },
        ];
      }

      if (this.update.creator === null || this.update.creator.uid !== this.loggedInUser.uid) {
        return [];
      }

      return [
        {
          icon: 'edit',
          name: this.$t('general.edit'),
          onClick: this.editUpdate,
        },
        {
          icon: 'delete',
          name: this.$t('general.delete'),
          onClick: this.onDeleteUpdate,
        },
      ];
    },
    goalActivities() {
      const ids = [this.goal.uid, ...this.goalChildren.map((g) => g.uid)];
      return this.activitiesToCreate.filter((a) => {
        if (a.referenced === true) {
          return false;
        }

        if (a.deletedAt !== undefined) {
          return false;
        }

        return ids.includes(a.goal.uid);
      });
    },
    goalList() {
      return uniqBy([
        this.goal,
        ...this.goalChildren,
      ], 'uid').filter((g) => g[goalConfig.edges.progressMeasurement] !== goalProgressMeasurement.none);
    },
    nextGoal() {
      const filtered = this.goalList.filter((g) => this.updatedGoals.map((u) => u.uid).indexOf(g.uid) === -1);
      if (filtered.length === 0) {
        return null;
      }

      return filtered[0];
    },
    updatedGoals() {
      return this.activitiesToCreate.map((activity) => activity.goal);
    },
    timestamp() {
      if (this.update === null) {
        return '';
      }

      if (this.update.createdAt !== this.update.modifiedAt) {
        return `${DateTime.fromISO(this.update.customCreatedAt).toLocaleString(DateTime.DATETIME_MED)} (${this.$t('goalUpdateEditor.edited')})`;
      }

      return DateTime.fromISO(this.update.customCreatedAt).toLocaleString(DateTime.DATETIME_MED);
    },
  },
  methods: {
    handleClose() {
      const close = () => {
        this.showModal = false;
        this.updateDirty = false;
      };

      if (this.updateDirty) {
        this.$confirm({
          title: this.$t('general.discardEditPrompt'),
          okText: this.$t('general.discardChanges'),
          cancelText: this.$t('general.close'),
          okType: 'warning',
          maskClosable: false,
          onOk() {
            close();
          },
        });
        return;
      }

      close();
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    onCmdOrModEnter() {
      this.submit();
      return true;
    },
    viewCheckIn() {
      this.showMenu = false;
      this.showModal = true;
    },
    setUpdate(value) {
      this.updateDirty = false;
      this.activitiesToCreate = copy(value.goalActivities);
    },
    deleteGlobalUpdate() {
      this.showModal = false;
    },
    deleteGoalActivity(activity, i) {
      if (activity.uid === undefined || activity.uid === 0) {
        this.activitiesToCreate.splice(i, 1);
        return;
      }
      let index = -1;
      let value = null;
      this.activitiesToCreate.forEach((a, j) => {
        if (a.key === activity.key) {
          index = j;
          value = copy(a);
        }
      });
      if (value === null) {
        return;
      }

      value.deletedAt = DateTime.local().toISO();
      this.activitiesToCreate.splice(index, 1, value);
    },
    updateActivity(value, index) {
      this.activitiesToCreate.splice(index, 1, value);
    },
    addGoalActivity() {
      if (this.nextGoal === null) {
        return;
      }

      const nextPropertyStatus = this.calculateInitialPropertyStatus(this.nextGoal);

      this.activitiesToCreate.push({
        uid: undefined,
        goal: this.nextGoal,
        current: current(this.nextGoal, this.nextGoal.cachedCurrent),
        message: null,
        properties: nextPropertyStatus,
        customCreatedAt: DateTime.local().toISO(),
      });
    },
    submit() {
      this.content = trimEmptyLines(this.content);
      if (this.update === null) {
        this.create();
        return;
      }

      if (this.$refs.editor !== undefined) {
        this.$refs.editor.setContent(this.content);
      }
      this.save();
    },
    create() {
      if ((this.content === null || Object.keys(this.content).length === 0) && this.activitiesToCreate.length === 0) {
        return;
      }

      this.createUpdate({
        message: this.content,
        goal: { uid: this.goal.uid },
        goalActivities: this.activitiesToCreate,
      }).then(() => {
        this.key += 1;
        this.content = null;
        this.activitiesToCreate = [];
        this.$emit('created');
        this.focused = false;
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    save() {
      this.updateUpdate({
        ...this.update,
        message: this.content,
        goalActivities: this.activitiesToCreate,
      }).then(() => {
        this.fetchUpdatesForGoal(this.goal.uid);
        this.$emit('saved');
        this.disabled = true;
        this.focused = false;
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    editUpdate() {
      this.showMenu = false;
      this.disabled = false;
      if (this.allowComments) {
        this.$refs.editor.focus();
      }
    },

    deleteUpdateEntity() {
      this.deleteUpdate(this.update).then(() => {
        this.$emit('deleted');
        this.fetchUpdatesForGoal(this.goal.uid);
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });
    },
    onDeleteUpdate() {
      this.showMenu = false;
      const deleteMethod = this.deleteUpdateEntity;

      this.$confirm({
        title: this.$t('goalUpdateEditor.deleteUpdatePrompt'),
        okText: this.$t('general.yesDelete'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          deleteMethod();
        },
      });
    },
  },
  watch: {
    update: {
      handler(newVal, oldVal) {
        if (isEqual(newVal, oldVal)) {
          return;
        }

        this.activitiesToCreate = copy(newVal.goalActivities);
      },
      deep: true,
    },
  },
  mounted() {
    this.disabled = this.initialDisabled;

    if (this.update !== null) {
      this.activitiesToCreate = copy(this.update.goalActivities);
    }

    if (this.autoAddGoalActivity) {
      this.addGoalActivity();
    }
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/box-shadow";

  .goal-update-editor {
    ._editor {
      ._inner {
        padding: .4rem .8rem 0 .8rem;
        margin: -.4rem -.8rem 0;
        border: 1px solid transparent;
        border-radius: $border-radius-sm;

        ._activity {
          margin-top: .8rem;

          &:not(:last-of-type) {
            margin-bottom: .8rem;
          }
        }
      }

      ._submit {
        margin-top: .6rem;

        ._submit-btn {
          display: flex;
          justify-content: flex-end;
        }

        ._bottom-actions {
          ._reactions {
            margin-bottom: .4rem;
          }
        }
      }
    }

    ._btn-group {
      background-color: white;

      ._inner {
        display: flex;
      }
    }

    ._tooltip {
      position: relative;
      display: inline-flex;
      width: 3.6rem;
      height: 2rem;

      ._tooltip-inner {
        position: absolute;
        top: .9rem;
        left: .6rem;

        ._icon-info {
          cursor: help;
        }
      }
    }
  }
</style>
