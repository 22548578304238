<template>
  <m-tag
    class="progress-tag"
    has-default-slot
    :custom-color="{ color: $colors.blue.darken3, backgroundColor: $colors.blue.lighten4 }"
    small
  >
    <simple-pie-chart
      :progress="progress"
      :color="optionColor.blue"
      size="12px"
      border-width="3px"
      :style="{ marginRight: '.6rem' }"
    />
    <div class="_text">
      {{ progress }}%
      <template v-if="showText">
        {{ $t('progressTag.completed') }}
      </template>
    </div>
  </m-tag>
</template>

<script>
import SimplePieChart from '@/components/SimplePieChart.vue';
import { optionColor } from 'shared/constants.json';

export default {
  name: 'ProgressTag',
  props: {
    progress: {
      type: Number,
      required: true,
    },
    showText: {
      type: Boolean,
      default: false,
    },
  },
  components: { SimplePieChart },
  data() {
    return { optionColor };
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .progress-tag {
    border-radius: $tag-border-radius;

    ._text {
      font-size: $font-size-2;
      font-weight: $font-weight-semibold;
      color: map_get($blue, 'darken-3');
    }
  }
</style>
