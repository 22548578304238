<template>
  <div class="publish-embed-code">
    <m-input
      v-model:value="embedCode"
      class="_input"
      disabled
      full-width
      :style="{borderTopRightRadius: 0, borderBottomRightRadius: 0, cursor: 'default', borderRight: 0 }"
    />
    <m-btn
      v-clipboard="embedCode"
      v-clipboard:error="onError"
      v-clipboard:success="close"
      class="_btn"
      block
      :style="{borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }"
    >
      {{ $t('publishButton.copyEmbedCode') }}
    </m-btn>
  </div>
</template>

<script setup>
import useSnackBar from '@/composables/snackbar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const snackBarSvc = useSnackBar();
const { t } = useI18n();

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['close']);

const close = () => {
  snackBarSvc.success(t('analyticAccess.copySuccess'));
  emits('close');
};

const onError = () => {
  t({ color: 'error', message: this.$t('error.duringCopying') });
};

const embedCode = computed(() => `<iframe
  src="${props.url}"
  width="1280"
  height="1024"
  frameborder="0"
  allowfullscreen
  loading="lazy">
</iframe>`);

</script>

<style scoped lang="scss" type="text/scss">
  .publish-embed-code {
      display: flex;
      width: 100%;
      margin-top: .4rem;

      ._input {
        flex: 1 1 25rem;
      }

      ._btn {
        flex: 0 0 10rem;
      }
    }
</style>
