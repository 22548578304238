import { computed, ref } from 'vue';

export default function useSimpleEditor(debounce, emit, initialValue, window) {
  const getSelection = (selection) => {
    const range = selection.getRangeAt(0);
    return {
      startOffset: range.startOffset,
      endOffset: range.endOffset,
    };
  };
  const elementRef = ref(null);
  const history = ref([{
    value: initialValue,
    selection: { startOffset: initialValue.length, endOffset: initialValue.length },
  }]);
  const historyPointer = ref(0);

  const setRef = (reference) => {
    elementRef.value = reference;
  };

  const currentSelection = ref(null);

  const updateHistory = (commitNow = false) => {
    const update = () => {
      historyPointer.value += 1;
      history.value.push({ value: elementRef.value.innerText, selection: currentSelection.value });
      if (historyPointer.value + 1 < history.value.length) {
        history.value = history.value.slice(0, historyPointer.value + 1);
      }
    };
    if (commitNow) {
      update();
      return;
    }
    debounce(update, 250);
  };

  const applySelectionFromStartAndEnd = (start, end, selection) => {
    // editor is empty
    if (elementRef.value.firstChild === null) {
      return;
    }

    const range = window.document.createRange();
    range.setStart(elementRef.value.firstChild, start);
    range.setEnd(elementRef.value.firstChild, end);
    applySelection(range, selection);
  };

  const applySelection = (range, selection) => {
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handlePaste = (event) => {
    const text = event.clipboardData.getData('text/plain').trim().replace('\n', ' ');
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    if (range !== null && range.startOffset !== range.endOffset) {
      selection.getRangeAt(0).deleteContents();
    }
    const node = window.document.createTextNode(text);
    selection.getRangeAt(0).insertNode(node);
    const newRange = window.document.createRange();
    newRange.setStartAfter(node);
    applySelection(newRange, selection);
    event.preventDefault();
    updateHistory();
    emit('update:value', elementRef.value.innerText);
  };

  const handleBlur = () => {
    emit('blur');
  };

  const handleInput = () => {
    currentSelection.value = getSelection(window.getSelection());
    updateHistory();
    emit('update:value', elementRef.value.innerText.replace(/\n/g, ''));
  };

  const isMacComputer = computed(() => window.navigator.userAgent.includes('Macintosh'));

  const isControlKey = (event) => {
    if (isMacComputer.value) {
      return event.metaKey;
    }

    return event.ctrlKey;
  };

  const handleKeyDown = (event) => {
    switch (true) {
      case event.key === 'ArrowDown':
        emit('arrow-down', event);
        break;
      case event.key === 'Delete' || event.key === 'Backspace': {
        const selection = getSelection(window.getSelection());
        if (Math.abs(selection.endOffset - selection.startOffset) > 0) {
          currentSelection.value = selection;
          updateHistory(true);
        }
        break;
      }
      case event.key === 'Enter' && event.shiftKey:
        event.preventDefault();
        event.stopPropagation();
        emit('shift-enter');
        break;
      case event.key === 'Enter' && isControlKey(event):
        event.preventDefault();
        event.stopPropagation();
        emit('ctrl-enter');
        break;
      case event.key === 'Enter':
        event.preventDefault();
        event.stopPropagation();
        emit('enter');
        break;
      case event.key === 'Z' && isControlKey(event) && event.shiftKey: {
        event.preventDefault();
        if (historyPointer.value + 2 <= history.value.length) {
          historyPointer.value += 1;
          const historyEntry = history.value[historyPointer.value];
          elementRef.value.innerText = historyEntry.value;
          applySelectionFromStartAndEnd(historyEntry.selection.startOffset, historyEntry.selection.endOffset, window.getSelection());
          emit('update:value', elementRef.value.innerText);
        }
        break;
      }
      case event.key === 'z' && isControlKey(event): {
        event.preventDefault();
        if (historyPointer.value === 0) {
          return;
        }
        historyPointer.value -= 1;
        const historyEntry = history.value[historyPointer.value];
        elementRef.value.innerText = historyEntry.value;
        applySelectionFromStartAndEnd(historyEntry.selection.startOffset, historyEntry.selection.endOffset, window.getSelection());
        emit('update:value', elementRef.value.innerText);
        break;
      }
      case event.key === 'Escape':
        event.preventDefault();
        event.stopPropagation();
        emit('escape');
        break;
      case isControlKey(event) && (event.key === 'b' || event.key === 'i' || event.key === 'u'):
        event.preventDefault();
        event.stopPropagation();
        break;
      default:
    }
  };

  const setCaretToEnd = () => {
    const node = elementRef.value.firstChild;
    if (node === null) {
      return;
    }

    const sel = window.getSelection();
    if (sel.rangeCount === 0) {
      return;
    }
    ['Start', 'End'].forEach((pos) => sel.getRangeAt(0)[`set${pos}`](node, node.length));
  };

  const select = () => {
    const node = elementRef.value.firstChild;
    if (node === null) {
      return;
    }

    const sel = window.getSelection();
    if (sel.rangeCount === 0) {
      return;
    }
    sel.getRangeAt(0).setStart(node, 0);
    sel.getRangeAt(0).setEnd(node, node.length);
  };

  return {
    updateHistory,
    handlePaste,
    handleBlur,
    handleInput,
    handleKeyDown,
    history,
    historyPointer,
    setCaretToEnd,
    select,
    setRef,
  };
}
