<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    preserveAspectRatio="xMidYMid meet"
  >
    <rect
      x="12.1152"
      y="6.01599"
      width="6.79602"
      height="11.968"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="3.25"
      y="4.3678"
      width="17.5"
      height="15.2644"
      rx="1.25"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default { name: 'SidePeek' };
</script>
