<template>
  <div
    class="tree-item"
    :style="style"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <goal-card
      class="_card"
      :entity="entity"
      :identifier="index"
      :data-id="entity.uid"
      :data-identifier="index"
      :props="props"
      title-layout="column"
      :component-key="entity.uid"
      :selected="selected"
      :can-create="canCreate"
      :read-only="readOnly"
      prevent-scroll-on-focus
      hide-hover
      show-expand-buttons
      :wrap="wrapCells"
      :max-body-height="maxBodyHeight"
      :update-property="updateProperty"
      :dragging-over-bottom="draggingOverBottom"
      :dragging-over-top="draggingOverTop"
      :dragging-over-right="draggingOverRight"
      :dragging-over-left="draggingOverLeft"
      @context-menu-clicked="$emit('context-menu-click', $event)"
      @create-beneath="({ option }) => handleCreate({ option }, 'bottom')"
      @select="$emit('select', $event)"
      @open="$emit('open', $event)"
      @progress-clicked="showUpdateProgressModal = true"
      @expand-all="$emit('expand-all', $event)"
      @collapse-all="$emit('collapse-all', $event)"
    >
      <template
        #footer
      >
        <m-content
          v-if="hasChildren || countComments > 0 || countUpdates > 0 || Object.keys(errorMessages).length > 0"
          :padding-bottom="9"
          :padding-x="9"
          :padding-top="6"
          class="_summary"
        >
          <m-tooltip
            v-if="countComments > 0"
            placement="bottomCenter"
          >
            <div class="_summary-item">
              <m-icon
                type="message"
                :color="$colors.grey.lighten1"
                size="16"
              />
              <span class="_text">{{ countComments < summaryLimit ? countComments : `${summaryLimit - 1}+` }}</span>
            </div>
            <template #title>
              <div style="color: white;">
                {{ $t('goalCard.summary.comments', countComments, {count: countComments}) }}
              </div>
              <div
                v-if="lastComment !== undefined"
                :style="{ color: tooltipGrey }"
              >
                {{ $t('goalCard.summary.lastCommentDate', {date: formatDate(lastComment.createdAt)}) }}
              </div>
            </template>
          </m-tooltip>
          <m-tooltip
            v-if="countUpdates > 0"
            placement="bottomCenter"
          >
            <div class="_summary-item">
              <m-icon
                type="history"
                :color="$colors.grey.lighten1"
                size="16"
              />
              <span class="_text">{{ countUpdates < summaryLimit ? countUpdates : `${summaryLimit - 1}+` }}</span>
            </div>
            <template #title>
              <div style="color: white;">
                {{ $t('goalCard.summary.updates', countUpdates, {count: countUpdates}) }}
              </div>
              <div
                v-if="lastUpdate !== undefined"
                :style="{ color: tooltipGrey }"
              >
                {{ $t('goalCard.summary.lastUpdateDate', {date: formatDate(lastUpdate.createdAt)}) }}
              </div>
            </template>
          </m-tooltip>
          <error-indicator-dropdown
            class="_error-btn"
            :can-edit="!readOnly"
            :error-messages="errorMessages"
            :has-errors="Object.keys(errorMessages).length > 0"
            :goal="entity"
            :update-property="updateProperty"
            :style="{ fontSize: $fontSizes[2] }"
            @select="$emit('select', { goal: entity })"
          />
          <m-btn
            v-if="hasChildren"
            data-cy="expand-btn"
            class="_btn"
            small
            light
            :icon="expanded ? 'up' : 'down'"
            icon-size="14"
            :loading="loading"
            :icon-color="$colors.grey.lighten1"
            @mouseenter="scheduleExpand"
            @mouseleave="cancelExpand"
            @click.stop="$emit('toggle-expand', { index })"
          >
            {{ countChildren < childrenLimit ? countChildren : `${childrenLimit - 1}+` }}
          </m-btn>
        </m-content>
      </template>
    </goal-card>
    <template
      v-if="canCreate"
    >
      <goal-create-dropdown
        v-for="position in ['top', 'right', 'bottom', 'left']"
        :key="position"
        :class="['_add', `-${position}`, hover ? '-hover' : '']"
        :insert-position="position"
        :goal="entity"
        :index="index"
        @create-beneath="handleCreate($event, position)"
        @click.stop
      >
        <template
          #default="goalCreateDropdownProps"
        >
          <m-tooltip :disabled="!goalCreateDropdownProps.disabled">
            <m-btn
              xs
              icon="plus"
              fab
              light
              :disabled="goalCreateDropdownProps.disabled"
              :loading="createLoading && createLoadingPosition === position"
            />
            <template #title>
              {{ $t('treeItem.noTypeAllowed') }}
            </template>
          </m-tooltip>
        </template>
      </goal-create-dropdown>
    </template>
    <m-dialog
      v-if="showUpdateProgressModal"
      v-model:value="showUpdateProgressModal"
      hide-footer
      :fullscreen-on-mobile="false"
      :body-style="{ overflow: 'visible' }"
      :card-style="{ overflow: 'visible' }"
      :max-width="$modalSizes.lg"
    >
      <goal-update-editor
        ref="goalUpdateEditor"
        allow-goal-activity
        :goal="entity"
        :goal-children="[entity]"
        auto-add-goal-activity
        auto-focus
        @created="showUpdateProgressModal = false"
      />
    </m-dialog>
  </div>
</template>

<script>
import ErrorIndicatorDropdown from '@/components/goal/ErrorIndicatorDropdown.vue';
import GoalCard from '@/components/goal/GoalCard.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import GoalUpdateEditor from '@/components/goal/GoalUpdateEditor.vue';
import useGoals from '@/composables/goal/goals';
import { DateTime } from 'luxon';
import { rgbaToHex } from 'shared/lib/color';

export default {
  name: 'TreeItem',
  props: {
    hasChildren: {
      type: Boolean,
      default: false,
    },
    index: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    props: {
      type: Array,
      default: () => [],
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      required: true,
    },
    maxBodyHeight: {
      type: Number,
      required: true,
    },
    wrapCells: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    draggingOverTop: {
      type: Boolean,
      default: false,
    },
    draggingOverBottom: {
      type: Boolean,
      default: false,
    },
    draggingOverRight: {
      type: Boolean,
      default: false,
    },
    draggingOverLeft: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updateProperty: {
      type: Function,
      default: () => {
      },
    },
    cardPadding: {
      type: Number,
      default: 0,
    },
    createLoading: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Function,
      default: () => ({}),
    },
  },
  setup() {
    const { selectSingle } = useGoals();
    return { selectSingle };
  },
  components: { GoalUpdateEditor, GoalCard, GoalCreateDropdown, ErrorIndicatorDropdown },
  emits: ['context-menu-click', 'open', 'select', 'toggle-expand', 'create', 'expand-all', 'collapse-all', 'schedule-expand', 'cancel-expand'],
  data() {
    return {
      summaryLimit: 1000,
      childrenLimit: 100,
      createLoadingPosition: '',
      hover: false,
      showUpdateProgressModal: false,
    };
  },
  computed: {
    entity() {
      return this.selectSingle(this.entityId);
    },
    errorMessages() {
      if (!this.showErrors) {
        return {};
      }
      return this.validate({ toValidate: this.entity, selectRulesFrom: this.entity });
    },
    style() {
      return {
        width: `${this.width}px`,
        position: 'absolute',
        top: `${this.y}px`,
        left: `${this.x}px`,
        padding: `${this.cardPadding}px`,
      };
    },
    tooltipGrey() {
      return rgbaToHex(this.$colors.grey.lighten2);
    },
    countComments() {
      return this.entity.commentCount;
    },
    lastComment() {
      return this.countComments > 0 ? this.entity.lastComment[0] : undefined;
    },
    countUpdates() {
      return this.entity.goalActivityCount;
    },
    lastUpdate() {
      return this.countUpdates > 0 ? this.entity.lastGoalActivity[0] : undefined;
    },
    countChildren() {
      return this.entity.children.length;
    },
  },
  methods: {
    scheduleExpand() {
      this.$emit('schedule-expand', this.index);
    },
    cancelExpand() {
      this.$emit('cancel-expand', this.index);
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
    },
    handleCreate({ option }, position) {
      this.createLoadingPosition = position;
      this.$emit('create', { index: this.index, entity: this.entity, option, position });
    },
  },
  watch: {
    createLoading(val) {
      if (val) {
        return;
      }
      this.createLoadingPosition = '';
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
.tree-item {
  transition: top $goal-tree-transition-length, left $goal-tree-transition-length;
  position: relative;

  ._add {
    display: none;
    position: absolute;
    background-color: white;
    border-radius: $btn-border-radius;

    &.-hover {
      display: block;
    }

    &.-top {
      left: 50%;
      top: 0;
      transform: translate(-50%, 0%);
    }

    &.-right {
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);
    }

    &.-bottom {
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0%);
    }

    &.-left {
      left: 0;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
}

._card {
  ._summary {
    display: flex;
    align-items: center;

    ._summary-item {
      display: flex;
      align-items: center;
      color: $font-color-secondary;
      margin-right: .4rem;

      ._text {
        padding: 0 .4rem;
        font-size: $font-size-2;
        font-weight: $font-weight-medium;
      }
    }

    ._btn {
      margin-left: auto;
      font-size: $font-size-2;
      pointer-events: auto;

      ._icon {
        padding-right: 0.4rem;
      }
    }
  }
}
</style>
