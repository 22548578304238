<template>
  <m-alert
    class="data-source-query-error"
    type="error"
  >
    <div class="_content">
      <div>
        {{ errorMessage }}
      </div>
      <div v-if="context != null">
        <m-dropdown
          v-model:value="expanded"
          placement="onTopLeft"
          match-trigger-width
          block
        >
          <div class="_trigger">
            <m-btn
              class="_btn"
              hide-border
              xs
            >
              <m-icon :type="expandIcon" />
              <div class="_text">
                {{ $t('dataSourceQueryError.details') }}
              </div>
            </m-btn>
            <m-btn
              v-clipboard="formattedContext"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="logCatch(onCopyError)"
              icon="copy"
              fab
              xs
              hide-border
              super-light
              @click.stop.prevent
            />
          </div>
          <template #overlay>
            <m-card
              class="data-source-query-error-overlay"
              padding-xs
            >
              <div class="_trigger">
                <div
                  class="_btn"
                  @click="toggleExpanded"
                >
                  <m-icon :type="expandIcon" />
                  <div class="_text">
                    {{ $t('dataSourceQueryError.details') }}
                  </div>
                </div>
                <m-btn
                  v-clipboard="formattedContext"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="logCatch(onCopyError)"
                  icon="copy"
                  fab
                  xs
                  hide-border
                  super-light
                  @click.stop.prevent
                />
              </div>
              <div class="_content">
                <component
                  :is="contextTag"
                  class="_text"
                >
                  {{ formattedContext }}
                </component>
              </div>
            </m-card>
          </template>
        </m-dropdown>
      </div>
    </div>
  </m-alert>
</template>
<script setup>
import camelCase from 'lodash-es/camelCase';
import useSnackBar from '@/composables/snackbar';
import { computed, ref } from 'vue';
import { logCatch } from '@/lib/logger/logger';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  dataSource: {
    type: Object,
    required: true,
  },
  error: {
    type: Object,
    required: true,
  },
});

const errorMessage = computed(() => (props.error === null ? '' : t(
  `dataSources.errors.${props.error.source}.${props.error.type}`,
  {
    source: t(`dataSources.${camelCase(props.dataSource.type)}`),
    sourceName: props.dataSource.name,
  },
)));
const context = computed(() => props.error.context);
const contextTag = ref('div');
const formatContext = () => {
  try {
    contextTag.value = 'pre';
    return JSON.stringify(JSON.parse(context.value), null, 2);
  } catch {
    contextTag.value = 'div';
    return context.value;
  }
};
const formattedContext = computed(() => formatContext());

const expanded = ref(false);
const expandIcon = computed(() => (expanded.value ? 'down' : 'right'));
const toggleExpanded = () => {
  expanded.value = !expanded.value;
};

const snackbar = useSnackBar();
const onCopySuccess = () => {
  snackbar.success(t('success.copied'));
};
const onCopyError = () => {
  snackbar.error(t('error.duringCopying'));
};
</script>

<style scoped lang="scss" type="text/scss">
._trigger {
  display: flex;
  gap: .8rem;

  ._btn {
    display: flex;
    gap: .4rem;
    align-items: center;
  }
}

.data-source-query-error {
  ._content{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.data-source-query-error-overlay {
  top: -$xs-container-padding-y;
  left: -$xs-container-padding-y;

  ._content{
    border-radius: $input-field-border-radius;
    margin-top: .4rem;
    background: map_get($grey, 'lighten-4');
    padding: $xxs-container-padding-y;

    ._text {
      white-space: pre-wrap;
      word-wrap: break-word;
      margin-bottom: 0;
    }
  }
}
</style>
