import useAppFeedbackRepo from '@/composables/app-feedback/app-feedback-repo';
import useDataSync from '@/composables/subscription/data-sync';
import useFeedEntryRepo from '@/composables/feed-entry/feed-entry-repo';
import useGoalActivityDataSync from '@/composables/goal-activity/goal-activity-data-sync';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGridPageDataSync from '@/composables/grid-page/grid-page-data-sync';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useRepo from '@/nebula/repo';
import useSubscription from '@/composables/subscription/subscription';
import useUpdateDataSync from '@/composables/updates/update-data-sync';
import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import {
  accessGroup as accessGroupConfig,
  accessPolicy as accessPolicyConfig,
  accessPolicyScope as accessPolicyScopeConfig,
  account as accountConfig,
  accountSettings as accountSettingsConfig,
  appFeedback as appFeedbackConfig,
  appIntegration as appIntegrationConfig,
  asanaQuery as asanaQueryConfig,
  comment as commentConfig,
  companyInfo as companyInfoConfig,
  customerContract as customerContractConfig,
  dataSource as dataSourceConfig,
  favorite as favoriteConfig,
  feedEntry as feedEntryConfig,
  goalActivity as goalActivityConfig,
  goal as goalConfig,
  goalCycle as goalCycleConfig,
  goalSettings as goalSettingsConfig,
  goalSubscription as goalSubscriptionConfig,
  gridPage as gridPageConfig,
  gridPageRow as gridPageRowConfig,
  gridPageTile as gridPageTileConfig,
  gridPageTileSingleGoal as gridPageTileSingleGoalConfig,
  gridPageTileText as gridPageTileTextConfig,
  hubspotQuery as hubspotQueryConfig,
  invitation as invitationConfig,
  jiraQuery as jiraQueryConfig,
  msPlannerQuery as msPlannerQueryConfig,
  msPowerBiQuery as msPowerBiQueryConfig,
  notification as notificationConfig,
  notificationSetting as notificationSettingConfig,
  oauthCodeGrantClient as oauthCodeGrantClientConfig,
  personalAppSettings as personalAppSettingsConfig,
  pin as pinConfig,
  planning as planningConfig,
  printable as printableConfig,
  property as propertyConfig,
  propertyOption as propertyOptionConfig,
  propertySettings as propertySettingsConfig,
  propertyValue as propertyValueConfig,
  reaction as reactionConfig,
  report as reportConfig,
  resourceSettings as resourceSettingsConfig,
  salesforceQuery as salesforceQueryConfig,
  saml as samlConfig,
  savedView as savedViewConfig,
  selectedView as selectedViewConfig,
  space as spaceConfig,
  spreadsheetCell as spreadsheetCellConfig,
  storageObject as storageObjectConfig,
  update as updateConfig,
  updateTemplate as updateTemplateConfig,
  user as userConfig,
  userProvisioning as userProvisioningConfig,
  userProvisioningMapping as userProvisioningMappingConfig,
  userScope as userScopeConfig,
  userScopeTree as userScopeTreeConfig,
  viewCollection as viewCollectionConfig,
} from 'shared/api/query/configs.json';
import { isEmpty } from 'shared/lib/object/object';
import { ref } from 'vue';
import { routeName } from 'shared/constants.json';
import { showOnboarding } from '@/lib/onboarding/onboarding';
import { useRoute, useRouter } from 'vue-router';

export default function useLogin(pageVisitsService) {
  const route = useRoute();
  const router = useRouter();

  const loading = ref(false);

  const { loggedInUser, getLoggedInUser } = useLoggedInUser();
  const { loggedInUserAccount } = useLoggedInUserAccount();

  const debounceDelay = 1000; // debounce delay for querying data in bulk from collaboration

  const findLastPage = () => {
    const page = pageVisitsService.getLastPageVisit();
    if (page === null) {
      return { ok: false };
    }

    if (page.routeName === routeName.logout) {
      return { ok: false };
    }

    if (!routeName.all.includes(page.routeName)) {
      return { ok: false };
    }

    return { ok: true, page };
  };

  const loggedInUserResponseHandler = (async () => {
    EventBus.$emit(EVENTS.SESSION.LOGIN, { user: loggedInUser.value, account: loggedInUserAccount.value });

    pageVisitsService.fetchPageVisits({ user: loggedInUser.value });

    if (!isEmpty(route.meta?.title)) {
      document.title = `${route.meta.title()} | Mooncamp`;
    }

    if (showOnboarding({ user: loggedInUser.value, account: loggedInUserAccount.value })) {
      await router.replace({ name: routeName.onboarding });
      return;
    }

    if (route.name !== routeName.home || Object.keys(route.query).length > 0) {
      return;
    }

    const { page, ok } = findLastPage();
    if (ok) {
      const params = page.params;
      const query = page.query;
      await router.replace({ name: page.routeName, params, query });
    }
  });

  const goalSettingsSvc = useGoalSettings();

  const repos = {};
  const subscribers = {};
  const configs = [
    { model: favoriteConfig.model, fetch: true, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: goalCycleConfig.model, fetch: true, subscribe: true },
    { model: propertyOptionConfig.model, fetch: true, subscribe: true },
    { model: propertyConfig.model, fetch: true, subscribe: true },
    { model: propertySettingsConfig.model, fetch: true, subscribe: true },
    { model: spaceConfig.model, fetch: true, subscribe: true },
    { model: userConfig.model, fetch: true, subscribe: true },
    { model: invitationConfig.model, fetch: false, subscribe: true },
    { model: propertyValueConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: personalAppSettingsConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: pinConfig.model, fetch: true, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: notificationSettingConfig.model, fetch: false, subscribe: true },
    { model: storageObjectConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: accessGroupConfig.model, fetch: true, subscribe: true },
    { model: accessPolicyConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: accessPolicyScopeConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: userScopeTreeConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: userScopeConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: accountConfig.model, fetch: true, subscribe: true },
    { model: accountSettingsConfig.model, fetch: false, subscribe: true },
    { model: goalSettingsConfig.model, fetch: false, subscribe: true },
    { model: resourceSettingsConfig.model, fetch: false, subscribe: true },
    { model: appIntegrationConfig.model, fetch: false, subscribe: true },
    { model: customerContractConfig.model, fetch: false, subscribe: true },
    { model: companyInfoConfig.model, fetch: false, subscribe: true },
    { model: samlConfig.model, fetch: false, subscribe: true },
    { model: userProvisioningConfig.model, fetch: false, subscribe: true },
    { model: userProvisioningMappingConfig.model, fetch: false, subscribe: true },
    { model: oauthCodeGrantClientConfig.model, fetch: false, subscribe: true },
    { model: commentConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: reactionConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: updateConfig.model, fetch: false, subscribe: true, subscriber: useUpdateDataSync, syncOptions: { ignoreNonExistingData: true } },
    { model: goalActivityConfig.model, fetch: false, subscribe: true, subscriber: useGoalActivityDataSync, syncOptions: { ignoreNonExistingData: true } },
    { model: appFeedbackConfig.model, fetch: true, subscribe: false, repo: useAppFeedbackRepo },
    { model: selectedViewConfig.model, fetch: true, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: savedViewConfig.model, fetch: true, subscribe: true },
    { model: feedEntryConfig.model, fetch: true, subscribe: true, repo: useFeedEntryRepo, syncOptions: { ignoreNonExistingData: true } },
    { model: dataSourceConfig.model, fetch: true, subscribe: true },
    { model: goalConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: gridPageConfig.model, fetch: true, subscribe: true, subscriber: useGridPageDataSync, syncOptions: { ignoreNonExistingData: true } },
    { model: gridPageRowConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: gridPageTileConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: gridPageTileTextConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: gridPageTileSingleGoalConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: goalSubscriptionConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: asanaQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: hubspotQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: jiraQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: salesforceQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: spreadsheetCellConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: msPlannerQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: msPowerBiQueryConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: planningConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: updateTemplateConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: notificationConfig.model, fetch: false, subscribe: true, syncOptions: { ignoreNonExistingData: true } },
    { model: viewCollectionConfig.model, fetch: true, subscribe: true },
    { model: printableConfig.model, fetch: false, subscribe: true },
    { model: reportConfig.model, fetch: true, subscribe: true },
  ];

  configs.forEach((config) => {
    if (config.repo !== undefined) {
      repos[config.model] = config.repo();
    } else {
      repos[config.model] = useRepo(config.model);
    }
    if (config.subscriber !== undefined) {
      subscribers[config.model] = config.subscriber(repos[config.model], { delay: debounceDelay, ...config.syncOptions });
    } else {
      const subscriptionSvc = useSubscription();
      subscribers[config.model] = useDataSync(subscriptionSvc, repos[config.model], { delay: debounceDelay, ...config.syncOptions });
    }
  });

  const initializeUser = () => {
    loading.value = true;
    const promises = [];
    promises.push(getLoggedInUser().then((data) => data).catch(() => null));
    configs.forEach((config) => {
      if (!config.fetch) {
        return;
      }
      promises.push(repos[config.model].getList().catch(() => {}));
    });
    return Promise.all(promises).then(async (responses) => {
      if (responses[0] !== null) {
        await loggedInUserResponseHandler();
        // subscriptions can only start from here
        goalSettingsSvc.setDefaultValueFeatureName();
        configs.forEach((config) => {
          if (!config.subscribe) {
            return;
          }

          subscribers[config.model].subscribe();
        });
      }
      return responses[0];
    }).finally(() => {
      loading.value = false;
    });
  };

  return { loading, initializeUser };
}
