<template>
  <m-item-wrapper>
    <div class="space-selector-item">
      <div
        :id="`selector-item-${space.index}`"
        :class="['_content', space.children.length > 0 && multiple ? '-can-select-all' : '', !space.match ? '-light' : '']"
        :style="{ backgroundColor: focusIndex === space.index ? $colors.grey.lighten5 : '' }"
        @click="select(space)"
        @mouseover="$emit('set-focus', space.index)"
      >
        <div
          class="_expandable"
          :style="styleLeft"
        >
          <m-btn
            v-if="space.hasChildren"
            :icon="space.expanded ? 'down' : 'right'"
            fab
            xs
            hide-border
            :icon-color="$colors.grey.base"
            @click.stop="$emit('toggle-expand', { expanded: !space.expanded, uid: space.uid })"
          />
          <m-btn
            v-else
            icon="dot"
            fab
            xs
            light
            hide-border
            :button-style="{ backgroundColor: 'transparent' }"
          />
        </div>
        <div class="_icon">
          <m-avatar
            v-if="isEmptyIcon(icon)"
            :size="18"
            :text="space.title"
            :amount-chars="1"
            :color="getColor(space.color)"
          />
          <m-icon-display
            v-else
            :icon="icon"
          />
        </div>
        <div class="_title">
          {{ space.title }}
        </div>
        <div
          v-if="space.children.length > 0 && multiple"
          :class="['_btn', isSelected ? '-selected': '']"
        >
          <m-btn
            xs
            @click.stop="handleClick"
          >
            <template v-if="allSelected">
              {{ $t('spaceSelectorItem.deselectAll') }}
            </template>
            <template v-else>
              {{ $t('spaceSelectorItem.selectAll') }}
            </template>
          </m-btn>
        </div>
        <div
          v-if="isSelected"
          class="_selected"
        >
          <m-icon
            type="check"
            size="14"
          />
        </div>
      </div>
    </div>
  </m-item-wrapper>
</template>

<script>

import useSpace from '@/composables/space/space';
import { getColor } from 'shared/lib/color-map';
import { isEmptyIcon } from 'shared/lib/icon';
import { toRef } from 'vue';

export default {
  name: 'SpaceSelectorItem',
  props: {
    space: {
      type: Object,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    indentation: {
      type: Number,
      required: true,
    },
    focusIndex: {
      type: Number,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['set-focus', 'toggle-expand', 'select', 'deselect-all', 'select-all'],
  setup(props) {
    const spaceSvc = useSpace(toRef(props, 'space'));
    const { icon } = spaceSvc;
    return { icon };
  },
  data() {
    return { hover: false };
  },
  computed: {
    allSelected() {
      if (!this.isSelected) {
        return false;
      }

      if (this.space.children.length === 0) {
        return true;
      }

      return this.space.children.reduce(this.allSelectedReducer, true);
    },
    isSelected() {
      return this.checkSelected(this.space);
    },
    styleLeft() {
      return { marginLeft: `${this.indentation * 2}rem` };
    },
  },
  methods: {
    getColor,
    isEmptyIcon,
    handleClick() {
      if (this.allSelected) {
        this.deselectAll(this.space);
        return;
      }

      this.selectAll(this.space);
    },
    allSelectedReducer(res, next) {
      if (!this.checkSelected(next)) {
        return false;
      }

      if (next.children.length === 0) {
        return res;
      }

      return next.children.reduce(this.allSelectedReducer, true);
    },
    checkSelected(space) {
      return this.selected.filter((s) => s.uid === space.uid).length > 0;
    },
    select(space) {
      this.$emit('select', space);
    },
    deselectAll(space) {
      this.$emit('deselect-all', space);
    },
    selectAll(space) {
      this.$emit('select-all', space);
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .space-selector-item {
    ._content {
      width: 100%;
      display: flex;
      align-items: center;
      height: 3.2rem;
      padding: 0 1.1rem;
      cursor: pointer;
      position: relative;
      border-radius: $btn-border-radius;

      &.-light {
        opacity: .4;
      }

      ._expandable {
        margin-right: .6rem;
      }

      ._icon {
        margin-right: .6rem;
      }

      ._title {
        margin-right: .6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ._btn {
        display: none;
        flex: 0 0 6rem;
        margin-right: .6rem;
        position: absolute;
        top: .5rem;
        right: 0;
        background: white;
        border-radius: $border-radius-sm;
      }

      ._selected {
        margin-left: auto;
      }

      &.-focused {
        background-color: $hover-color;
      }

      &:hover {
        ._btn {
          display: flex;

          &.-selected {
              right: 2.4rem;
          }
        }
      }
    }
  }
</style>
