<template>
  <page-layout
    class="user-list"
    hide-top-bar
    :loading="loading"
  >
    <scroll-container>
      <m-dialog
        v-model:value="showImportModal"
        :title="$t('userList.importModalHeader')"
        hide-footer
        no-padding
      >
        <m-content
          padding
          class="_csv-import-content"
        >
          <i18n-t
            keypath="userList.importModalText"
            class="_terms"
            tag="div"
          >
            <template #link>
              <m-link
                :href="$t('userList.link')"
                underline-on-hover
              >{{ $t('userList.here') }}
              </m-link>
            </template>
          </i18n-t>
          <input
            ref="fileinput"
            type="file"
            :style="{display: 'none'}"
            @change="importFileChange"
          >
          <div class="_select-button">
            <m-btn
              icon="upload"
              @click="selectCSV"
            >
              {{ $t('userList.importModalFileSelect') }}
            </m-btn>
          </div>
          <template v-if="csvFile !== null">
            <div
              :class="['_selected-file', !csvValid ? '-error' : null]"
            >
              <m-icon
                class="_icon"
                type="paper-clip"
              />
              <div>{{ csvFile.name }}</div>
            </div>
            <div
              v-if="!csvValid"
              class="_errors"
            >
              <span v-if="importValidationError.errors.length === 1">
                {{
                  $t(`importError.${importValidationError.errors[0].failure}`)
                }}: {{ importValidationError.errors[0].value }}
              </span>
              <ul v-else>
                <li
                  v-for="err in importValidationError.errors"
                  :key="`${err.failure}.${err.value}`"
                >
                  {{ $t(`importError.${err.failure}`) }}: {{ err.value }}
                </li>
              </ul>
            </div>
          </template>
        </m-content>
        <m-default-form-actions
          :clickable="csvValid"
          :loading="csvImportLoading"
          :submit-text="$t('userList.importSubmit')"
          @cancel="showImportModal = false"
          @submit="importCSV"
        />
      </m-dialog>
      <m-content
        padding-x="layout"
        class="_header"
      >
        <page-header
          :title="$t('userList.title', { total: users.length })"
          heading="h3"
          underlined
          boxed
          no-padding-x
        />
      </m-content>
      <m-content
        padding-x="layout"
        boxed
        class="_table-header"
      >
        <m-alert
          v-if="showDeleteDemoUserBox"
          class="_alert-box"
          type="warning"
          hide-icon
        >
          <div class="_text">
            {{ $t("userList.removeDemoUsers.text") }}
          </div>
          <m-btn
            color="secondary"
            @click="deleteDemoUsers"
          >
            {{ $t('userList.removeDemoUsers.button') }}
          </m-btn>
        </m-alert>
        <m-alert
          v-if="notActiveUsers.length > 1"
          class="_bulk-invitation-box _alert-box"
          type="warning"
          hide-icon
        >
          <i18n-t
            tag="div"
            class="_text"
            keypath="userList.bulkInvitationBox.text"
          >
            <template #users>
              <span class="_text-bold">
                {{ $t('userList.bulkInvitationBox.userCount', { userCount: notActiveUsers.length }) }}
              </span>
            </template>
          </i18n-t>
          <m-btn
            color="secondary"
            @click="confirmBulkInviteUsers(notActiveUsers)"
          >
            {{ $t('userList.bulkInvitationBox.bulkAction', { userCount: notActiveUsers.length }) }}
          </m-btn>
        </m-alert>
        <div :style="{ display: 'flex' }">
          <div class="_left">
            {{ $t('userList.tableTitle') }}
          </div>
          <div
            v-if="canCreate"
            class="_right"
          >
            <list-string-filter
              class="_btn"
              @input="val => search = val"
            />
            <div class="_btn">
              <m-tooltip :disabled="!disableCsvImport">
                <template #title>
                  {{ $t('userList.csvDisabled') }}
                </template>
                <m-btn
                  hide-border
                  light
                  :disabled="disableCsvImport"
                  @click="showImportModal = true"
                >
                  {{ $t('userList.importModalButton') }}
                </m-btn>
              </m-tooltip>
            </div>
            <div class="_btn">
              <m-btn
                hide-border
                light
                href="/api/v1/csv-user-export?lang=de"
              >
                {{ $t('userList.exportUsers') }}
              </m-btn>
            </div>
            <m-btn
              color="primary"
              @click="showAddModal = true"
            >
              {{ $t('userList.add') }}
            </m-btn>
            <m-drawer
              :value="showAddModal"
              :title="$t('userList.add')"
              @close="hide"
            >
              <edit-user-form
                :key="showAddModal"
                v-model:dirty="modalDirty"
                :user="defaultUser"
                :account="account"
                show-cancel
                @cancel="showAddModal = false"
                @create="userAdded"
              />
            </m-drawer>
          </div>
        </div>
      </m-content>
      <m-content
        boxed
        padding-x="layout"
      >
        <user-list-card
          :entities="users"
          :account="account"
          :search="search"
        />
      </m-content>
    </scroll-container>
  </page-layout>
</template>

<script>
import EditUserForm from '@/components/EditUserForm.vue';
import ListStringFilter from '@/components/list/ListStringFilter.vue';
import PageHeader from 'shared/components/PageHeader.vue';
import PageLayout from '@/components/page/PageLayout.vue';
import ScrollContainer from '@/components/page/ScrollContainer.vue';
import UserListCard from '@/components/UserListCard.vue';
import useAccess from '@/composables/access/access';
import useInvitations from '@/composables/user/invitations/invitations';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useUsers from '@/composables/user/users';
import { USER_STATUS_NO_INVITATION } from '@/lib/constants';
import { accessGroupFlag, productPlan } from 'shared/constants.json';
import { doImportCSV, doResetAccount, doValidateCSV } from '@/api';

export default {
  name: 'UserList',
  components: {
    ListStringFilter,
    ScrollContainer,
    PageLayout,
    EditUserForm,
    PageHeader,
    UserListCard,
  },
  setup() {
    const { userHasRight } = useAccess();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { getInvitationStatus, users } = useUsers();
    const { inviteUsers } = useInvitations();
    return {
      userHasRight,
      user: loggedInUser,
      userLang,
      account: loggedInUserAccount,
      users,
      getInvitationStatus,
      inviteUsers,
    };
  },
  data() {
    return {
      showAddModal: false,
      showImportModal: false,
      csvFile: null,
      csvValid: false,
      csvImportLoading: false,
      importValidationError: null,
      loading: false,
      search: '',
      modalDirty: false,
    };
  },
  computed: {
    showDeleteDemoUserBox() {
      return this.userHasRight([accessGroupFlag.accountWriteAccess]) && this.users.filter((u) => u.isDemo).length > 0;
    },
    notActiveUsers() {
      return this.users.filter(this.needsInvitation);
    },
    defaultUser() {
      return {
        uid: 0,
        firstName: '',
        lastName: '',
        email: '',
        language: this.userLang,
        values: [],
        accessGroups: [this.$t('accessGroupForm.defaultGroupTitle')],
      };
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.userWriteAccess]);
    },
    disableCsvImport() {
      return this.account.accountSettings.planId === productPlan.trial;
    },
  },
  methods: {
    hide() {
      const hideFn = () => {
        this.showAddModal = false;
      };

      if (!this.modalDirty) {
        hideFn();
        return;
      }

      const title = this.$t('general.discardEditPrompt');
      const okText = this.$t('general.yesDiscard');
      const cancelText = this.$t('general.close');
      this.$confirm({
        title,
        okText,
        okType: 'danger',
        cancelText,
        maskClosable: true,
        onOk() {
          hideFn();
        },
      });
    },
    selectCSV() {
      this.$refs.fileinput.click();
    },
    importFileChange(event) {
      if (event.target.files.length === 0) {
        return;
      }

      doValidateCSV(event.target.files[0], this.userLang).then((resp) => {
        this.csvFile = event.target.files[0];
        this.$refs.fileinput.value = '';

        if (resp.status !== 200) {
          this.importValidationError = resp.data;
          this.csvValid = false;
          return;
        }

        this.importValidationError = null;
        this.csvValid = true;
      });
    },
    importCSV() {
      this.csvImportLoading = true;
      doImportCSV(this.csvFile, this.userLang).then((resp) => {
        if (resp.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.showImportModal = false;
      }).finally(() => {
        this.csvFile = null;
        this.csvImportLoading = false;
      });
    },
    userAdded() {
      this.showAddModal = false;
    },
    confirmBulkInviteUsers(users) {
      const bulkInviteMethod = () => this.inviteUsers(users).then(() => {
        this.$showSnackbar({ color: 'success', message: this.$t('inviteUsers.success') });
      }).catch(() => {
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      });

      this.$confirm({
        title: this.$t('userList.bulkInvitationDialog.text', { userCount: users.length }),
        okText: this.$t('userList.bulkInvitationDialog.confirmAction', { userCount: users.length }),
        okType: 'primary',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          bulkInviteMethod();
        },
      });
    },
    needsInvitation(user) {
      return this.getInvitationStatus(user) === USER_STATUS_NO_INVITATION;
    },
    deleteDemoUsers() {
      const resetMethod = () => doResetAccount({ demoUsers: true }).then((response) => {
        this.loading = false;
        if (response.status === 401) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.unauthorizedAction') });
          return;
        }
        if (response.status !== 200) {
          this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
          return;
        }
        this.$showSnackbar({ color: 'success', message: this.$t('success.deleted') });
        window.location.reload();
      });
      this.$confirm({
        title: this.$t('userList.removeDemoUsers.confirmText'),
        okText: this.$t('userList.removeDemoUsers.confirmAction'),
        okType: 'primary',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        onOk() {
          resetMethod();
        },
      });
    },
  },
};
</script>

<style
  scoped
  lang="scss"
  type="text/scss"
>
  .user-list {
    ._alert-box {
      display: flex;
      align-items: center;
      margin-bottom: 3rem;

      ._text {
        padding-right: 2.4rem;
        margin-right: auto;
        white-space: pre-wrap;
      }

      ._text-bold {
        font-weight: $font-weight-semibold;
      }
    }

    ._header {
      position: sticky;
      left: 0;
      margin-bottom: 4rem;
    }

    ._table-header {
      position: sticky;
      left: 0;
      display: flex;
      align-items: center;
      padding-bottom: .6rem;
    }

    ._left {
      margin-right: 2rem;
      font-size: $font-size-5;
      font-weight: $font-weight-bold;
    }

    ._right {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      overflow: auto;

      ._btn {
        margin-right: .2rem;
      }
    }
  }

  ._csv-import-content {
    ._terms {
      margin-bottom: .8rem;
    }

    ._selected-file {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: .8rem;

      &.-error {
        color: $error-color;
      }

      ._icon {
        margin-right: .8rem;
      }
    }

    ._errors {
      margin-top: .8rem;
      color: $error-color;
    }
  }
</style>
