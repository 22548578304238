<template>
  <div
    ref="viewPort"
    class="goal-tree"
    :style="wrapperStyle"
  >
    <m-selectable
      v-model:value="selectedGoalIds"
      selector-class="goal-card"
      :scroll-container-class="'goal-tree'"
      :disabled="readOnly || selectDisabled || !canUseGoalTree"
      :scroll-handler="pan"
    >
      <div :style="vpStyle">
        <tree-edge
          v-for="edge in edges"
          :key="`${edge.from.uid}_${edge.to.uid}`"
          :from="edge.from"
          :to="edge.to"
          :card-width="cardWidth"
        />
        <m-draggable
          draggable-item-class="goal-card"
          ghost-item-class="goal-card"
          dragover-item-class="goal-card"
          data-key="identifier"
          scroll-container-class="goal-tree"
          :ghost-item-style="{ transformOrigin: 'top left', transform: `scale(${viewPortTransform.k})`}"
          can-drag-over-top
          can-drag-over-bottom
          can-drag-over-right
          can-drag-over-left
          :recreate-key="treeId"
          :disabled="mode === 'hand' || readOnly"
          :drag-between-height="((distanceX * 2) - cardPadding) * viewPortTransform.k"
          :scroll-handler="pan"
          @set-drag-item="setDragItem"
          @over-top="setOverTop"
          @over-bottom="setOverBottom"
          @over-right="setOverRight"
          @over-left="setOverLeft"
          @drag-drop="handleDropItem"
          @cancel="cancelDragging"
        >
          <tree-item
            v-for="item in nodes"
            :key="item.index"
            :x="item.x"
            :y="item.y"
            :entity-id="item.entity.uid"
            :index="item.index"
            :has-children="item.hasChildren"
            :width="cardWidth"
            :height="levelHeight"
            :props="viewProps"
            wrap-cells
            :dragging-over-bottom="draggingOverBottom.includes(item.index)"
            :dragging-over-top="draggingOverTop.includes(item.index)"
            :dragging-over-right="draggingOverRight.includes(item.index)"
            :dragging-over-left="draggingOverLeft.includes(item.index)"
            :max-body-height="maxBodyHeight"
            :selected="selectedGoalIds.includes(item.entity.uid)"
            show-errors
            :validate="validate"
            :expanded="getExpand(item).value"
            :loading="goalsLoading.includes(item.entity.uid)"
            :update-property="updateProp"
            :create-loading="goalCreator.createLoading.value"
            :card-padding="cardPadding"
            :can-create="canCreate"
            :read-only="readOnly"
            @context-menu-click="handleContextMenuClick($event, item.entity)"
            @select="select"
            @open="open"
            @create="create"
            @toggle-expand="toggleExpand"
            @expand-all="expandAll(nodes)"
            @collapse-all="collapseAll(nodes)"
            @schedule-expand="scheduleExpand"
            @cancel-expand="cancelExpand"
          />
        </m-draggable>
      </div>
    </m-selectable>
    <div
      class="_controls"
    >
      <app-feedback-dropdown
        v-if="currentView.viewType === viewType.tree"
        topic="tree"
        class="_btn-group -feedback"
      />
      <m-btn-group class="_btn-group">
        <m-tooltip>
          <m-btn
            fab
            hide-border
            icon="fit-to-screen"
            @click.stop="fitToScreen"
          />
          <template #title>
            {{ $t('goalTree.fitToScreen') }}
          </template>
        </m-tooltip>
        <m-btn
          fab
          hide-border
          icon="zoom-out"
          @click.stop="zoomOut"
        />
        <m-btn
          hide-border
          :style="{ minWidth: '7rem', justifyContent: 'center'}"
          @click.stop="setToPreviousZoomLevel"
        >
          {{ zoomLevel }}%
        </m-btn>
        <m-btn
          fab
          hide-border
          icon="zoom-in"
          @click.stop="zoomIn"
        />
      </m-btn-group>
    </div>
    <m-card
      :level="1"
      class="_center-controls"
      padding-xxs
    >
      <div :style="{ display: 'flex'}">
        <m-btn
          fab
          hide-border
          icon="mouse"
          light
          :color="mode === 'select' ? 'primary' : ''"
          :style="mode === 'select' ? { background: $colors.blue.lighten3 } : {}"
          @click.stop="localChangeMode('select')"
        />
        <m-btn
          fab
          hide-border
          icon="hand"
          light
          :style="mode === 'hand' ? { background: $colors.blue.lighten3 } : {}"
          :color="mode === 'hand' ? 'primary' : ''"
          @click.stop="localChangeMode('hand')"
        />
      </div>
    </m-card>
  </div>
  <m-content
    v-if="!listLoading && nodes.length === 0"
    padding-x="layout"
    :padding-y="8"
    class="_empty"
  >
    <div class="_inner">
      {{ emptyMessage }}
      <goal-create-dropdown
        v-if="canCreate"
        class="_create"
        @create-beneath="handleCreate"
      >
        <m-btn
          icon="plus"
          light
          :button-style="{ minWidth: '30rem' }"
        >
          {{ $t('general.new') }}
        </m-btn>
      </goal-create-dropdown>
    </div>
  </m-content>
  <m-content
    v-if="!canUseGoalTree"
    padding-x="layout"
    :padding-y="8"
    class="_disabled"
    :style="{ height: contentHeight }"
  >
    <div
      class="_inner"
    >
      <m-card
        :level="2"
      >
        <div :style="{ display: 'flex' }">
          <m-icon
            type="warning"
            :color="$colors.yellow.base"
            :style="{ marginRight: '.4rem'}"
          />
          {{ $t('goalTree.disabledView') }}
        </div>
      </m-card>
    </div>
  </m-content>
</template>

<script>
import AppFeedbackDropdown from '@/components/app-feedback/AppFeedbackDropdown.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import TreeEdge from '@/components/goal/tree/TreeEdge.vue';
import TreeItem from '@/components/goal/tree/TreeItem.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useCascadeExpand from '@/composables/goal/cascade/expand';
import useCascadeViewParams from '@/composables/goal/cascade/view-params';
import useCustomFuncCtx from '@/composables/custom-func/custom-func-ctx';
import useDebounce from '@/composables/debounce';
import useDragAndDrop from '@/composables/goal/tree/drag-and-drop';
import useExpand from '@/composables/expand';
import useGoalDetailRules from '@/composables/goal/validator/goal-detail-rules';
import useGoalFetcher from '@/composables/goal/fetcher';
import useGoalFilter from '@/composables/goal/cascade/goal-filter';
import useGoalOperationValidator from '@/composables/goal/cascade/drag-validator';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalTree from '@/composables/goal/cascade/goal-tree';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useGoalsSorting from '@/composables/goal/sort';
import useInlineEditing from '@/composables/inline-editing';
import useLoadExpanded from '@/composables/goal/cascade/load-expanded';
import useLoader from '@/composables/goal/cascade/loader';
import useLocalStorage from '@/composables/local-storage/local-storage';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOnboardingMarker from '@/composables/onboarding/onboarding-marker';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import useParentSelectorRules from '@/composables/goal/validator/parent-selector-rules';
import useSnackBar from '@/composables/snackbar';
import useSort from '@/composables/draggable/sort';
import useTreeData from '@/composables/goal/tree/tree-data';
import useTreeViewSlideShow from '@/composables/goal/tree/slide-show';
import useUsers from '@/composables/user/users';
import useValidator from '@/composables/goal/validator/validator';
import useViewPort from '@/lib/viewport/view-port';
import useViewTreeExpandKeyMaker from '@/composables/local-storage/view-tree-expand-key-maker';
import useViewTreeViewPortKeyMaker from '@/composables/local-storage/view-tree-view-port-key-maker';
import { CARD_SIZE_LARGE, CARD_SIZE_MEDIUM, CARD_SIZE_SMALL, ERRORS, EVENTS, VIEWS_SERVICE } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';
import { accessPolicyType, featureFlag, validationError, viewType } from 'shared/constants.json';
import { computed, inject, ref, toRef, watch } from 'vue';
import { createViewId } from '@/lib/saved-view/saved-view';
import { depth, getOperationBasedOnPosition, idFromIndex } from '@/lib/sort-operation';
import { logCatch } from '@/lib/logger/logger';
import { shouldShowFeatureGate, showFeatureGate } from '@/lib/feature-gate';

export default {
  name: 'GoalTree',
  props: {
    canCreate: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    selectedCycles: {
      type: Array,
      default: () => [],
    },
    baseFilter: {
      type: Object,
      default: () => ({}),
    },
    defaultFilter: {
      type: Object,
      default: () => null,
    },
    goalCreator: {
      type: Object,
      default: () => ({}),
    },
    goalFetcherOptions: {
      type: Object,
      default: () => undefined,
    },
    goalDetailSorter: {
      type: Object,
      default: () => ({}),
    },
    viewSorter: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { GoalCreateDropdown, AppFeedbackDropdown, TreeItem, TreeEdge },
  setup(props) {
    const snackbar = useSnackBar();
    const viewPort = ref(null);
    const { userHasRight } = useAccess();

    const canUseGoalTree = computed(() => !shouldShowFeatureGate(featureFlag.goalTree));
    const viewsService = inject(VIEWS_SERVICE);
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { loggedInUser } = useLoggedInUser();
    const viewParamsService = useCascadeViewParams(
      viewsService,
      props.defaultFilter,
      loggedInUserAccount,
      toRef(props, 'searchTerm'),
      toRef(props, 'selectedCycles'),
    );

    const cardPadding = 12;
    const getCardWidth = () => {
      switch (viewsService.currentView.value.params.cardWidth) {
        case CARD_SIZE_SMALL:
          return 200;
        case CARD_SIZE_MEDIUM:
          return 260;
        case CARD_SIZE_LARGE:
          return 320;
        default:
          return 260;
      }
    };
    const cardWidth = computed(() => getCardWidth() + (2 * cardPadding));
    const distanceX = 30;
    const distanceY = 70;

    const { properties: goalProperties } = useGoalProperty();

    const { debounce } = useDebounce();
    // TODO: use expand for dragging
    const { debounce: expandDebounce, cancel: expandCancel } = useDebounce();

    const propertyItemHeight = 28;
    const cardFooterHeight = 38;
    const titleHeight = 100; // assuming 5 lines
    const paddings = 16;
    const margins = 8;
    const defaultHeight = 200;
    const viewProps = viewParamsService.props;
    const levelHeight = computed(() => {
      if (viewProps.value === undefined) {
        return defaultHeight;
      }
      return viewProps.value.filter((p) => !p.hideInProps && p.show && !p.isTitle).length * propertyItemHeight + cardFooterHeight + titleHeight + (2 * propertyItemHeight) + (paddings * 3) + margins;
    });

    const maxBodyHeight = computed(() => levelHeight.value - (1.5 * paddings) - cardFooterHeight);

    const goalFilter = useGoalFilter(
      toRef(props, 'baseFilter'),
      viewParamsService,
    );

    const customFuncCtxAlias = computed(() => `${createViewId(viewsService.currentView.value)}_compareTo`);
    const customFuncCtx = useCustomFuncCtx(customFuncCtxAlias);
    const goalFetcher = useGoalFetcher(goalProperties, viewParamsService, customFuncCtx, props.goalFetcherOptions);

    const rootItemsPerPage = 10;
    const initialAmountOfItems = 30;
    const loadMoreAmount = 20;
    const { fetchLoading, listLoading, viewChangedLoading, retrieveGoals, loadMore } = useLoader(
      rootItemsPerPage,
      initialAmountOfItems,
      loadMoreAmount,
      goalFetcher,
      viewParamsService,
      debounce,
      toRef(props, 'selectedCycles'),
      toRef(props, 'searchTerm'),
      ref([]),
      toRef(props, 'baseFilter'),
    );
    const goalsSvc = useGoals();

    const localStorageSvc = useLocalStorage(localStorage, useViewTreeExpandKeyMaker(viewsService.currentView));
    const identifier = { getId: (o) => o.index };
    const expandSvc = useExpand(identifier, localStorageSvc.data);
    const cascadeExpand = useCascadeExpand(expandSvc, localStorageSvc.data);
    const childrenKey = '_children';

    const userSvc = useUsers();
    const { sort } = useGoalsSorting(goalProperties, userSvc);

    const goalTreeService = useGoalTree(
      viewsService,
      viewParamsService,
      goalFilter,
      sort,
      goalsSvc,
      goalsSvc.entityList,
      cascadeExpand,
      childrenKey,
    );
    const {
      nodes,
      edges,
      minMax,
    } = useTreeData(goalTreeService, childrenKey, distanceX, distanceY, levelHeight, cardWidth, cardPadding);
    const viewPortDisabled = computed(() => !canUseGoalTree.value || nodes.value.length === 0);

    // set to 100px, 100px so the goal cards have some space at the top and right
    const localStorageViewPort = useLocalStorage(localStorage, useViewTreeViewPortKeyMaker(viewsService.currentView), {
      x: 100,
      y: 100,
      k: 1,
    });
    const panDebounce = useDebounce();
    const {
      viewPortTransform,
      init,
      changeMode,
      mode,
      zoomIn,
      fitToScreen,
      zoomToContentPoint,
      zoomLevel,
      setToPreviousZoomLevel,
      zoomOut,
      resetTransform,
      grabbing,
      pan,
    } = useViewPort(localStorageViewPort.data, panDebounce, minMax, viewPortDisabled, loadMore);

    const { showAsLoading: goalsLoading } = useLoadExpanded(
      goalsSvc,
      nodes,
      cascadeExpand,
      goalFetcher,
    );

    const { goalTypeProperty } = useGoalTypeProperty();
    const { rules: parentRules } = useParentSelectorRules(goalTypeProperty.value.options);
    const parentValidator = useValidator({
      rules: parentRules,
      usesOKRRules: true,
    });

    const opElementReader = {
      getId: (o) => o.entity.uid,
      getDragId: (o) => o.index,
      getDepth: (o) => o.indentation,
      isParent: (o) => o.isParent,
      canWrite: (o) => {
        const entity = goalsSvc.selectSingle(o.entity.uid);
        return [accessPolicyType.write, accessPolicyType.full].includes(entity?.accessRight);
      },
    };
    const actionValidator = useGoalOperationValidator(nodes, opElementReader, goalsSvc, parentValidator, viewsService);
    const { accountHasFeature } = useAccess();
    const { rules } = useGoalDetailRules(
      goalTypeProperty.value.options,
      goalsSvc,
      accountHasFeature([featureFlag.advancedOkrRules]),
    );
    const { validate } = useValidator({ rules, usesOKRRules: true });
    const validateDragAction = (keys, position) => {
      const filtered = keys.filter((k) => k !== dragItemId.value);
      if (filtered.length === 0) {
        return [];
      }
      const operations = [];

      operations.push(getOperationBasedOnPosition([dragItemId.value], keys[0], position));
      // if position is top, a node split operation is performed, which consists of two
      // operations: 1) set the parent of the dragged item and 2) change the parent of the
      // anchor to the dragged item.
      if (position === 'top') {
        operations.push(getOperationBasedOnPosition(keys, dragItemId.value, 'bottom'));
      }
      for (let i = 0; i < operations.length; i++) {
        const { isValid } = actionValidator.isValidDrag(operations[i]);
        if (!isValid) {
          return [];
        }
      }
      return keys;
    };

    const {
      setDragItem,
      setOverBottom,
      setOverTop,
      setOverRight,
      setOverLeft,
      draggingOverBottom,
      draggingOverTop,
      draggingOverRight,
      draggingOverLeft,
      cancelDragging,
      dragItemId,
    } = useSort('uid', validateDragAction);

    const { accountSettings } = useAccountSettings();
    const { handleDrop, getNewParentIndex } = useDragAndDrop(
      nodes,
      dragItemId,
      draggingOverTop,
      draggingOverBottom,
      draggingOverLeft,
      draggingOverRight,
      viewsService,
      goalsSvc,
      props.goalDetailSorter,
      props.viewSorter,
      accountSettings.value.usesMultiGoalAlignment,
    );

    const inlineEditingSvc = useInlineEditing();

    const firstViewChangedLoading = ref(true);
    watch(viewChangedLoading, (val, oldVal) => {
      if (val === false && oldVal === true) {
        if (firstViewChangedLoading.value === false) {
          fitToScreen();
        }
        firstViewChangedLoading.value = false;
      }
    });

    const accountSettingsSvc = useAccountSettings();
    const onboardingMarker = useOnboardingMarker(loggedInUserAccount, accountSettingsSvc);
    const { showTreeViewSlideShow } = useTreeViewSlideShow(onboardingMarker);

    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));

    const selectedGoalIds = ref([]);

    return {
      snackbar,
      viewPort,
      userHasRight,

      selectedGoalIds,

      showTreeViewSlideShow,
      loggedInUser,

      setDragItem: (item) => {
        selectedGoalIds.value = [];
        setDragItem(item);
      },
      setOverBottom,
      setOverTop,
      setOverRight,
      setOverLeft,
      draggingOverTop,
      draggingOverBottom,
      draggingOverRight,
      draggingOverLeft,
      cancelDragging,
      dragItemId,

      zoomToContentPoint,

      resetTransform,

      handleDrop,
      getNewParentIndex,
      cardPadding,
      init,
      viewPortTransform,
      zoomIn,
      fitToScreen,
      zoomOut,
      zoomLevel,
      setToPreviousZoomLevel,
      changeMode,
      mode,
      grabbing,
      pan,
      edges,
      goalsSvc,
      updateProperty: goalsSvc.updateProperty,
      currentView: viewsService.currentView,
      viewProps,
      cardWidth,
      levelHeight,
      maxBodyHeight,
      distanceX,
      distanceY,
      nodes,
      retrieveGoals,
      goalsLoading,
      fetchLoading,
      listLoading,

      expandGoal: cascadeExpand.expandItem,
      realignAndRestoreExpandState: cascadeExpand.realignAndRestoreExpandState,
      getExpand: cascadeExpand.getExpand,
      toggleExpand: cascadeExpand.toggleExpand,
      expandNextLevel: cascadeExpand.expandNextLevel,
      expandAll: cascadeExpand.expandAll,
      collapseNextLevel: cascadeExpand.collapseNextLevel,
      collapseAll: cascadeExpand.collapseAll,

      expandDebounce,
      expandCancel,

      inlineEditingSvc,

      canUseGoalTree,

      validate,
      peekModeSvc,
    };
  },
  data() {
    return {
      viewType,
      selectDisabled: false,
      boundingClientRect: { top: 0, left: 0, width: 0, height: 0 },
    };
  },
  computed: {
    hasFilter() {
      return !(this.currentView.params.filter === null || this.currentView.params.filter.children.length === 0);
    },
    emptyMessage() {
      const getMessage = () => {
        if (this.hasFilter) {
          return this.$t('goalTree.empty.hasFilter');
        }
        return this.$t('goalTree.empty.noFilter');
      };

      const message = getMessage();
      if (!this.canCreate) {
        return message;
      }
      return `${message} ${this.$t('goalTree.empty.cta')}`;
    },
    treeId() {
      return this.nodes.reduce((res, next) => {
        res += next.index;
        return res;
      }, '');
    },
    vpStyle() {
      return {
        transformOrigin: 'left top',
        transform: `translate(${this.viewPortTransform.x}px, ${this.viewPortTransform.y}px) scale(${this.viewPortTransform.k})`,
        pointerEvents: this.grabbing ? 'none' : undefined,
      };
    },
    cursorStyle() {
      if (this.grabbing || this.mode === 'hand') {
        return { cursor: this.grabbing ? 'grabbing' : 'grab' };
      }
      return {};
    },
    contentHeight() {
      return `calc(100vh - ${this.boundingClientRect.top + 1}px)`;
    },
    wrapperStyle() {
      return {
        height: this.contentHeight,
        overflow: 'hidden',
        ...this.cursorStyle,
        touchAction: 'none',
        opacity: this.canUseGoalTree ? 1 : 0.3,
        pointerEvents: !this.canUseGoalTree || this.dragItemId !== '' ? 'none' : 'unset',
      };
    },
    dataLoading() {
      return this.fetchLoading || this.goalsLoading.length > 0;
    },
  },
  emits: ['data-loaded', 'data-loading', 'selection-right-click', 'update-selected-goal-ids'],
  methods: {
    handleContextMenuClick(event, node) {
      if (this.selectedGoalIds.includes(node.uid)) {
        this.$emit('selection-right-click', event, node);
        return;
      }
      this.selectedGoalIds = [node.uid];
      this.$emit('selection-right-click', event, node);
    },
    scheduleExpand(index) {
      if (this.dragItemId === '') {
        return;
      }
      const toggle = () => {
        this.toggleExpand({ index });
      };

      this.expandDebounce(toggle, 1000);
    },
    cancelExpand() {
      this.expandCancel();
    },
    handleCreate({ option }) {
      this.create({ node: { entity: { uid: 0 } }, position: 'bottom', option });
    },
    handleDropItem() {
      const fromIndex = this.dragItemId;
      const fromGoalId = idFromIndex(fromIndex, depth(fromIndex));
      const newParentIndex = this.getNewParentIndex();
      this.selectedGoalIds = [fromGoalId];
      this.handleDrop().then((res) => {
        if (res?.cancelled === undefined && res?.info === undefined) {
          this.expandGoal({ index: newParentIndex });
          this.realignAndRestoreExpandState(fromIndex, `${newParentIndex}_${fromGoalId}`);
          return;
        }

        if (res?.cancelled === true) {
          return;
        }

        if (res.info.message.includes(ERRORS.SORT.PARENT_NOT_EDITABLE)) {
          this.snackbar.info(this.$t('goalMutationErrors.missingAccessRightsGoal', { title: res.info.entity.title }));
          return;
        }
        if (res.info.message.includes(ERRORS.SORT.VIEW_NOT_EDITABLE)) {
          this.snackbar.info(this.$t('goalMutationErrors.missingAccessRightsView', { title: res.info.entity.title }));
        }
      }).catch(logCatch((err) => {
        this.realignAndRestoreExpandState(`${newParentIndex}_${fromGoalId}`, fromIndex);

        if (err.message.includes(validationError.circularReferenceNotAllowed)) {
          this.snackbar.info(this.$t('goalMutationErrors.circularReference'));
          return;
        }
        if (err.message.includes(ERRORS.REALIGN.MISSING_ACCESS_RIGHTS_FOR_GOAL)) {
          const goalTitle = err.message.split(`${ERRORS.REALIGN.MISSING_ACCESS_RIGHTS_FOR_GOAL}: `)[1];
          this.snackbar.info(this.$t('goalMutationErrors.missingAccessRightsGoal', { title: goalTitle }));
          return;
        }

        this.snackbar.error();
      }));
    },
    localChangeMode(mode) {
      this.changeMode(mode);
      if (mode === 'select') {
        this.selectDisabled = false;
        return;
      }
      this.selectDisabled = true;
    },
    updateProp(value, property, goal) {
      this.updateProperty(value, property, goal).catch(logCatch((err) => {
        if (err.message.includes(validationError.circularReferenceNotAllowed)) {
          this.snackbar.error(this.$t('goalMutationErrors.circularReference'));
          return;
        }

        this.snackbar.error();
      }));
    },
    editGoal(goal) {
      const g = this.nodes.find((n) => n.entity.uid === goal.uid);
      if (g === undefined) {
        return;
      }
      this.inlineEditingSvc.set(g.index);
    },
    centerGoal(goal) {
      const g = this.nodes.find((n) => n.entity.uid === goal.uid);
      if (g === undefined) {
        return;
      }
      this.zoomToContentPoint(g.x + this.cardWidth / 2, g.y + this.levelHeight / 2);
    },
    create({ index, entity, position, option }) {
      if (position === 'bottom') {
        this.goalCreator.modifiers.setOption(option);
        this.goalCreator.addChild([entity.uid]).then((newGoal) => {
          this.goalCreator.modifiers.setOption(null);
          this.expandGoal({ index });
          this.centerGoal(newGoal);
          this.editGoal(newGoal);
        }).catch(logCatch(() => {
          this.snackbar.error();
        }));
        return;
      }
      if (position === 'left') {
        const d = depth(index);
        const parentId = idFromIndex(index, d - 1);
        this.goalCreator.modifiers.setOption(option);
        this.goalCreator.addLeftSibling(entity, parentId, this.nodes).then((newGoal) => {
          this.goalCreator.modifiers.setOption(null);
          this.centerGoal(newGoal);
          this.editGoal(newGoal);
        });
      }
      if (position === 'right') {
        const d = depth(index);
        const parentId = idFromIndex(index, d - 1);
        this.goalCreator.modifiers.setOption(option);
        this.goalCreator.addRightSibling(entity, parentId, this.nodes).then((newGoal) => {
          this.goalCreator.modifiers.setOption(null);
          this.centerGoal(newGoal);
          this.editGoal(newGoal);
        });
      }
      if (position === 'top') {
        this.goalCreator.modifiers.setOption(option);
        this.goalCreator.splitNode(entity, entity.parents.map((p) => p.uid), this.nodes).then((newGoal) => {
          const g = this.nodes.find((n) => n.entity.uid === newGoal.uid);
          if (g === undefined) {
            return;
          }
          this.expandGoal(g);
          this.zoomToContentPoint(g.x + this.cardWidth / 2, g.y + this.levelHeight / 2);
          this.editGoal(newGoal);
        });
      }
    },
    select({ goal }) {
      this.selectedGoalIds = [goal.uid];
    },
    open(goal) {
      this.peekModeSvc.openGoal({ goalId: goal.uid });
    },
  },
  watch: {
    selectedGoalIds() {
      this.$emit('update-selected-goal-ids', this.selectedGoalIds);
    },
    dataLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.$emit('data-loaded');
      }
      if (oldVal === false && newVal === true) {
        this.$emit('data-loading');
      }
    },
  },
  mounted() {
    this.boundingClientRect = this.viewPort.getBoundingClientRect();
    this.init(this.viewPort);
  },
  created() {
    EventBus.$emit(EVENTS.VIEW.GOAL_TREE_VIEW_VISITED, { view: this.currentView });
    if (!this.canUseGoalTree) {
      showFeatureGate(featureFlag.goalTree);
    }
    if (this.loggedInUser.goalTreeSlideShowShown === null && this.canUseGoalTree) {
      this.showTreeViewSlideShow();
    }
    this.retrieveGoals();

    EventBus.$on('expand-next-level', ({ target }) => {
      if (target !== this) return;
      this.expandNextLevel(this.nodes);
    });
    EventBus.$on('expand-all', ({ target }) => {
      if (target !== this) return;
      this.expandAll(this.nodes);
    });
    EventBus.$on('collapse-next-level', ({ target }) => {
      if (target !== this) return;
      this.collapseNextLevel(this.nodes);
    });
    EventBus.$on('collapse-all', ({ target }) => {
      if (target !== this) return;
      this.collapseAll(this.nodes);
    });
  },
  beforeUnmount() {
    EventBus.$off('expand-next-level', ({ target }) => {
      if (target !== this) return;
      this.expandNextLevel(this.nodes);
    });
    EventBus.$off('expand-all', ({ target }) => {
      if (target !== this) return;
      this.expandAll(this.nodes);
    });
    EventBus.$off('collapse-next-level', ({ target }) => {
      if (target !== this) return;
      this.collapseNextLevel(this.nodes);
    });
    EventBus.$off('collapse-all', ({ target }) => {
      if (target !== this) return;
      this.collapseAll(this.nodes);
    });
  },
};
</script>

<style scoped lang="scss" type="text/scss">
@import "shared/assets/scss/box-shadow";

.goal-tree {
  width: 100%;
  position: relative;
  border-top: 1px solid map_get($grey, 'lighten-4');

  ._controls {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    align-items: center;

    @media (max-width: $screen-size-md) {
      bottom: 6rem;
      right: 1rem;
    }

    ._btn-group {
      border-radius: $btn-border-radius;
      background: white;
      margin-left: .6rem;

      @include box_shadow(1);
    }
  }

  ._center-controls {
    @include box_shadow(1);

    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateY(0%) translateX(-50%);

    @media (max-width: $screen-size-md) {
      display: none;
    }
  }
}

._empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;

  ._inner {
    color: $font-color-secondary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ._create {
      margin-top: 1rem;
    }
  }
}

._disabled {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  ._inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
