<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
  >
    <rect
      x="5.9895"
      y="7.5271"
      width="12.0209"
      height="9.78284"
      rx="1"
      fill="currentColor"
    />
    <rect
      x="3.25"
      y="4.78625"
      width="17.5"
      height="15.2644"
      rx="1.25"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default { name: 'CenterPeek' };
</script>

<style scoped lang="scss" type="text/scss">

</style>
