<template>
  <full-screen-spinner
    v-if="firstLoadingSvc.loading.value && goalTree.length === 0"
    class="_spinner"
    :height="150"
  />
  <m-selectable
    v-else
    v-model:value="selectedGoalIds"
    :selector-class="itemClass"
    :scroll-container-class="scrollContainerClass"
    :select-area-class="selectAreaClass"
    :disabled="readOnly"
  >
    <div
      ref="container"
      :class="classes"
    >
      <m-hover-menu
        v-if="!readOnly || selectable"
        class="_hover-menu"
        :item-class="itemClass"
        :recreate-key="goalTree"
        :mouse-move-area="mouseMoveArea"
        data-key="draggableId"
        :force-show="showHoverMenu || showCreateDropdown"
        @set-active-element="setActiveElement"
      >
        <m-card
          v-if="dragItem == null && !readOnly"
          flat
          no-padding
          :style="{ position: 'absolute', right: '0.4rem', top: '.6rem', maxWidth: 'fit-content' }"
          :bordered="viewApplication.gridPageTileGoal === viewApp"
        >
          <div
            v-if="selectedGoalIds.length < 2"
            class="_hover-menu-inner"
          >
            <m-tooltip v-if="canCreate && allowedSubItemGoalTypes.length > 0">
              <goal-create-dropdown
                :goal="activeGoal.entity"
                @create-beneath="createBeneath"
                @hide="showCreateDropdown = false"
              >
                <m-btn
                  icon="plus"
                  hide-border
                  small
                  super-light
                  fab
                  icon-size="18"
                  :button-style="{ width: '2.2rem' }"
                  :loading="createLoading"
                  :disabled="createLoading"
                  @click="showCreateDropdown = true"
                  @mousedown.stop
                />
              </goal-create-dropdown>
              <template #title>
                {{ $t('goalsCascadeTable.addTooltip') }}
              </template>
            </m-tooltip>
            <m-dropdown
              v-model:value="showHoverMenu"
              :title="$t('general.actions')"
              placement="leftCenter"
              :relocate-key="goalCtxMenuRelocate"
              @hide="goalCtxMenuRelocate = 0"
            >
              <drag-or-click-btn
                draggable
                clickable
                @click.stop="handleHoverMoreClick"
                @drag="handleMouseDownDragButton"
              />
              <template #overlay>
                <goal-context-menu
                  :goal="goalsSvc.selectSingle(activeGoal.entity.uid)"
                  component-type="div"
                  :create-loading="createLoading"
                  :can-create="canCreate"
                  show-expand-buttons
                  @relocate="handleRelocateCtxMenu"
                  @hide="hideHoverMenu"
                  @create-beneath="createBeneath"
                  @expand-all="expandAll(goalTree)"
                  @collapse-all="collapseAll(goalTree)"
                />
              </template>
            </m-dropdown>
          </div>
        </m-card>
      </m-hover-menu>
      <template v-if="viewHeaderPortalTarget !== null">
        <teleport
          :to="viewHeaderPortalTarget"
        >
          <div class="_select-menu">
            <select-menu
              v-if="selectedGoalIds.length > 0 && !readOnly"
              class="_inner"
              :goal-ids="selectedGoalIds"
              :can-create="canCreate"
              show-expand-buttons
              @expand-all="expandAll(goalTree)"
              @collapse-all="collapseAll"
              @hide="unselectAll"
              @expand-and-select-all="handleExpandAndSelectAll"
              @mousedown.stop
            />
          </div>
        </teleport>
      </template>
      <template v-if="headerPortalTarget !== null">
        <teleport :to="headerPortalTarget">
          <goal-table-header
            :key="JSON.stringify(selectedView.key)"
            ref="table-header"
            class="_header"
            :props="visibleProps"
            :has-empty-row="hasEmptyRow"
            :filler-column-width="fillerColumnWidth"
            :read-only="readOnly"
            :show-check-box="showSelectAll"
            :selection-state="selectionState"
            @select-all-clicked="selectAll"
            @show-menu="handleShowMenuClick"
            @scroll-top="handleScrollTop"
            @cell-dragstart="handleHeaderCellDragStart($event)"
            @cell-drag="handleHeaderCellDrag($event)"
            @cell-dragend="handleHeaderCellDragEnd"
          />
        </teleport>
      </template>
      <m-endless-scroll-list
        v-if="goalTree.length > 0"
        class="_table"
        @visible="handleAutoPaginate"
      >
        <m-draggable
          ref="draggable"
          :disabled="$store.state.breakpoint.smAndDown"
          ghost-item-class="cascade-table-row"
          dragover-item-class="cascade-table-row"
          scroll-container-class="scroll-container"
          can-drag-over-top
          can-drag-over-bottom
          can-drag-over
          data-key="draggableId"
          :recreate-key="goalTree"
          :drag-between-height="dragBetweenHeight"
          @set-drag-item="initDrag"
          @over-top="setDragOverTop"
          @over-bottom="setDragOverBottom"
          @over="setDragOver"
          @drag-drop="handleDrop"
          @cancel="resetDragging"
        >
          <goal-cascade-table-item
            v-for="(goal, i) in goalTree"
            :key="goal.index"
            :entity-id="goal.entity.uid"
            :has-children="goal.hasChildren"
            :is-last-item="goal.isLastItem"
            :is-first-item="goal.isFirstItem"
            :is-parent="goal.isParent"
            :identation="goal.indentation"
            :guid="goal.guid"
            :expanded="getExpand(goal).value"
            :index="goal.index"
            :show-errors="showErrors"
            :props="visibleProps"
            :has-empty-row="hasEmptyRow"
            :is-selected="selectedGoalIds.includes(goal.entity.uid)"
            :item-class="itemClass"
            :can-create="canCreate"
            :create-loading="createLoading"
            :is-disabled="isDisabled(goal.entity)"
            :is-inline-editing="inlineEditingSvc.isInlineEditing(goal.guid).value"
            :loading="goalsLoading.includes(goal.entity.uid)"
            :update-property="updateProp"
            :indentation-level="goal.indentation"
            :show-select-box="activeGoalIndex === goal.index"
            class="_item"
            :read-only="readOnly"
            :selectable="selectable"
            :wrap-cells="selectedView.params.wrapCells"
            :show-progress-diff="showProgressDiff"
            :progress-display-option="progressDisplayOption"
            :progress-course-diff="getCustomFuncCtxValue(goal.entity)"
            :validate="validate"
            :highlighted-columns="highlightedColumns[goal.index]"
            :show-drag-over-targets="dragOver.includes(goal.index)"
            :show-drag-over-top-targets="dragOverTop.includes(goal.index)"
            :show-drag-over-bottom-targets="dragOverBottom.includes(goal.index)"
            :drag-depth="dragDepth(goal.index)"
            :filler-column-width="fillerColumnWidth"
            @highlight-column="handleHighlightColumn"
            @context-menu-click="handleContextMenuClick($event, goal.entity)"
            @set-inline-editing="inlineEditingSvc.set(goal.guid)"
            @reset-inline-editing="inlineEditingSvc.reset()"
            @on-click="handleClick($event, i)"
            @select-row="selectGoalRow"
            @toggle-expand="toggleExpand"
            @schedule-expand="scheduleExpand"
            @cancel-expand="cancelExpand"
          />
        </m-draggable>
        <div
          v-if="!readOnly"
          :class="['_drag-handle-line', headerCellIsDragging? '-active':'']"
          :style="dragHandleLineStyle"
        />
      </m-endless-scroll-list>
      <m-content
        v-if="goalTree.length === 0 && !canCreate && !listLoading"
        :padding-x="6"
        :padding-y="6"
        class="_empty"
      >
        {{
          $t('goalsCascadeTable.emptyGoalList', {title: goalSettings.featureNamePlural})
        }}
      </m-content>
      <row-button
        v-if="hasMore"
        class="_row-btn"
        :label="$t('general.loadMore')"
        :loading="listLoading"
        block
        icon="arrow-down"
        @click="loadMore"
      />
      <row-button
        v-if="canCreate && !isPrint"
        class="_row-btn"
        :style="newButton"
        :label="$t('general.new')"
        :loading="createLoading"
        block
        icon="plus"
        @click="create()"
      />
    </div>
  </m-selectable>
</template>

<script>
import DragOrClickBtn from 'shared/components/DragOrClickBtn.vue';
import FullScreenSpinner from 'shared/components/FullScreenSpinner.vue';
import GoalCascadeTableItem from '@/components/goal/GoalCascadeTableItem.vue';
import GoalContextMenu from '@/components/goal/GoalContextMenu.vue';
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import GoalTableHeader from '@/components/goal/GoalTableHeader.vue';
import RowButton from '@/components/table/RowButton.vue';
import SelectMenu from '@/components/goal/SelectMenu.vue';
import useAccess from '@/composables/access/access';
import useAccountSettings from '@/composables/logged-in-user-account/account-settings';
import useBulkMutate from '@/nebula/bulk-mutate';
import useCascadeDragAndDrop from '@/composables/cascade-drag-and-drop';
import useCascadeExpand, { COLLAPSE_ALL_MODE, DEFAULT_MODE, EXPAND_ALL_MODE } from '@/composables/goal/cascade/expand';
import useCascadeViewParams from '@/composables/goal/cascade/view-params';
import useCustomFuncCtx from '@/composables/custom-func/custom-func-ctx';
import useDebounce from '@/composables/debounce';
import useExpand from '@/composables/expand';
import useExport from '@/composables/export/export';
import useFirstLoading from '@/composables/first-loading';
import useGoalDetailRules from '@/composables/goal/validator/goal-detail-rules';
import useGoalFetcher from '@/composables/goal/fetcher';
import useGoalFilter from '@/composables/goal/cascade/goal-filter';
import useGoalOperationExecutor from '@/composables/goal/cascade/drag-executor';
import useGoalOperationValidator from '@/composables/goal/cascade/drag-validator';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoalTree from '@/composables/goal/cascade/goal-tree';
import useGoalTypeProperty from '@/composables/customize-page/goal-type-property';
import useGoals from '@/composables/goal/goals';
import useGoalsSorting from '@/composables/goal/sort';
import useInlineEditing from '@/composables/inline-editing';
import useLoadExpanded from '@/composables/goal/cascade/load-expanded';
import useLoader from '@/composables/goal/cascade/loader';
import useLocalStorage from '@/composables/local-storage/local-storage';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useOpenPeekMode from '@/composables/goal/open-peek-mode';
import useParentSelectorRules from '@/composables/goal/validator/parent-selector-rules';
import useSelect from '@/composables/select';
import useSnackBar from '@/composables/snackbar';
import useUsers from '@/composables/user/users';
import useValidator from '@/composables/goal/validator/validator';
import { EventBus } from '@/lib/event-bus';
import { MAX_AUTO_LOAD_LIMIT, VIEWS_SERVICE } from '@/lib/constants';
import { accessPolicyType, featureFlag, validationError, viewApplication } from 'shared/constants.json';
import { computed, inject, ref, toRef } from 'vue';
import { createViewId } from '@/lib/saved-view/saved-view';
import { depth, idFromIndex } from '@/lib/sort-operation';
import { getAllowedSubItemGoalTypes, goalTypeOption } from '@/composables/goal/allowed-sub-items';
import { goal as goalConfig } from 'shared/api/query/configs.json';
import { iconByType } from '@/lib/property';
import { logCatch } from '@/lib/logger/logger';
import { showCompareTo } from '@/lib/compare-to';
import { upToContainerWithClass } from 'shared/lib/dom';
import { useCascadeData } from '@/composables/goal/cascade/cascade-data';

export default {
  name: 'RGoalsCascadeTable',
  props: {
    headerWidth: {
      type: Number,
      required: true,
    },
    headerPortalTarget: {
      type: Object,
      default: () => null,
    },
    viewHeaderPortalTarget: {
      type: Object,
      default: () => null,
    },
    initiallySelectedGoalIds: {
      type: Array,
      default: () => ([]),
    },
    singleSelectMode: {
      type: Boolean,
      default: false,
    },
    hasEmptyRow: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    disabledGoalIds: {
      type: Array,
      default: () => [],
    },
    disabledCondition: {
      type: Function,
      default: () => false,
    },
    itemClass: {
      type: String,
      default: 'goal-item',
    },
    scrollContainerClass: {
      type: String,
      default: 'scroll-container',
    },
    selectAreaClass: {
      type: String,
      default: '',
    },
    initiallySelectAllGoals: {
      type: Boolean,
      default: false,
    },
    forceExpandMode: {
      type: String,
      default: '',
      validator(expandMode) {
        return ['', DEFAULT_MODE, EXPAND_ALL_MODE, COLLAPSE_ALL_MODE].includes(expandMode);
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    selectedCycles: {
      type: Array,
      default: () => [],
    },
    goalCreator: {
      type: Object,
      default: () => ({
        addChild() {
        },
        createLoading: { value: false },
      }),
    },
    baseFilter: {
      type: Object,
      default: () => ({}),
    },
    defaultFilter: {
      type: Object,
      default: () => null,
    },
    goalFetcherOptions: {
      type: Object,
      default: () => undefined,
    },
    expandLocalStorageKeyMaker: {
      type: Object,
      required: true,
    },
    showSelectAll: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['create', 'selection-right-click', 'data-loaded', 'data-loading', 'parents-edited', 'show-menu', 'scroll-top', 'selected-goals-updated'],
  components: {
    SelectMenu,
    GoalTableHeader,
    DragOrClickBtn,
    GoalCreateDropdown,
    RowButton,
    GoalCascadeTableItem,
    FullScreenSpinner,
    GoalContextMenu,
  },
  setup(props) {
    const snackbar = useSnackBar();
    const { isPrint } = useExport();
    const { properties: goalProperties } = useGoalProperty();
    const userSvc = useUsers();
    const { sort } = useGoalsSorting(goalProperties, userSvc);
    const { userHasRight } = useAccess();
    const { goalSettings } = useGoalSettings();
    const { debounce } = useDebounce();
    const { debounce: expandDebounce, cancel: expandCancel } = useDebounce();
    const goalsSvc = useGoals();

    const { goalTypeProperty } = useGoalTypeProperty();

    const { accountHasFeature } = useAccess();
    const { rules } = useGoalDetailRules(
      goalTypeProperty.value.options,
      goalsSvc,
      accountHasFeature([featureFlag.advancedOkrRules]),
    );
    const { validate } = useValidator({ rules, usesOKRRules: true });

    const viewsService = inject(VIEWS_SERVICE);
    const firstLoadingSvc = useFirstLoading({ currentView: viewsService.currentView });
    const { loggedInUserAccount } = useLoggedInUserAccount();

    const viewParamsService = useCascadeViewParams(
      viewsService,
      props.defaultFilter,
      loggedInUserAccount,
      toRef(props, 'searchTerm'),
      toRef(props, 'selectedCycles'),
    );

    const customFuncCtxAlias = computed(() => `${createViewId(viewsService.currentView.value)}_compareTo`);
    const customFuncCtx = useCustomFuncCtx(customFuncCtxAlias);
    const goalFetcher = useGoalFetcher(goalProperties, viewParamsService, customFuncCtx, props.goalFetcherOptions);
    const goalFetcherWithFirstLoading = {
      getGoals: (...args) => {
        firstLoadingSvc.startLoading();
        return goalFetcher.getGoals(...args);
      },
    };

    const goalFilter = useGoalFilter(
      toRef(props, 'baseFilter'),
      viewParamsService,
    );

    const localStorageSvc = useLocalStorage(localStorage, props.expandLocalStorageKeyMaker);
    const expandStorage = [DEFAULT_MODE, EXPAND_ALL_MODE, COLLAPSE_ALL_MODE].includes(props.forceExpandMode) ? undefined : localStorageSvc.data;
    const identifier = { getId: (o) => o.index };
    const expandSvc = useExpand(identifier, expandStorage);
    const initialExpandMode = props.forceExpandMode !== '' ? props.forceExpandMode : DEFAULT_MODE;
    const cascadeExpand = useCascadeExpand(expandSvc, expandStorage, initialExpandMode);

    const incRootItems = 10;
    const initRenderedItems = 30;
    const incRenderedItems = 20;
    const {
      fetchLoading,
      listLoading,
      totalRootItems,
      retrieveGoals,
      renderedItems,
      loadMore,
    } = useLoader(
      incRootItems,
      initRenderedItems,
      incRenderedItems,
      goalFetcherWithFirstLoading,
      viewParamsService,
      debounce,
      toRef(props, 'selectedCycles'),
      toRef(props, 'searchTerm'),
      toRef(props, 'disabledGoalIds'),
      toRef(props, 'baseFilter'),
    );

    const childrenKey = '_children';
    const goalTreeService = useGoalTree(
      viewsService,
      viewParamsService,
      goalFilter,
      sort,
      goalsSvc,
      goalsSvc.entityList,
      cascadeExpand,
      childrenKey,
    );
    const { goalTree, loadedItems } = useCascadeData(goalTreeService, renderedItems);

    const loadedRootItems = computed(() => {
      if (viewParamsService.showParents.value) {
        return goalTree.value.filter((g) => g.indentation === 1 || (!g.isParent && g.indentation === 0)).length;
      }
      return goalTree.value.filter((g) => g.indentation === 0).length;
    });

    const { rules: parentRules } = useParentSelectorRules(goalTypeProperty.value.options);
    const parentValidator = useValidator({
      rules: parentRules,
      usesOKRRules: true,
    });

    const accountSettingsSvc = useAccountSettings();
    const bulkMutateSvc = useBulkMutate();
    const opElementReader = {
      getId: (o) => o.entity.uid,
      getDragId: (o) => o.index,
      getDepth: (o) => o.indentation,
      isParent: (o) => o.isParent,
      canWrite: (o) => {
        const entity = goalsSvc.selectSingle(o.entity.uid);
        return [accessPolicyType.write, accessPolicyType.full].includes(entity?.accessRight);
      },
    };
    const actionExecutor = useGoalOperationExecutor(goalTree, opElementReader, accountSettingsSvc, goalsSvc, viewsService, bulkMutateSvc);
    const actionValidator = useGoalOperationValidator(goalTree, opElementReader, goalsSvc, parentValidator, viewsService);

    const dragAndDropElementReader = {
      getId: (o) => o.index,
      getDepth: (o) => o.indentation,
      isFirstSibling: (o) => o.isFirstItem,
      isLastSibling: (o) => o.isLastItem,
      hasChildren: (o) => o.hasChildren,
      isExpanded: (o) => expandSvc.getExpand(o).value === true,
    };

    const {
      selectAll,
      selectedIds: selectedGoalIds,
      unselectAll,
      selectItem: selectGoalRow,
      selectionState,
    } = useSelect(computed(() => goalTree.value.map((g) => g.entity.uid)), goalsSvc, props.disabledGoalIds, props.disabledCondition, !props.readOnly, props.singleSelectMode, props.initiallySelectedGoalIds);

    const {
      dropItem,
      dragOverBottom,
      dragOverTop,
      dragDepth: localDragDepth,
      setDragOverBottom,
      setDragOverTop,
      resetDragging,
      setDragItem,
      dragItem,
      dragOver,
      setDragOver,
    } = useCascadeDragAndDrop(
      goalTree,
      dragAndDropElementReader,
      actionExecutor,
      actionValidator,
    );

    const { showAsLoading: goalsLoading } = useLoadExpanded(
      goalsSvc,
      goalTree,
      cascadeExpand,
      goalFetcher,
    );

    const inlineEditingSvc = useInlineEditing();

    const viewApp = computed(() => viewsService.currentView.value.viewApplication);

    const mousemoveAreaClass = computed(() => {
      switch (viewApp.value) {
        case viewApplication.gridPageTileGoal:
          return 'grid-item';
        default:
          return 'scroll-container';
      }
    });

    const peekModeSvc = useOpenPeekMode(toRef(props, 'readOnly'));

    const hasMore = computed(() => {
      if (loadedItems.value === 0 || loadedRootItems.value === 0) {
        return false;
      }
      return renderedItems.value < loadedItems.value || loadedRootItems.value < totalRootItems.value;
    });

    const progressDisplayOption = computed(() => viewsService.currentView.value.params.props.find((p) => p.key === goalConfig.edges.cachedCalculatedCurrent).progressDisplayOption);

    const highlightedColumns = ref({});
    const resetHighlightedColumns = () => {
      highlightedColumns.value = {};
    };

    const selectRow = (item, append = false) => {
      resetHighlightedColumns();
      selectGoalRow(item, append);
    };

    return {
      firstLoadingSvc,
      snackbar,
      isPrint,
      expandDebounce,
      expandCancel,
      userHasRight,
      validate,

      customFuncCtx,
      resetHighlightedColumns,
      highlightedColumns,

      selectedView: viewsService.currentView,
      canEditCurrentView: viewsService.canEditCurrentView,
      viewProps: viewParamsService.props,
      sort,
      goalTypeProperty,
      goalTree,
      goalsSvc,
      getExpand: cascadeExpand.getExpand,
      toggleExpand: cascadeExpand.toggleExpand,
      expandGoal: cascadeExpand.expandItem,
      expandNextLevel: cascadeExpand.expandNextLevel,
      expandAll: cascadeExpand.expandAll,
      collapseNextLevel: cascadeExpand.collapseNextLevel,
      collapseAll: cascadeExpand.collapseAll,
      goalsLoading,
      retrieveGoals,
      hasMore,
      loadMore,
      renderedItems,
      fetchLoading,
      listLoading,
      dragItem,
      dropItem,
      localDragDepth,
      dragOverBottom,
      dragOverTop,
      setDragOverBottom,
      setDragOverTop,
      resetDragging,
      setDragItem,
      dragOver,
      setDragOver,
      selectSingle: goalsSvc.selectSingle,
      updateProperty: goalsSvc.updateProperty,
      goalProperties,
      inlineEditingSvc,

      mousemoveAreaClass,
      viewApp,
      viewApplication,
      peekModeSvc,

      goalSettings,
      progressDisplayOption,

      selectAll,
      unselectAll,
      selectedGoalIds,
      selectGoalRow: selectRow,
      selectionState,
    };
  },
  data() {
    return {
      itemsPerPage: 10,
      activeGoalIndex: -1,
      showHoverMenu: false,
      headerCellIsDragging: false,
      dragHandleLineStyle: undefined,
      accessPolicyType,
      showCreateDropdown: false,
      goalCtxMenuRelocate: 0,
      handleMouseMove: null,
      // amount of pixels between two elements which will trigger the
      // drag target
      dragBetweenHeight: 20,
      toExpand: null,
    };
  },
  computed: {
    mouseMoveArea() {
      return upToContainerWithClass(this.$refs.container, this.mousemoveAreaClass);
    },
    createLoading() {
      return this.goalCreator.createLoading.value;
    },
    dataLoading() {
      return this.fetchLoading || this.goalsLoading.length > 0;
    },
    showProgressDiff() {
      return showCompareTo(this.selectedView.params.compare);
    },
    allowedSubItemGoalTypes() {
      if (this.activeGoal.entity.uid === 0) {
        return [];
      }
      return getAllowedSubItemGoalTypes(goalTypeOption(this.activeGoal.entity, this.goalTypeProperty), this.goalTypeProperty);
    },
    activeGoal() {
      const g = this.goalTree.find((g) => g.index === this.activeGoalIndex);
      if (g === undefined) {
        return { entity: { uid: 0 } };
      }

      return g;
    },
    visiblePropsColumnWidth() {
      return this.visibleProps.reduce((acc, next) => acc + next.width, 0);
    },
    fillerColumnWidth() {
      const difference = this.visiblePropsColumnWidth - this.headerWidth;
      if (difference < -40) {
        return -difference;
      }
      return 40;
    },
    visibleProps() {
      const filteredProps = this.viewProps.filter((p) => (!p.hideInProps && p.show) || p.isTitle);
      const props = filteredProps.map((p) => {
        const icon = p.isTitle ? 'align-left' : iconByType({ type: p.property.type, edgeName: p.property.edgeName });
        const sortable = !p.isTitle;
        return {
          ...p,
          draggable: true,
          sortable,
          icon,
        };
      });
      return props;
    },
    classes() {
      return [
        'goals-cascade-table',
      ];
    },
    newButton() {
      return { width: `${this.visiblePropsColumnWidth + this.fillerColumnWidth}px` };
    },
  },
  methods: {
    handleHighlightColumn(column, index) {
      this.highlightedColumns[index] = [column.key];
    },
    handleExpandAndSelectAll() {
      this.selectAll(true);
      this.expandAll(this.goalTree);
    },
    handleClick(goal) {
      this.peekModeSvc.openGoal({ goalId: goal.uid });
    },
    getCustomFuncCtxValue(entity) {
      return this.customFuncCtx.state.value?.[entity.uid];
    },
    dragDepth(index) {
      if (this.dragOver.includes(index) || this.dragOverTop.includes(index) || this.dragOverBottom.includes(index)) {
        return this.localDragDepth;
      }
      return 0;
    },
    handleContextMenuClick(event, goal) {
      if (this.readOnly) {
        return;
      }
      if (this.selectedGoalIds.includes(goal.uid)) {
        this.$emit('selection-right-click', event, goal);
        return;
      }
      this.selectedGoalIds = [goal.uid];
      this.$emit('selection-right-click', event, goal);
    },
    isDisabled(entity) {
      return this.disabledGoalIds.includes(entity.uid) || this.disabledCondition(this.goalsSvc.selectSingle(entity.uid));
    },
    handleAutoPaginate() {
      if (this.renderedItems >= MAX_AUTO_LOAD_LIMIT) {
        return;
      }

      this.loadMore();
    },
    updateProp(value, property, goal) {
      this.updateProperty(value, property, goal).catch(logCatch((err) => {
        if (err.message.includes(validationError.circularReferenceNotAllowed)) {
          this.snackbar.error(this.$t('goalMutationErrors.circularReference'));
          return;
        }

        this.snackbar.error();
      }));
    },
    scheduleExpand(item) {
      if (this.dragItemId === '') {
        return;
      }
      if (this.dragItem !== null && this.toExpand !== item.index) {
        this.toExpand = item.index;
        const toggle = () => {
          this.toggleExpand(item);
        };
        this.expandDebounce(toggle, 1000);
      }
    },
    cancelExpand() {
      this.toExpand = null;
      this.expandCancel();
    },
    handleDrop() {
      const dragItem = this.dragItem;
      this.$emit('data-loading');
      this.dropItem().then(() => {
        if (dragItem === null) {
          return;
        }

        this.selectGoalRow(this.selectSingle(idFromIndex(dragItem, depth(dragItem))));
      }).finally(() => {
        this.$emit('data-loaded');
      }).catch(logCatch((err) => {
        if (err.message.includes(validationError.circularReferenceNotAllowed)) {
          this.$showSnackbar({ color: 'info', message: this.$t('goalMutationErrors.circularReference') });
          return;
        }

        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
      }));
    },
    handleMouseDownDragButton(event) {
      const el = document.querySelector(`.cascade-table-row[data-draggable-id="${this.activeGoal.index}"]`);
      if (el == null) {
        throw new Error('goal element not found');
      }

      this.$refs.draggable.handleMouseDown(el)(event);
    },
    initDrag(itemId) {
      const position = this.$refs.draggable.$el.getBoundingClientRect();
      this.unselectAll();
      this.setDragItem(itemId, position.left);
    },
    createBeneath({ parent, option }) {
      this.expandGoal(this.activeGoal);
      this.goalCreator.modifiers.setOption(option);
      this.goalCreator.addChild([parent.uid]).then((newGoal) => {
        this.goalCreator.modifiers.setOption(null);
        const g = this.goalTree.find((g) => g.entity.uid === newGoal.uid);
        if (g === undefined || this.$store.state.breakpoint.smAndDown) {
          this.peekModeSvc.openGoal({ goalId: newGoal.uid });
          return;
        }
        this.inlineEditingSvc.set(g.guid);
      }).catch(logCatch(() => {
        this.snackbar.error();
      })).finally(() => {
        this.showCreateDropdown = false;
        this.showHoverMenu = false;
      });
    },
    create() {
      this.goalCreator.addChild().then((newGoal) => {
        const g = this.goalTree.find((g) => g.entity.uid === newGoal.uid);
        if (g === undefined || this.$store.state.breakpoint.smAndDown) {
          this.peekModeSvc.openGoal({ goalId: newGoal.uid });
          return;
        }
        this.inlineEditingSvc.set(g.guid);
      }).catch(logCatch(() => {
        this.snackbar.error();
      }));
    },
    hideHoverMenu() {
      this.showHoverMenu = false;
      this.activeGoalIndex = -1;
      this.goalCtxMenuRelocate = 0;
    },
    handleRelocateCtxMenu(val) {
      this.goalCtxMenuRelocate = val;
    },
    handleHoverMoreClick() {
      this.showHoverMenu = true;
      this.selectGoalRow({ uid: this.activeGoal.entity.uid });
    },
    handleHeaderCellDragStart(event) {
      this.headerCellIsDragging = true;
      this.handleHeaderCellDrag(event);
    },
    handleHeaderCellDrag(event) {
      const headerEl = this.$refs['table-header'].$el;
      const headerRect = headerEl.getBoundingClientRect();
      this.dragHandleLineStyle = { left: `${event.pageX - headerRect.left}px` };
    },
    handleHeaderCellDragEnd() {
      this.headerCellIsDragging = false;
    },
    setActiveElement(uid) {
      this.activeGoalIndex = uid;
    },
    handleShowMenuClick() {
      this.$emit('show-menu', true);
    },
    handleScrollTop() {
      this.$emit('scroll-top');
    },
  },
  watch: {
    listLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.firstLoadingSvc.finishLoading();
      }
    },
    selectedGoalIds(val) {
      this.$emit('selected-goals-updated', val);
    },
    dataLoading(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.$emit('data-loaded');
      }
      if (oldVal === false && newVal === true) {
        this.$emit('data-loading');
      }
    },
  },
  created() {
    window.addEventListener('mousedown', this.resetHighlightedColumns);
    this.retrieveGoals();
    if (this.initiallySelectAllGoals) {
      this.selectAll();
    }
    EventBus.$on('expand-next-level', ({ target }) => {
      if (target !== this) return;
      this.expandNextLevel(this.goalTree);
    });
    EventBus.$on('expand-all', ({ target }) => {
      if (target !== this) return;
      this.expandAll(this.goalTree);
    });
    EventBus.$on('collapse-next-level', ({ target }) => {
      if (target !== this) return;
      this.collapseNextLevel(this.goalTree);
    });
    EventBus.$on('collapse-all', ({ target }) => {
      if (target !== this) return;
      this.collapseAll(this.goalTree);
    });
  },
  beforeUnmount() {
    window.removeEventListener('mousedown', this.resetHighlightedColumns);
    EventBus.$off('expand-next-level', ({ target }) => {
      if (target !== this) return;
      this.expandNextLevel(this.goalTree);
    });
    EventBus.$off('expand-all', ({ target }) => {
      if (target !== this) return;
      this.expandAll(this.goalTree);
    });
    EventBus.$off('collapse-next-level', ({ target }) => {
      if (target !== this) return;
      this.collapseNextLevel(this.goalTree);
    });
    EventBus.$off('collapse-all', ({ target }) => {
      if (target !== this) return;
      this.collapseAll(this.goalTree);
    });
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
.goals-cascade-table {
  padding-bottom: .8rem;

  ._table {
    display: table-row;
  }

  ._drag-handle-line {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 1px;
    height: 100%;
    background-color: transparent;

    &.-active {
      background-color: map_get($blue, 'lighten-1');
    }
  }

  ._pagination {
    margin-top: 2rem;
  }

  ._empty {
    color: $font-color-tertiary;
  }

  ._hover-menu-inner {
    display: flex;
  }

  ._row-btn {
    border-bottom: 1px solid $border-color;
    border-radius: 0;
  }
}

._header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

._select-menu {
  ._inner {
    position: absolute;
    top: .6rem;
  }
}

._hover-menu {
  margin-left: -2rem;
}
</style>
