import { environmentVariable, resolveEnvironmentVariable } from '@/lib/env';

import { EVENTS } from '@/lib/constants';
import { EventBus } from '@/lib/event-bus';

const syncHost = () => {
  if (window.location.host.includes('stage.x.mooncamp.com')) {
    return window.location.host.replace('stage', 'stage-subscription');
  }

  return resolveEnvironmentVariable(environmentVariable.VORTEX_HOST);
};

let subscriptionChannel = null;

const protocol = 'sse'; // optionally 'ws'

const connect = () => {
  if (protocol === 'sse') {
    const subscriptionUrl = `https://${syncHost()}/api/v1/subscribe`;
    subscriptionChannel = new EventSource(subscriptionUrl, { withCredentials: true });
    return;
  }

  const subscriptionUrl = `wss://${syncHost()}/api/v1/subscribe`;
  subscriptionChannel = new WebSocket(subscriptionUrl);
};

const disconnect = () => {
  if (subscriptionChannel === null) {
    return;
  }

  subscriptionChannel.close();
  subscriptionChannel = null;
};

let connector = null;
let eventHandlers = [];

const initConnector = () => {
  eventHandlers = [];
  connector = new Promise((resolve) => {
    const handler = () => { connect(); resolve(); };
    eventHandlers.push(handler);
    EventBus.$on(EVENTS.SESSION.LOGIN, handler);
    EventBus.$on(EVENTS.SESSION.LOGIN_PUBLISHED_VIEW, handler);
  });
};

const initVortex = () => {
  initConnector();

  EventBus.$on(EVENTS.SESSION.LOGOUT, () => {
    disconnect();
    eventHandlers.forEach((handler) => { EventBus.$off(EVENTS.SESSION.LOGIN, handler); });
    initConnector();
  });
};

const vortex = () => connector.then(() => subscriptionChannel);

export default {
  initVortex,
  vortex,
};
