<template>
  <div class="participants-list">
    <div class="_header">
      <div class="_left">
        {{ $t('participantsTable.title') }}
      </div>
      <div class="_right">
        <m-select
          :items="occurrences"
          :value="selectedDate"
          btn
          small
          hide-border
          light
          @input="handleSelectedDateChanged"
        />
      </div>
    </div>
    <div class="_content">
      <m-table
        class="_participants-table"
        :columns="columns"
        :data-source="users"
        row-class-name="participation-table-row"
        :pagination="pagination"
        row-key="user.uid"
      >
        <template #user="{user}">
          <user-display :user="user" />
        </template>
        <template #participated="{participated, row}">
          <div
            class="_participated"
          >
            <m-tag
              v-if="participated"
              color="success"
              :title="$t('participationList.done')"
            />
            <m-tag
              v-else
              color="warning"
              :title="$t('participationList.missing')"
            />
            <div class="_actions">
              <m-btn
                v-if="participated"
                :to="updateLink(row.update.uid)"
              >
                {{ $t('participantsTable.view') }}
              </m-btn>
            </div>
          </div>
        </template>
      </m-table>
    </div>
  </div>
</template>

<script>
import UserDisplay from 'shared/components/UserDisplay.vue';
import useUsers from '@/composables/user/users';
import { findInArray } from 'shared/lib/array/array';
import { routeName } from 'shared/constants.json';

export default {
  name: 'ParticipantsTable',
  props: {
    participationList: {
      type: Array,
      required: true,
    },
    occurrences: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const userRepo = useUsers();
    return { userRepo };
  },
  components: { UserDisplay },
  data() {
    return { selectedDate: null };
  },
  computed: {
    pagination() {
      if (this.users.length < 30) {
        return false;
      }

      return { pageSize: 30 };
    },
    columns() {
      return [
        {
          key: 'user',
          dataIndex: 'user',
          title: this.$t('participantsTable.user'),
          scopedSlots: { customRender: 'user' },
          width: '60%',
          sorter: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
        },
        {
          key: 'participated',
          dataIndex: 'participated',
          title: this.$t('participantsTable.participated'),
          scopedSlots: { customRender: 'participated' },
          width: '40%',
          sorter: (a, b) => {
            if (a.participated === true && b.participated === false) {
              return -1;
            }

            if (a.participated === false && b.participated === true) {
              return 1;
            }

            return 0;
          },
        },
      ];
    },
    users() {
      return findInArray({ haystack: this.participationList, needle: this.selectedDate, key: 'time', defaultValue: this.participationList[0] }).users.map((e) => ({
        ...e,
        user: this.userRepo.selectSingle(e.user.uid),
      }));
    },
  },
  methods: {
    handleSelectedDateChanged(val) {
      this.selectedDate = val;
    },
    updateLink(id) {
      return {
        name: routeName.updateDetails,
        params: { id },
      };
    },
  },
  watch: {
    occurrences: {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.selectedDate = val[0].value;
      },
      deep: true,
    },
  },
  created() {
    if (this.occurrences.length === 0) {
      return;
    }
    this.selectedDate = this.occurrences[0].value;
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .participants-list {
    ._header {
      display: flex;
      margin-bottom: 1.2rem;

      ._left {
        display: flex;
        align-items: center;
        font-weight: $font-weight-bold;
      }

      ._right {
        display: flex;
        margin-left: auto;
      }
    }

    ._content {
      ._participated {
        position: relative;
        display: flex;
        align-items: center;

        ._actions {
          position: absolute;
          top: -.4rem;
          right: 0;
          display: none;
          background-color: white;
          border-radius: $btn-border-radius;
        }
      }
    }
  }
</style>
